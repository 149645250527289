import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToAnchor = (offset = 0) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const anchor = document.querySelector(hash);
      if (anchor) {
        const topOffset = anchor.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({
          top: topOffset,
          behavior: 'smooth',
        });
      }
    }
  }, [hash, offset]);
};
