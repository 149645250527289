
import React, {ReactNode} from "react";
import { ReactComponent as CopyIcon } from '../../../../assets/icons/v2/ic-copy.svg'
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings.svg'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/danger-triangle.svg'
import styles from './styles.module.scss';
import clsx from "clsx";
import {copyToClipboard} from "../../../lib/utils/copy-to-clipboard/copyToClipboard";
import {SNACKBAR_TYPES} from "../../../../layouts/snackbar/SnackbarProvider";
import {useSnackbar} from "../../../../app/hooks/useSnackbar";
import {Loader} from "../../../ui-kit/data-display/loader/Loader";

export enum SwitchVariant {
  GOOD_BAD_CHOICE = 1,
  NORMAL_CHOICE = 2
}

interface IActionCardProps {
  id: number,
  name: string
  number: string
  variant?: SwitchVariant
  loading?: boolean
  settings?: {
    onClick?: (id: number) => void
  }
  switcher: {
    currentValue: boolean
    leftButton: {
      text: string
      onClick: (id: number) => void
    }
    rightButton: {
      text: string
      onClick: (id: number) => void
    }
  }
  copyBlock: {
    show: boolean,
    items?: Array<{
      name: string,
      value: string,
      possiblyToCopy: boolean
    }>
  }
  footer?: ReactNode
  alert: {
    show: boolean,
    text?: string
  }
}

export const ActionCard = ({
  id,
  name,
  number,
  variant = SwitchVariant.GOOD_BAD_CHOICE,
  loading,
  settings,
  switcher,
  copyBlock,
  footer,
  alert
}: IActionCardProps) => {
  const { handleOpenSnackbar, handleHideSnackbar } = useSnackbar()

  const handleCopyData = (string: string) => {
    if (string) {
      void copyToClipboard(string)

      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: 'Copied to clipboard!',
      })

      setTimeout(() => {
        handleHideSnackbar()
      }, 1000)
    }
  }

  return (
    <div className={clsx(styles.ActionCard, alert.show && styles.ActionCard_alert)}>
      <div className={styles.Top}>
        <div className={styles.Top__Info}>
          <div className={styles.Top__Name}>{name}</div>
          <div className={styles.Top__Number}>{number}</div>
        </div>
        {settings?.onClick && (
          <button
            className={styles.Top__Action}
            onClick={() => settings.onClick?.(id)}
          >
            <SettingsIcon />
          </button>
        )}
      </div>
      {alert.show ? (
        <div className={styles.Alert}>
          <div className={styles.Alert__Icon}>
            <WarningIcon />
          </div>
          <div className={styles.Alert__Text}>
            { alert?.text }
          </div>
        </div>
      ) : (
        <>
          <div className={styles.Switcher}>
            {loading ? (
              <div className={styles.Switcher__Loader}>
                <Loader isSmall={true} />
              </div>
            ) : (
              <>
                <button
                  className={clsx(
                    styles.Switcher__LeftMode,
                    switcher.currentValue && styles.Switcher__LeftMode_active,
                    variant === SwitchVariant.NORMAL_CHOICE && styles.Switcher__LeftMode_normal
                  )}
                  onClick={() => !switcher.currentValue && switcher.leftButton.onClick(id)}
                >
                  {switcher.leftButton.text}
                </button>
                <button
                  className={clsx(
                    styles.Switcher__RightMode,
                    !switcher.currentValue && styles.Switcher__RightMode_active,
                    variant === SwitchVariant.NORMAL_CHOICE && styles.Switcher__RightMode_normal
                  )}
                  onClick={() => switcher.currentValue && switcher.rightButton.onClick(id)}
                >
                  {switcher.rightButton.text}
                </button>
              </>
            )}
          </div>
          {copyBlock.show && copyBlock?.items && copyBlock.items.length > 0 && (
            <div className={styles.CopyBlock}>
              {copyBlock.items.map(({ name, value, possiblyToCopy }) => (
                <div key={name} className={styles.CopyBlock__Row}>
                  <div className={styles.CopyBlock__Name}>
                    { name || '-' }
                  </div>
                  <div className={styles.CopyBlock__Right}>
                    <div className={styles.CopyBlock__Value}>
                      { value || '-' }
                    </div>
                    {possiblyToCopy && value && (
                      <button className={styles.CopyBlock__Button} onClick={() => handleCopyData(value)}>
                        <CopyIcon />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {footer || <></>}
        </>
      )}
    </div>
  )
}