import { FC } from 'react'
import { useSelector } from 'react-redux'
import { AddCardModal } from './AddCardModal/AddCardModal'
import { BuyNewDeviceModal } from './BuyNewDeviceModal/BuyNewDeviceModal'
import { ContactGroupSettingsModal } from './ContactGroupSettingsModal/ContactGroupSettingsModal'
import { ContactSettingsModal } from './ContactSettingsModal/ContactSettingsModal'
import { CreateCallModal } from './CreateCallModal/CreateCallModal'
import { DeleteFailedModal } from './DeleteFailedModal/DeleteFailedModal'
import { DeleteItemModal } from './DeleteItemModal/DeleteItemModal'
import { DeviceDetailsModal } from './DeviceDetailsModal/DeviceDetailsModal'
import { EditDeviceNameModal } from './EditDeviceNameModal/EditDeviceNameModal'
import { EditProfileModal } from './EditProfileModal/EditProfileModal'
import { FiltersModal } from './FiltersModal/FiltersModal'
import { OrderModal } from './OrderModal/OrderModal'
import { PasswordModal } from './PasswordModal/PasswordModal'
import { PaymentBankCardChooseModal } from './PaymentBankCardChooseModal/PaymentBankCardChooseModal'
import { RechargeBalanceModal } from './RechargeBalanceModal/RechargeBalanceModal'
import { RedirectionSettingsModal } from './RedirectionSettingsModal/RedirectionSettingsModal'
import { SendSmsModal } from './SendSmsModal/SendSmsModal'
import { SmsMailingCreateModal } from './SmsMailingCreateModal/SmsMailingCreateModal'
import { SmsMailingViewModal } from './SmsMailingViewModal/SmsMailingViewModal'
import { SmsViewModal } from './SmsViewModal/SmsViewModal'
import { WifiSettingsModal } from './WifiSettingsModal/WifiSettingsModal'
import { WireTransferSuccessModal } from './WireTransferSuccessModal/WireTransferSuccessModal'
import {AddCardWarningModal} from "./AddCardWarningModal/AddCardWarningModal";
import {DeviceChangePhoneNumberModal} from "./DeviceChangePhoneNumberModal/DeviceChangePhoneNumberModal";
import {RepeatCallModal} from "./RepeatCallModal/RepeatCallModal";
import {CallBackModal} from "./CallBackModal/CallBackModal";
import {CallModal} from "./CallModal/CallModal";
import {CallsAutoResponseRules} from "./CallsAutoResponseRules/CallsAutoResponseRules";

export const MODAL_TYPES = {
  RECHARGE_BALANCE: 'RECHARGE_BALANCE',
  WIRE_TRANSFER_SUCCESS: 'WIRE_TRANSFER_SUCCESS',
  PAYMENT_BANK_CARD_CHOOSE: 'PAYMENT_BANK_CARD_CHOOSE',
  BUY_NEW_DEVICE: 'BUY_NEW_DEVICE',
  WIFI_SETTINGS: 'WIFI_SETTINGS',
  FILTERS: 'FILTERS',
  CREATE_CALL: 'CREATE_CALL',
  DELETE_ITEM: 'DELETE_ITEM',
  ORDER: 'ORDER',
  SMS_MAILING_CREATE: 'SMS_MAILING_CREATE',
  SMS_MAILING_VIEW: 'SMS_MAILING_VIEW',
  CONTACT_SETTINGS_MODAL: 'CONTACT_SETTINGS_MODAL',
  CONTACT_GROUP_SETTINGS_MODAL: 'CONTACT_GROUP_SETTINGS_MODAL',
  DELETE_FAILED_MODAL: 'DELETE_FAILED_MODAL',
  REDIRECTION_SETTINGS: 'REDIRECTION_SETTINGS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  EDIT_PROFILE: 'EDIT_PROFILE',
  ADD_CARD: 'ADD_CARD',
  ADD_CARD_WARNING: 'ADD_CARD_WARNING',
  DEVICE_DETAILS: 'DEVICE_DETAILS',
  EDIT_DEVICE_NAME: 'EDIT_DEVICE_NAME',
  SEND_SMS: 'SEND_SMS',
  SMS_VIEW: 'SMS_VIEW',
  DEVICE_CHANGE_PHONE_NUMBER: 'DEVICE_CHANGE_PHONE_NUMBER',
  REPEAT_CALL: 'REPEAT_CALL',
  CALL_BACK: 'CALL_BACK',
  CALL: 'CALL',
  CALLS_AUTO_RESPONSE_RULES: 'CALLS_AUTO_RESPONSE_RULES'
}

export const MODAL_BY_TYPE = {
  [MODAL_TYPES.RECHARGE_BALANCE]: <RechargeBalanceModal />,
  [MODAL_TYPES.WIRE_TRANSFER_SUCCESS]: <WireTransferSuccessModal />,
  [MODAL_TYPES.PAYMENT_BANK_CARD_CHOOSE]: <PaymentBankCardChooseModal />,
  [MODAL_TYPES.BUY_NEW_DEVICE]: <BuyNewDeviceModal />,
  [MODAL_TYPES.WIFI_SETTINGS]: <WifiSettingsModal />,
  [MODAL_TYPES.FILTERS]: <FiltersModal />,
  [MODAL_TYPES.CREATE_CALL]: <CreateCallModal />,
  [MODAL_TYPES.DELETE_ITEM]: <DeleteItemModal />,
  [MODAL_TYPES.ORDER]: <OrderModal />,
  [MODAL_TYPES.SMS_MAILING_CREATE]: <SmsMailingCreateModal />,
  [MODAL_TYPES.SMS_MAILING_VIEW]: <SmsMailingViewModal />,
  [MODAL_TYPES.CONTACT_SETTINGS_MODAL]: <ContactSettingsModal />,
  [MODAL_TYPES.CONTACT_GROUP_SETTINGS_MODAL]: <ContactGroupSettingsModal />,
  [MODAL_TYPES.DELETE_FAILED_MODAL]: <DeleteFailedModal />,
  [MODAL_TYPES.REDIRECTION_SETTINGS]: <RedirectionSettingsModal />,
  [MODAL_TYPES.CHANGE_PASSWORD]: <PasswordModal />,
  [MODAL_TYPES.EDIT_PROFILE]: <EditProfileModal />,
  [MODAL_TYPES.ADD_CARD]: <AddCardModal />,
  [MODAL_TYPES.ADD_CARD_WARNING]: <AddCardWarningModal />,
  [MODAL_TYPES.DEVICE_DETAILS]: <DeviceDetailsModal />,
  [MODAL_TYPES.EDIT_DEVICE_NAME]: <EditDeviceNameModal />,
  [MODAL_TYPES.SEND_SMS]: <SendSmsModal />,
  [MODAL_TYPES.SMS_VIEW]: <SmsViewModal />,
  [MODAL_TYPES.DEVICE_CHANGE_PHONE_NUMBER]: <DeviceChangePhoneNumberModal />,
  // TODO: remove repeat_call and call_back
  [MODAL_TYPES.REPEAT_CALL]: <RepeatCallModal />,
  [MODAL_TYPES.CALL_BACK]: <CallBackModal />,
  [MODAL_TYPES.CALL]: <CallModal />,
  [MODAL_TYPES.CALLS_AUTO_RESPONSE_RULES]: <CallsAutoResponseRules />
}

export const ModalsProvider: FC = () => {
  // @ts-ignore
  const { type, props } = useSelector((store) => store.modal)

  const Component: FC = () => (type ? MODAL_BY_TYPE[type] : <></>)

  return <Component {...props} />
}
