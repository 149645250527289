
export const getWebsocketsUrl = (hostname: string = '') => {
  try {
    const envWS = hostname;

    if (envWS) {
      return `wss://${envWS}/broadcast`
    }

    return;
  } catch (e) {
    return;
  }
}
