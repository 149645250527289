import React, { FC } from 'react'
import clsx from 'clsx'
import { useAppSelector } from '../../store'
import styles from './styles.module.scss'

type Props = {
  children: React.ReactNode,
  loading?: boolean,
}

export const PageWrapper = ({
  children,
  loading
}: Props) => {
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated)

  return (
    <div
      className={clsx(
        styles.Wrapper,
        isAuthenticated && styles.Wrapper_authenticated,
        loading && styles.Wrapper_loading
      )}
    >
      <div className={styles.Content}>
        {children}
      </div>
    </div>
  )
}
