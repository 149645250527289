import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { MODAL_TYPES } from '../../../layouts/modals/ModalsProvider'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  contactDeleteDatagate,
  contactListDatagate,
} from '../../datagates/api/contact'
import {
  BackendErrorCodes,
  useBackendErrorCodes,
} from '../../datagates/helpers/_common/use-backend-error-codes'
import { ContactListResponse } from '../../datagates/types/contact/_crud/list'
import {QueryParameter} from "../../datagates/helpers/_common/wrap-api-request";

type UseContactsProps = {
  page?: number
  limit?: number
  isSortedOrderTypeAsc?: boolean
}

const UseContactsMessages = defineMessages({
  positiveDeleted: {
    id: 'UseContactsMessages.positiveDeleted',
    defaultMessage: 'Contact is successfully deleted',
  },
  negativeDeleted: {
    id: 'UseContactsMessages.negativeDeleted',
    defaultMessage: 'An error occurred while deleting contact',
  },
  deleteErrorUsedTitle: {
    id: 'UseContactsMessages.deleteErrorUsedTitle',
    defaultMessage: 'This contact group cannot be deleted',
  },
  deleteErrorUsedDescription: {
    id: 'UseContactsMessages.deleteErrorUsedDescription',
    defaultMessage:
      'It is used in auto-reply rules or redirects or mailings. First remove it from the rules, then try again',
  },
})

export const useContacts = (props: UseContactsProps) => {
  const { page, limit, isSortedOrderTypeAsc } = props

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleHideModal, handleOpenModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const [query, setQuery] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [contacts, setContacts] = useState<ContactListResponse['contacts']>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [initialFetchCompleted, setInitialFetchCompleted] = useState<boolean>(false)

  const handleDeleteContact = async (contactId: number) => {
    try {
      const urlParams = [{ name: 'contact_id', value: contactId.toString() }]

      await contactDeleteDatagate(null, urlParams)
      await handleFetch()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseContactsMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(UseContactsMessages.negativeDeleted),
      })
    }
  }

  const handleFetch = async ({ params = [] }: { params?: QueryParameter[] } = {}) => {
    try {
      setLoading(true)
      //
      const queryParams = [
        { name: 'order_by', value: 'name' },
        {
          name: 'order_type',
          value: `${isSortedOrderTypeAsc ? 'asc' : 'desc'}`,
        },
        { name: 'query', value: query },
        ...params
      ]

      if (page && limit) {
        queryParams.push({ name: 'page', value: (page - 1).toString() })
        queryParams.push({ name: 'limit', value: limit.toString() })
      }

      const { data } = await contactListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setContacts(data.contacts)
    } catch (e) {
      if (
        getErrorMessage(e) ===
        BackendErrorCodes.CONTACT_ERROR_REDIRECTS_ARE_PRESENT_WHILE_DELETING_CONTACT_GROUP
      ) {
        handleHideModal()
        handleOpenModal({
          type: MODAL_TYPES.DELETE_FAILED_MODAL,
          props: {
            title: intl.formatMessage(UseContactsMessages.deleteErrorUsedTitle),
            description: intl.formatMessage(
              UseContactsMessages.deleteErrorUsedDescription,
            ),
          },
        })
      } else {
        handleOpenSnackbar({
          type: SNACKBAR_TYPES.error,
          text: resolveBackendError(getErrorMessage(e)),
        })
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [page, limit, query, isSortedOrderTypeAsc])

  return {
    contacts,
    totalCount,
    loading,
    setQuery,
    handleFetch,
    handleDeleteContact,
    initialFetchCompleted,
    setInitialFetchCompleted
  }
}
