import { formatPhoneNumber } from '../../../../../../formatters/format-phone-number'
import {
  DongleUpdateBackendResponse,
  DongleUpdateResponse,
} from '../../../../../types/dongle/_crud/update'

export const mapDongleUpdateBackendToDongleUpdate = (
  item: DongleUpdateBackendResponse,
): DongleUpdateResponse => {
  const {
    dongle_id,
    bootstrap_dongle_id,
    user_id,
    name,
    hotspot_name,
    hotspot_password,
    fake_imei,
    number,
    dongle_call_type_id,
    is_online,
    is_deleted,
    api_version,
    is_hotspot_enable,
    created_at,
    updated_at,
    tariff_package_id,
    is_active,
    is_tariff_package_enabled,
    is_mute_incoming_call
  } = item.data

  return {
    dongleId: dongle_id,
    bootstrapDongleId: bootstrap_dongle_id,
    userId: user_id,
    name: name,
    hotspotName: hotspot_name,
    hotspotPassword: hotspot_password,
    fakeImei: {
      fakeImeiId: fake_imei?.fake_imei_id,
      imei: fake_imei?.imei,
      createdAt: fake_imei?.created_at
    },
    phoneNumber: number,
    dongleCallTypeId: dongle_call_type_id,
    isOnline: is_online,
    isDeleted: is_deleted,
    apiVersion: api_version,
    isHotspotEnable: is_hotspot_enable,
    createdAt: created_at,
    updatedAt: updated_at,
    tariffPackageId: tariff_package_id,
    isActive: is_active,
    isTariffPackageEnabled: is_tariff_package_enabled,
    isMuteIncomingCall: is_mute_incoming_call
  }
}
