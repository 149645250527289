import React, { Dispatch, FC, SetStateAction } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { InvoiceListResponse } from '../../../sdk/datagates/types/billing/invoice/_crud/list'
import {
  Table,
  TableColType,
} from '../../../shared/ui-kit-2/data-display/table/Table'
import { InvoiceTableRows } from './components/invoice-table-rows/InvoiceTableRows'

interface InvoiceTableProps {
  invoices: InvoiceListResponse['invoices']
  totalCount: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
  pageLimit: number
  loading: boolean
}

const InvoiceTableMessages = defineMessages({
  columnDate: {
    id: 'InvoiceTableMessages.columnDate',
    defaultMessage: 'Date',
  },
  columnAmount: {
    id: 'InvoiceTableMessages.columnAmount',
    defaultMessage: 'Amount',
  },
  columnType: {
    id: 'InvoiceTableMessages.columnType',
    defaultMessage: 'Type',
  },
  columnDocuments: {
    id: 'InvoiceTableMessages.columnDocuments',
    defaultMessage: 'Download PDF',
  },
})

export const InvoiceTable: FC<InvoiceTableProps> = (props) => {
  const { invoices, page, setPage, setLimit, pageLimit, totalCount, loading } =
    props

  const intl = useIntl()

  const handlePageChange = (page: number, limit: number) => {
    setPage(page)
    setLimit(limit)
  }

  const cols: TableColType[] = [
    { title: intl.formatMessage(InvoiceTableMessages.columnDate) },
    { title: intl.formatMessage(InvoiceTableMessages.columnType) },
    { title: intl.formatMessage(InvoiceTableMessages.columnAmount) },
    {
      title: intl.formatMessage(InvoiceTableMessages.columnDocuments),
      position: 'right',
    },
  ]

  const rowGroups = InvoiceTableRows(invoices)

  return (
    <Table
      name={'InvoiceTable'}
      cols={cols}
      rowGroups={rowGroups}
      currentPage={page}
      totalCount={totalCount}
      onPageChange={handlePageChange}
      itemsPerPage={pageLimit}
      columnWidths={[1, 1.5, 1.5, 1.5]}
      loading={loading}
    />
  )
}
