import styles from "../../styles.module.scss";
import {PrivacyPolicyMessages} from "../../messages";
import React from "react";
import {useIntl} from "react-intl";

export const RuPrivacyPolicy = () => {
  const intl = useIntl();

  return (
    <>
      <div className={styles.Title}>
        {intl.formatMessage(PrivacyPolicyMessages.title)}
      </div>
      <div className={styles.Content}>
        <div className={styles.Text}>
          <h3>
            {intl.formatMessage(
              PrivacyPolicyMessages.definitionsAndInterpretationTitle,
            )}
          </h3>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.privacyPolicyDefinitionsIntro,
            )}
          </p>

          <table>
            <tr>
              <td>{intl.formatMessage(PrivacyPolicyMessages.dataTitle)}</td>
              <td>{intl.formatMessage(PrivacyPolicyMessages.dataContent)}</td>
            </tr>
            <tr>
              <td>
                {intl.formatMessage(PrivacyPolicyMessages.cookiesTitle)}
              </td>
              <td>
                {intl.formatMessage(PrivacyPolicyMessages.cookiesContent)}
              </td>
            </tr>
            <tr>
              <td>
                {intl.formatMessage(
                  PrivacyPolicyMessages.dataProtectionTitle,
                )}
              </td>
              <td>
                {intl.formatMessage(
                  PrivacyPolicyMessages.dataProtectionContent,
                )}
              </td>
            </tr>
            <tr>
              <td>
                {intl.formatMessage(
                  PrivacyPolicyMessages.bringoContactsTitle,
                )}
              </td>
              <td>
                {intl.formatMessage(
                  PrivacyPolicyMessages.bringoContactsContent,
                  {
                    companyEmail: <b>info@virtal.ru</b>,
                    companyNameBringo: <b>ООО «Виртал»</b>,
                  },
                )}
              </td>
            </tr>
            <tr>
              <td>
                {intl.formatMessage(
                  PrivacyPolicyMessages.thirdPartyAccessDefinitionTitle,
                )}
              </td>
              <td>
                {intl.formatMessage(
                  PrivacyPolicyMessages.thirdPartyAccessDefinitionContent,
                  {
                    companyNameBringo: <b>'ООО «Виртал»'</b>,
                  },
                )}
              </td>
            </tr>

            <tr>
              <td>
                Веб-сайт
              </td>
              <td>
                <a href={'www.telecorn.ru'}>telecorn.ru</a>
              </td>
            </tr>
          </table>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.interpretationClauseTitle,
            )}
          </p>

          <ol className={styles.page_ol_lower_alpha}>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.interpretationClauseAPart,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.interpretationClauseBPart,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.interpretationClauseCPart,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.interpretationClauseDPart,
              )}
              <br />
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.interpretationClauseEPart,
              )}
              <br />
            </li>
          </ol>

          <h3>
            {intl.formatMessage(PrivacyPolicyMessages.dataCollectedTitle)}
          </h3>

          <ol className={styles.page_ol_inherit}>
            <li>{intl.formatMessage(PrivacyPolicyMessages.identityData)}</li>
            <li>{intl.formatMessage(PrivacyPolicyMessages.contactData)}</li>
            <li>{intl.formatMessage(PrivacyPolicyMessages.technicalData)}</li>
            <li>{intl.formatMessage(PrivacyPolicyMessages.profileData)}</li>
            <li>{intl.formatMessage(PrivacyPolicyMessages.usageData)}</li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.marketingAndCommunicationData,
              )}
            </li>
          </ol>

          <br />

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.aggregatedDataProcessing,
            )}
          </p>

          <br />

          {intl.formatMessage(
            PrivacyPolicyMessages.excludedPersonalDataCategories,
          )}

          <br />

          <h3>
            {intl.formatMessage(PrivacyPolicyMessages.howWeCollectDataTitle)}
          </h3>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.specificCollectionMethodsTitle,
            )}
          </p>

          <ol className={styles.page_ol_lower_alpha}>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.specificCollectionMethodsA,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.specificCollectionMethodsB,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.specificCollectionMethodsC,
              )}
            </li>
          </ol>

          <h3>
            {intl.formatMessage(PrivacyPolicyMessages.userProvidedDataTitle)}
          </h3>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.bringoDataCollectionMethodsTitle,
              {
                companyNameBringo: <b>ООО «Виртал»</b>,
              },
            )}
          </p>

          <ol className={styles.page_ol_lower_alpha}>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.bringoDataCollectionMethodsA,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.bringoDataCollectionMethodsB,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.bringoDataCollectionMethodsC,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.bringoDataCollectionMethodsD,
              )}
            </li>
          </ol>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.collectionPolicyCompliance,
            )}
          </p>

          <h3>
            {intl.formatMessage(
              PrivacyPolicyMessages.dataFromPublicSourcesTitle,
            )}
          </h3>

          <p>{intl.formatMessage(PrivacyPolicyMessages.dataFromCompanies)}</p>

          <h3>
            {intl.formatMessage(
              PrivacyPolicyMessages.automaticallyCollectedDataTitle,
            )}
          </h3>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.dataCollectedUponWebsiteAccess,
            )}
          </p>

          <ol className={styles.page_ol_lower_alpha}>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.dataCollectedUponWebsiteAccessA,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.dataCollectedUponWebsiteAccessB,
              )}
            </li>
          </ol>

          <h3>
            {intl.formatMessage(PrivacyPolicyMessages.ourUseOfDataTitle)}
          </h3>

          <p>{intl.formatMessage(PrivacyPolicyMessages.dataUsageIntro)}</p>

          <ol className={styles.page_ol_lower_alpha}>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataUsageIntroA)}
            </li>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataUsageIntroB)}
            </li>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataUsageIntroC)}
            </li>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataUsageIntroD)}
            </li>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataUsageIntroE)}
            </li>
          </ol>

          <p>
            {intl.formatMessage(PrivacyPolicyMessages.dataUsageCompliance)}
          </p>

          <p>
            {intl.formatMessage(PrivacyPolicyMessages.legitimateInterests)}
          </p>

          <p>
            {intl.formatMessage(PrivacyPolicyMessages.emailMarketingConsent)}
          </p>

          <ol className={styles.page_ol_lower_alpha}>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.emailMarketingConsentA,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.emailMarketingConsentB,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.emailMarketingConsentC,
              )}
            </li>
          </ol>

          <p>
            {intl.formatMessage(PrivacyPolicyMessages.registrationLegalBasis)}
          </p>

          <h3>
            {intl.formatMessage(
              PrivacyPolicyMessages.ensuringDataSecurityTitle,
            )}
            <br />
          </h3>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.ensuringDataSecurityIntro,
            )}
          </p>

          <ol className={styles.page_ol_lower_alpha}>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.ensuringDataSecurityA,
              )}
            </li>
            <li>
              {intl.formatMessage(
                PrivacyPolicyMessages.ensuringDataSecurityB,
              )}
            </li>
          </ol>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.technicalAndOrganisationalMeasures,
            )}
          </p>

          <h3>
            {intl.formatMessage(PrivacyPolicyMessages.dataRetentionTitle)}
          </h3>

          <p>
            {intl.formatMessage(PrivacyPolicyMessages.dataRetentionPolicy)}
          </p>

          <p>
            {intl.formatMessage(PrivacyPolicyMessages.dataRetentionBackup)}
          </p>

          <h3>{intl.formatMessage(PrivacyPolicyMessages.yourRightsTitle)}</h3>

          <p>
            {intl.formatMessage(PrivacyPolicyMessages.dataRightsOverview)}
          </p>

          <ol className={styles.page_ol_lower_alpha}>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataRightsOverviewA)}
            </li>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataRightsOverviewB)}
            </li>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataRightsOverviewC)}
            </li>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataRightsOverviewD)}
            </li>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataRightsOverviewE)}
            </li>
            <li>
              {intl.formatMessage(PrivacyPolicyMessages.dataRightsOverviewF)}
            </li>
          </ol>

          <p>{intl.formatMessage(PrivacyPolicyMessages.contactUs)}</p>

          <p>{intl.formatMessage(PrivacyPolicyMessages.handleComplaint)}</p>

          <p>{intl.formatMessage(PrivacyPolicyMessages.dataAccuracy)}</p>

          <h3>
            {intl.formatMessage(PrivacyPolicyMessages.linksToOtherSitesTitle)}
          </h3>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.linksToOtherSitesContent,
            )}
          </p>

          <h3>
            {intl.formatMessage(PrivacyPolicyMessages.changeInOwnershipTitle)}
          </h3>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.changeInOwnershipOverview,
              {
                companyNameBringo: <b>ООО «Виртал»</b>,
              },
            )}
          </p>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.changeInOwnershipProspectivePurchaser,
            )}
          </p>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.changeInOwnershipPrivacyProtection,
            )}
          </p>

          <h3>
            {intl.formatMessage(PrivacyPolicyMessages.cookiesTitleTable)}
          </h3>

          <p>{intl.formatMessage(PrivacyPolicyMessages.cookiesOverview)}</p>
          <p>
            {intl.formatMessage(PrivacyPolicyMessages.cookiesLegislation)}
          </p>
          <p>{intl.formatMessage(PrivacyPolicyMessages.cookiesConsent)}</p>
          <p>{intl.formatMessage(PrivacyPolicyMessages.followingCookies)}</p>

          <table>
            <tr>
              <td>
                {intl.formatMessage(
                  PrivacyPolicyMessages.cookieTypeHeadTable,
                )}
              </td>
              <td>
                {intl.formatMessage(
                  PrivacyPolicyMessages.cookiePurposeHeadTable,
                )}
              </td>
            </tr>
            <tr>
              <td>
                {intl.formatMessage(
                  PrivacyPolicyMessages.cookieTypeBodyTable,
                )}
              </td>
              <td>
                {intl.formatMessage(
                  PrivacyPolicyMessages.cookiePurposeBodyTable,
                )}
              </td>
            </tr>
          </table>

          <p>
            {intl.formatMessage(PrivacyPolicyMessages.cookiesBrowserSettings)}
          </p>

          <p>
            {intl.formatMessage(PrivacyPolicyMessages.cookiesDisableImpact)}
          </p>
          <p>
            {intl.formatMessage(PrivacyPolicyMessages.cookiesBrowserUpdate)}
          </p>
          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.cookiesAdditionalResources,
            )}
          </p>

          <h3>{intl.formatMessage(PrivacyPolicyMessages.generalTitle)}</h3>

          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.generalInfoRightsTransfer,
            )}
          </p>
          <p>
            {intl.formatMessage(
              PrivacyPolicyMessages.generalInfoInvalidProvisions,
            )}
          </p>
          <p>
            {intl.formatMessage(PrivacyPolicyMessages.generalInfoNoDelay)}
          </p>

          <h3>
            {intl.formatMessage(
              PrivacyPolicyMessages.privacyPolicyChangesTitle,
            )}
          </h3>

          <p>
            {intl.formatMessage(PrivacyPolicyMessages.privacyPolicyChanges, {
              companyEmail: <b>info@virtal.ru</b>,
              companyNameBringo: <b>ООО «Виртал»</b>,
            })}
          </p>
        </div>
      </div>
    </>
  )
}