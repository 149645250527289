import React, { Dispatch, FC, SetStateAction } from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { TransactionListResponse } from '../../../sdk/datagates/types/billing/transaction/_crud/list'
import { useDocuments } from '../../../sdk/hooks/use-documents/useDocuments'
import { useTransactionTypes } from '../../../sdk/hooks/use-transaction-types/useTransactionTypes'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../shared/lib/hooks/useLessThanDeviceScreen'
import { formatDate } from '../../../shared/lib/utils/date-utils/formatDate'
import { Sticker } from '../../../shared/ui-kit-2/data-display/sticker/Sticker'
import { Table } from '../../../shared/ui-kit-2/data-display/table/Table'
import {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_VIEWS,
  IconButton,
} from '../../../shared/ui-kit/inputs/icon-button/IconButton'
import styles from './styles.module.scss'

const BalanceHistoryTableMessages = defineMessages({
  columnDate: {
    id: 'BalanceHistoryTable.columnDate',
    defaultMessage: 'Date',
  },
  columnType: {
    id: 'BalanceHistoryTable.columnType',
    defaultMessage: 'Type',
  },
  columnAmount: {
    id: 'BalanceHistoryTable.columnAmount',
    defaultMessage: 'Amount',
  },
  columnDongle: {
    id: 'BalanceHistoryTable.columnDongle',
    defaultMessage: 'Dongle',
  },
  columnDownload: {
    id: 'BalanceHistoryTable.columnDownload',
    defaultMessage: 'Download',
  },
  invoice: {
    id: 'BalanceHistoryTable.invoice',
    defaultMessage: 'Invoice',
  },
})

interface BalanceHistoryTableProps {
  balanceHistory: TransactionListResponse['transactions']
  totalCount: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
  pageLimit: number
  loading: boolean
  classes?: {
    tableContainer?: string
    footer?: string
  }
}

export const BalanceHistoryTable: FC<BalanceHistoryTableProps> = (props) => {
  const {
    balanceHistory,
    page,
    setPage,
    setLimit,
    pageLimit,
    totalCount,
    loading,
    classes,
  } = props

  const intl = useIntl()

  const { getTransactionTypeTranslationByTransactionTypeId } =
    useTransactionTypes()
  const { handleOpenPdf } = useDocuments()
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const cols = [
    { title: intl.formatMessage(BalanceHistoryTableMessages.columnDate) },
    { title: intl.formatMessage(BalanceHistoryTableMessages.columnType) },
    { title: intl.formatMessage(BalanceHistoryTableMessages.columnDongle) },
    { title: intl.formatMessage(BalanceHistoryTableMessages.columnAmount) },
    {
      title: `${intl.formatMessage(BalanceHistoryTableMessages.columnDownload)} PDF`,
    },
  ]

  const handlePageChange = (page: number, limit: number) => {
    setPage(page)
    setLimit(limit)
  }

  const rowGroups =
    balanceHistory.length > 0
      ? [
          {
            groupHeader: <div></div>,
            rows: balanceHistory.map((story) => [
              <div>{formatDate(story.createdAt, 0, 'datetime')}</div>,
              <div>
                <Sticker
                  text={getTransactionTypeTranslationByTransactionTypeId(
                    story.transactionTypeId,
                  )}
                  color={story.transactionTypeId === 1 ? 'lightGreen' : 'red'}
                />
              </div>,
              <div className={styles.DongleName}>
                {story.dongleName ?? '-'}
              </div>,
              <div
                className={story.transactionTypeId === 1 ? styles.GoodText : styles.BadText}
              >
                {story.transactionTypeId === 1
                  ? `+ €${story.amount}`
                  : `- €${story.amount}`}
              </div>,
              <div>
                {story.invoiceFileId && (
                  <div className={styles.Download}>
                    <IconButton
                      icon={ICON_BUTTON_ICONS.pdf}
                      theme={ICON_BUTTON_VIEWS.text}
                      onClick={() =>
                        story.invoiceFileId &&
                        handleOpenPdf(story.invoiceFileId)
                      }
                      additionalText={
                        <div
                          className={clsx(styles.InvoiceText, styles.GoodText)}
                        >
                          {intl.formatMessage(
                            BalanceHistoryTableMessages.invoice,
                          )}
                        </div>
                      }
                      additionalClassNames={[styles.Button]}
                    />
                  </div>
                )}
              </div>,
            ]),
          },
        ]
      : []

  const NoDataMobile = () => (
    <div className={styles.NoDataMobile}>
      You don't have a balance history yet
    </div>
  )

  return (
    <Table
      name={'BalanceHistory'}
      cols={cols}
      rowGroups={rowGroups}
      currentPage={page}
      totalCount={totalCount}
      onPageChange={handlePageChange}
      itemsPerPage={pageLimit}
      columnWidths={[1.5, 1, 1, 1, 1.5]}
      loading={loading}
      noDataComponent={isMobile ? <NoDataMobile /> : undefined}
    />
  )
}
