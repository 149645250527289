import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from 'react'
import { ContactGroupListResponse } from '../../../../../sdk/datagates/types/contact-group/_crud/list'
import { ContactListResponse } from '../../../../../sdk/datagates/types/contact/_crud/list'
import { useContactGroups } from '../../../../../sdk/hooks/use-contact-groups/useContactGroups'
import { useContacts } from '../../../../../sdk/hooks/use-contacts/useContacts'
import {bool} from "prop-types";

interface ContactsPageContextProps {
  contactsPage: number
  setContactsPage: Dispatch<SetStateAction<number>>
  setContactsLimitPage: Dispatch<SetStateAction<number>>
  limitContactsPage: number
  setContactsSearchQuery: Dispatch<SetStateAction<string>>
  contacts: ContactListResponse['contacts']
  contactsTotalCount: number
  contactsLoading: boolean
  handleFetchContacts: () => Promise<void>
  handleDeleteContact: (contactId: number) => Promise<void>

  groupsPage: number
  setGroupsPage: Dispatch<SetStateAction<number>>
  setGroupsLimitPage: Dispatch<SetStateAction<number>>
  limitGroupsPage: number
  contactGroups: ContactGroupListResponse['contactGroups']
  groupsTotalCount: number
  setGroupsSearchQuery: Dispatch<SetStateAction<string>>
  contactGroupsLoading: boolean
  handleFetchContactGroups: () => Promise<void>
  handleDeleteContactGroup: (contactGroupId: number) => Promise<void>
  handleSortedOrderTypeAsc: () => void
  isSortedOrderTypeAsc: boolean
  initialFetchCompleted: boolean
  setInitialFetchCompleted: Dispatch<SetStateAction<boolean>>
}

interface ContactsPageContextProviderProps {
  children: ReactNode
}

export const ContactsPageContext = createContext<ContactsPageContextProps>({
  contactsPage: 0,
  setContactsPage: () => {},
  setContactsLimitPage: () => {},
  limitContactsPage: 0,
  setContactsSearchQuery: () => {},
  contacts: [],
  contactsTotalCount: 0,
  contactsLoading: true,
  handleFetchContacts: async () => {},
  handleDeleteContact: async (contactId: number) => {},
  groupsPage: 0,
  setGroupsPage: () => {},
  setGroupsLimitPage: () => {},
  limitGroupsPage: 0,
  contactGroups: [],
  groupsTotalCount: 0,
  setGroupsSearchQuery: () => {},
  contactGroupsLoading: true,
  handleFetchContactGroups: async () => {},
  handleDeleteContactGroup: async (contactGroupId: number) => {},
  handleSortedOrderTypeAsc: () => {},
  isSortedOrderTypeAsc: false,
  initialFetchCompleted: false,
  setInitialFetchCompleted: () => {},
})

export const ContactsPageContextProvider: FC<
  ContactsPageContextProviderProps
> = ({ children }) => {
  const [contactsPage, setContactsPage] = useState<number>(1)
  const [limitContactsPage, setContactsLimitPage] = useState<number>(50)
  const [groupsPage, setGroupsPage] = useState<number>(1)
  const [limitGroupsPage, setGroupsLimitPage] = useState<number>(50)
  const [isSortedOrderTypeAsc, setIsSortedOrderTypeAsc] =
    useState<boolean>(false)

  const {
    loading: contactGroupsLoading,
    contactGroups: contactGroups,
    handleFetch: handleFetchContactGroups,
    handleDeleteContactGroup: handleDeleteContactGroup,
    setQuery: setGroupsSearchQuery,
    totalCount: groupsTotalCount,
  } = useContactGroups({
    page: groupsPage,
    limit: limitGroupsPage,
  })

  const {
    loading: contactsLoading,
    contacts: contacts,
    totalCount: contactsTotalCount,
    handleFetch: handleFetchContacts,
    handleDeleteContact: handleDeleteContact,
    setQuery: setContactsSearchQuery,
    initialFetchCompleted,
    setInitialFetchCompleted,
  } = useContacts({
    page: contactsPage,
    limit: limitContactsPage,
    isSortedOrderTypeAsc: isSortedOrderTypeAsc,
  })

  const handleSortedOrderTypeAsc = () => {
    setIsSortedOrderTypeAsc((prevState) => !prevState)
  }

  return (
    <ContactsPageContext.Provider
      value={{
        contactsPage,
        setContactsPage,
        setContactsLimitPage,
        limitContactsPage,
        setContactsSearchQuery,
        contacts,
        contactsTotalCount,
        contactsLoading,
        handleFetchContacts,
        handleDeleteContact,

        groupsPage,
        setGroupsPage,
        setGroupsLimitPage,
        limitGroupsPage,
        contactGroups,
        groupsTotalCount,
        setGroupsSearchQuery,
        contactGroupsLoading,
        handleFetchContactGroups,
        handleDeleteContactGroup,
        handleSortedOrderTypeAsc,
        isSortedOrderTypeAsc,
        initialFetchCompleted,
        setInitialFetchCompleted
      }}
    >
      {children}
    </ContactsPageContext.Provider>
  )
}
