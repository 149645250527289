export interface DongleListRequest {}

export enum DongleFlags {
  PHONE_DETERMINATION_ERROR = 1,
  MANUAL_PHONE_DETERMINING
}

export interface DongleListBackendResponse {
  data: {
    dongles: Array<{
      dongle_id: number
      bootstrap_dongle_id: number
      user_id: number
      name: string
      hotspot_name: string
      hotspot_password: string
      number: string
      dongle_call_type_id: number
      is_online: boolean
      is_deleted: boolean
      api_version: string
      is_hotspot_enable: boolean
      sms_outgoing: number
      sms_incoming: number
      call_outgoing: number
      call_incoming: number
      created_at: string
      updated_at: string
      tariff_package_id: number
      is_active: boolean
      bootstrap_dongle: {
        imei_manufacture: string
      }
      fake_imei: {
        fake_imei_id?: number
        imei?: string
        created_at?: Date
      }
      tariff_package_end?: string
      activated_at?: string
      dongle_signal_quality_id?: number
      is_tariff_package_active: boolean
      dongle_flags: Array<{
        dongle_flag_id: DongleFlags
        name: keyof typeof DongleFlags;
      }>
      is_public_vpn_enabled: boolean
      public_vpn_endpoint: string
      public_vpn_login: string
      public_vpn_password: string
      is_mute_incoming_call: boolean
    }>
    total_count: number
  }
}

export interface DongleListResponse {
  dongles: Array<{
    dongleId: number
    bootstrapDongleId: number
    userId: number
    name: string
    hotspotName: string
    hotspotPassword: string
    imeiManufacture: string
    fakeImei: {
      fakeImeiId?: number,
      imei?: string,
      createdAt?: Date
    },
    phoneNumber: string
    dongleCallTypeId: number
    isOnline: boolean
    isDeleted: boolean
    apiVersion: string
    isHotspotEnable: boolean
    smsOutgoing: number
    smsIncoming: number
    callOutgoing: number
    callIncoming: number
    createdAt: string
    updatedAt: string
    tariffPackageId: number
    isActive: boolean
    tariffPackageEnd?: Date
    activatedAt?: Date
    dongleSignalQualityId?: number
    isTariffPackageActive: boolean
    dongleFlags: Array<{
      dongleFlagId: DongleFlags
      name: keyof typeof DongleFlags;
    }>
    isVPNConnected: boolean
    publicVPNEndpoint: string
    publicVPNLogin: string
    publicVPNPassword: string
    isMuteIncomingCall: boolean
  }>
  totalCount: number
}
