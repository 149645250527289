
import React, {useMemo, useState} from "react";
import {Form} from "react-final-form";
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import {useModal} from "../../../app/hooks/useModal";
import {InputField} from "../../../shared/lib/form/form-field-adapters/v2/input-field/InputField";
import {useFormRules} from "../../../shared/lib/form/form-rules";
import {PhoneMultipleSettings} from "../_components/phone-multiple-settings/PhoneMultipleSettings";
import {Source} from "../_hooks/use-add-source/useAddSource";
import {useDevices} from "../../../sdk/hooks/use-devices/useDevices";
import {SelectSearchField} from "../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField";
import {SelectSearchOption} from "../../../shared/ui-kit-2/inputs/select-search/SelectSearch";
import {WavSurfer, WavSurferThemes} from "../../../shared/ui-kit-2/components/wav-surfer/WavSurfer";
import {IconButton} from "../../../shared/ui-kit-2/inputs/icon-button/IconButton";
import {Button} from "../../../shared/ui-kit-2/inputs/button/Button";
import {formatFileSize} from "../../../shared/lib/utils/format-file-size/formatFileSize";
import { ReactComponent as PlusInCircleIcon } from "../../../assets/icons/v2/ic-plus-in-circle.svg";
import clsx from "clsx";
import styles from './styles.module.scss';
import {blobToBase64} from "../../../shared/lib/utils/blob-to-base-64/blobToBase64";
import {formatPhoneNumber} from "../../../sdk/formatters/format-phone-number";

export const CallsAutoResponseRules = () => {
  const {
    props: {
      rule: propsRule,
      handleFetchRules,
      handleUpdateRules,
      handleAddRule,
      handleUpdateRule,
    },
    handleHideModal
  } = useModal();
  const [rule, setRule] = useState(propsRule);
  const { ruleRequired } = useFormRules();
  const [sources, setSources] = useState<Source[]>(
    rule?.call_scenario_phones
      ? rule?.call_scenario_phones?.map(({ phone }: { phone: string }) => {
        const formattedPhone = formatPhoneNumber(phone);

        return {
          sourceName: formattedPhone,
          sourceType: 'phone',
          sourceValue: formattedPhone
        };
      })
      : []
  );
  const { devices } = useDevices({
    page: 0,
    limit: 0,
    takeAll: true,
    brandId: 2,
    callTypeId: 2
  })
  const [allPhones, setAllPhones] = useState<boolean>(sources.length === 0)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<Record<string, string | boolean> | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const dongleOptions: SelectSearchOption[] = useMemo(
    () =>
      devices.map((d) => {
        return {
          key: d.dongleId,
          label: d.name,
          inputLabel: d.name,
          value: d.dongleId.toString(),
        }
      }),
    [devices],
  );

  const validateUploadedFile = async (uploadedFile: File) => {
    if (!uploadedFile.name.endsWith('.wav')) {
      setErrors({ uploadedFile: 'File must be .wav' });
      return false;
    }

    if (uploadedFile.size >= 1024 * 1024) {
      setErrors({ uploadedFile: 'File must be have a size 1 MB' });
      return false;
    }

    const audioContext = new AudioContext();
    const fileReader = new FileReader();

    return new Promise((resolve) => {
      fileReader.onload = async () => {
        const arrayBuffer = fileReader.result;

        if (arrayBuffer instanceof ArrayBuffer) {
          try {
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            // TODO: NEED ASK ABOUT REQUIRED SAMPLE
            if (!audioBuffer.sampleRate) {
              setErrors({ uploadedFile: 'File must be have a 16 MB bitrate' });
              resolve(false);
              return;
            }

            setErrors({ uploadedFile: false });
            resolve(true);
          } catch (error) {
            setErrors({ uploadedFile: 'Invalid audio file' });
            resolve(false);
          }
        } else {
          setErrors({ uploadedFile: 'Failed to read file' });
          resolve(false);
        }
      };

      fileReader.readAsArrayBuffer(uploadedFile);
    });
  };

  const AudioUpload: React.FC = () => {
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file && await validateUploadedFile(file)) {
        setUploadedFile(file);
      }
    };

    return (
      <div className={clsx(styles.AudioUpload, errors?.uploadedFile && styles.AudioUpload_error)}>
        <label className={styles.UploadBox}>
          <div className={styles.Row}>
            <div className={styles.Icon}>
              <PlusInCircleIcon />
            </div>
            <div>
              Drag & Drop or <span className={styles.Link}>Choose audio file</span> to upload
            </div>
            <input
              type="file"
              accept=".wav"
              onChange={handleFileChange}
              className={styles.FileInput}
            />
          </div>
          <div className={styles.Instructions}>Max. 1Mb, Acceptable formats: WAV</div>
        </label>
      </div>
    );
  };

  const handleSubmit = async (data: any) => {
    setSubmitLoading(true);

    if (!uploadedFile && !rule?.file?.cdn_url) {
      setErrors(prev => ({ uploadedFile: prev?.uploadedFile || true }));
      setSubmitLoading(false);
      return;
    }

    if (errors?.uploadedFile) {
      setSubmitLoading(false);
      return;
    }

    const audioBase64 = uploadedFile ? await blobToBase64(new Blob([uploadedFile], { type: uploadedFile.type })) : '';
    const contactIds = sources
      .filter(({ sourceType }) => sourceType === "contacts")
      .map(({ sourceValue }) => +sourceValue);
    const contactGroupIds = sources
      .filter(({ sourceType }) => sourceType === "contact-groups")
      .map(({ sourceValue }) => +sourceValue);
    const phones = sources
      .filter(({ sourceType }) => sourceType === "phone")
      .map(({ sourceValue }) => `+${sourceValue}`);

    try {
      if (rule) {
        await handleUpdateRule({
          callScenarioId: rule.call_scenario_id,
          contactIds,
          contactGroupIds,
          phones,
          ...(data.name !== rule.name ? { name: data.name } : {}),
          ...(audioBase64 && uploadedFile ? { audioFileBase64: audioBase64, audioFileName: uploadedFile.name } : {}),
        })

        handleUpdateRules();
      } else {
        await handleAddRule({
          dongleId: +data.dongleId,
          name: data.name,
          audioFileBase64: audioBase64,
          audioFileName: uploadedFile?.name,
          contactIds,
          contactGroupIds,
          phones,
        })

        handleFetchRules();
      }

      handleHideModal();
    } catch {} finally {
      setSubmitLoading(false);
    }
  }

  return (
    <Drawer
      isOpen={true}
      close={handleHideModal}
      title={rule?.name || 'Create rule'}
      limitedWidth={false}
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          name: rule?.name,
          dongleId: String(rule?.dongle_id)
        }}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={styles.Form}>
            <InputField
              name={'name'}
              validate={ruleRequired()}
              markAsRequired={true}
              label={'Name'}
              placeholder={'Name of rule'}
            />
            <SelectSearchField
              name="dongleId"
              validate={!rule ? ruleRequired() : undefined}
              options={dongleOptions}
              label={'Modem'}
              placeholder={'Modem'}
              markAsRequired={true}
              dropdownElementsInView={12}
              disabled={rule}
              {...(rule?.dongle?.name ? { defaultInputValue: rule.dongle.name } : {})}
            />
            <PhoneMultipleSettings
              title='Numbers'
              sources={sources}
              setSources={setSources}
              phoneSourceVariant={'all'}
              error={errors?.phones as boolean}
              allPhones={allPhones}
              setAllPhones={setAllPhones}
            />

            {uploadedFile || rule?.file?.cdn_url ? (
              <div className={clsx(styles.UploadedFile, errors?.uploadedFile && styles.UploadedFile_error)}>
                <div className={styles.UploadedFile__Container}>
                  <div>
                    <WavSurfer
                      blobData={!rule?.file?.cdn_url && uploadedFile ? new Blob([uploadedFile], { type: uploadedFile.type }) : undefined}
                      audioSource={rule?.file?.cdn_url}
                      audioWavesWidth={200}
                      theme={WavSurferThemes.black}
                    />
                  </div>
                  <div className={styles.UploadedFile__Delete}>
                    <IconButton
                      icon={'trash'}
                      onClick={() => {
                        setUploadedFile(null)
                        setRule((prev: any) => ({
                          ...prev,
                          file: {
                            cdn_url: null
                          }
                        }));
                      }}
                    />
                  </div>
                </div>
                {uploadedFile && (
                  <div className={styles.UploadedFile__Info}>
                    <div className={styles.UploadedFile__Name}>
                      {uploadedFile.name}
                    </div>
                    <div className={styles.UploadedFile__Size}>
                      {formatFileSize(uploadedFile.size)}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <AudioUpload />
            )}

            {typeof errors?.uploadedFile === 'string' && (
              <div className={styles.FileError}>
                {errors?.uploadedFile}
              </div>
            )}

            <div className={styles.Submit}>
              <Button
                type={'submit'}
                text={rule ? 'Update' : 'Create'}
                loading={submitLoading}
                disabled={submitLoading}
              />
            </div>
          </form>
        )}
      />
    </Drawer>
  )
}