import { SignInPage } from '../../../../../../../pages/auth/subpages/sign-in/SignInPage'
import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'

export const signIn: RouteDescriptor<AuthRoute.SignIn> = {
  route: AuthRoute.SignIn,
  type: 'subpage',
  title: 'Teleleo',
  path: AuthRoutePath[AuthRoute.SignIn],
  render: SignInPage,
  accessLevel: 'guest',
  noAccessRedirectPath: RoutePath[Route.Dashboard],
}
