import {ReactNode, useState} from "react";
import {TABLE_FILTERS_VARIANTS, TableHeaderProps} from "../components/TableHeader/TableHeader";
import {isObject} from "../../../../lib/utils/is-object/is-object";

export type HeaderCol = {
  id: string | number;
  label?: string;
  filterType?: TABLE_FILTERS_VARIANTS;
  defaultText?: string;
  defaultTextIcon?: ReactNode;
  hideDefaultTextIconAfterSelectedValue?: boolean;
  checkboxes?: Array<{ text: string; value: string }>;
  sort?: boolean;
  postfix?: ReactNode;
};


export const useTableFilters = (headerCols: HeaderCol[]) => {
  const [currentFilters, setCurrentFilters] = useState<TableHeaderProps["currentFilters"]>(
    headerCols.reduce((reducer, col) => {
      return {
        ...reducer,
        [col.id]: null,
      };
    }, {} as TableHeaderProps["currentFilters"])
  );

  console.log('currentFilters', currentFilters)

  const handleChangeFilters: TableHeaderProps['handleChangeFilters'] = (colId, value) => {
    setCurrentFilters((prev) => {
      const prevValue = prev[colId];

      if (isObject(value)) {
        const newValue = isObject(prevValue) ? prevValue : {};

        return {
          ...prev,
          [colId]: {
            ...newValue,
            ...value,
          },
        };
      }

      return {
        ...prev,
        [colId]: value,
      };
    });
  };


  return {
    currentFilters,
    setCurrentFilters,
    handleChangeFilters
  };
};
