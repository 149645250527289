import { RouteDescriptor } from './types/routeDescriptor'
import {CallsRoute} from "./enums/callsRoute";
import {callsList} from "./route-descriptors/user/lk/subpages/calls/subpages/calls-list/calls-list";
import {callsSettings} from "./route-descriptors/user/lk/subpages/calls/subpages/calls-settings/calls-settings";
import {callsRedirects} from "./route-descriptors/user/lk/subpages/calls/subpages/calls-redirects/calls-redirects";
import {
  callsAutoResponseRules
} from "./route-descriptors/user/lk/subpages/calls/subpages/calls-auto-response-rules/calls-auto-response-rules";
import {
  callsAutoResponse
} from "./route-descriptors/user/lk/subpages/calls/subpages/calls-auto-response/calls-auto-response";

export const callsRoutes: { [key in CallsRoute]: RouteDescriptor<key> } = {
  [CallsRoute.CallsList]: callsList,
  [CallsRoute.CallsRedirects]: callsRedirects,
  [CallsRoute.CallsSettings]: callsSettings,
  [CallsRoute.CallsAutoResponse]: callsAutoResponse,
  [CallsRoute.CallsAutoResponseRules]: callsAutoResponseRules,
}
