import { parseStringAsUTCDate } from '../../../../../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import {
  CallListBackendResponse,
  CallListResponse,
} from '../../../../../types/call/_crud/list'

export const mapCallListBackendToCallList = (
  item: CallListBackendResponse,
): CallListResponse => {
  const { calls, total_count } = item.data

  return {
    // @ts-ignore
    calls: calls.map((el) => {
      return {
        dongleId: el.dongle_id,
        dongleName: el.dongle_name,
        callId: el.call_id,
        callTypeId: el.call_type_id,
        callStatusId: el.call_status_id,
        numberCaller: el.number_caller,
        numberReceiver: el.number_receiver,
        duration: el.duration,
        apiResponseData: el.api_response_data,
        createdAt: parseStringAsUTCDate(el.created_at),
        updatedAt: parseStringAsUTCDate(el.updated_at),
        contact: el.contact
          ? {
              contactId: el.contact.contact_id,
              name: el.contact.name,
              phone: el.contact.phone,
              userId: el.contact.user_id,
            }
          : null,
        ...(el?.file ? { file: { cdnUrl: el?.file?.cdn_url }} : {}),
        ...(el?.call_scenario_file ? { callScenarioFile: { cdnUrl: el?.call_scenario_file?.cdn_url }} : {}),
      }
    }),
    totalCount: total_count,
  }
}
