import React, {Dispatch, FC, memo, ReactNode, SetStateAction, useEffect, useMemo, useState} from 'react'
import { useIntl } from 'react-intl'
import { CommonTableMessages } from '../../../config/intl/common-messages/common-table-messages/CommonTableMessages'
import { CallListResponse } from '../../../sdk/datagates/types/call/_crud/list'
import {
  Table,
  TableColType,
} from '../../../shared/ui-kit-2/data-display/table/Table'
import { LC } from '../../../tests/e2e/locators'
import { NoDevicesForCalls } from '../../status-layouts/no-devices-for-calls/NoDevicesForCalls'
import { CallTableRows } from './components/call-table-rows/CallTableRows'
import { ReactComponent as CaretDownIcon } from '../../../assets/icons/v2/ic-caret-down.svg'
import {
  TABLE_FILTERS_VARIANTS,
} from "../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
  IconContainer,
} from "../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import {
  HeaderCol,
  useTableFilters
} from "../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {DongleListResponse} from "../../../sdk/datagates/types/dongle/_crud/list";
import {QueryParameter} from "../../../sdk/datagates/helpers/_common/wrap-api-request";
import {
  tableFiltersToUrlFilters
} from "../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";
import _ from "lodash";


interface CallTableProps {
  calls: CallListResponse['calls'],
  devices: DongleListResponse['dongles'],
  totalCount: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
  pageLimit: number
  loading: boolean
  handleDeleteCall: (dongleId: number, callId: number) => Promise<void>
  handleFetchCalls: ({ params = [], hidden }: { params?: QueryParameter[], hidden?: boolean }) => void
  initialFetchCompleted: boolean
  setInitialFetchCompleted: Dispatch<SetStateAction<boolean>>
}


// TODO: Rename to CallsTable
export const CallTable: FC<CallTableProps> = memo((props) => {
  const {
    calls,
    devices,
    page,
    setPage,
    setLimit,
    pageLimit,
    totalCount,
    loading,
    handleDeleteCall,
    handleFetchCalls,
    initialFetchCompleted,
    setInitialFetchCompleted
  } = props

  const intl = useIntl()

  const handlePageChange = (page: number, limit: number) => {
    setPage(page)
    setLimit(limit)
  }

  const cols: TableColType[] = [
    { title: intl.formatMessage(CommonTableMessages.columnType) },
    { title: intl.formatMessage(CommonTableMessages.columnTime) },
    { title: intl.formatMessage(CommonTableMessages.columnStatus) },
    { title: intl.formatMessage(CommonTableMessages.columnSender) },
    { title: intl.formatMessage(CommonTableMessages.columnReceiver) },
    { title: intl.formatMessage(CommonTableMessages.columnDuration) },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ]

  const rowGroups = CallTableRows(calls, handleDeleteCall);

  // const searchItemsDevices = useMemo(() => devices.map(({ dongleId, name }) => ({ text: name, value: dongleId })), [devices]);

  const COLS = {
    type: 'type',
  }

  const headerCols = [
    {
      id: COLS.type,
      filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
      label: 'Type',
      defaultText: 'Select',
      defaultTextIcon: (
        <IconContainer>
          <CaretDownIcon />
        </IconContainer>
      ),
      checkboxes: [
        { text: 'All', value: 'All' },
        { text: 'Incoming', value: '2' },
        { text: 'Outgoing', value: '1' },
      ]
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.callStatus],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.sender],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.recipient],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.duration],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const { currentFilters, handleChangeFilters } = useTableFilters(headerCols);

  useEffect(() => {
    const debouncedFetch = _.debounce(() => {
      handleFetchCalls({
        params: tableFiltersToUrlFilters([
          { name: 'from', value: currentFilters?.time?.from },
          { name: 'to', value: currentFilters?.time?.to },
          { name: 'call_type_ids[]', value: currentFilters?.type },
          { name: 'call_status_ids[]', value: currentFilters?.callStatus },
          { name: 'caller', value: currentFilters?.sender },
          { name: 'receiver', value: currentFilters?.recipient },
          { name: 'duration_presets[]', value: currentFilters?.duration },
        ])
      })
    }, 300);

    if (initialFetchCompleted) {
      debouncedFetch();
    } else {
      setInitialFetchCompleted(true);
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [currentFilters]);

  return (
    <Table
      headerCols={headerCols}
      currentFilters={currentFilters}
      handleChangeFilters={handleChangeFilters}
      isNew

      name={'CallTable'}
      cols={cols}
      rowGroups={rowGroups}
      currentPage={page}
      totalCount={totalCount}
      onPageChange={handlePageChange}
      itemsPerPage={pageLimit}
      loading={loading}
      columnWidths={['100px', '125px', '120px', 1, 1, '165px', 1]}
      testId={LC.CALLS.TABLE._}
      noDataComponent={devices.length > 0 ? undefined : <NoDevicesForCalls />}
    />
  )
})
