
import styles from './styles.module.scss';


const Card = ({ children, style }: { children: JSX.Element, style?: Record<string, string | number> }) => (
  <div className={styles.Card} style={style}>
    {children}
  </div>
);

export default Card;