import {useDevices} from "../use-devices/useDevices";
import {useEffect, useState} from "react";

type VPNs = {
  dongleId: number,
  name: string,
  phoneNumber: string,
  publicVPNEndpoint: string,
  publicVPNLogin: string,
  publicVPNPassword: string,
  isVPNConnected: boolean
  isOnline: boolean
}[];

export const useVPN = () => {
  const [VPNs, setVPNs] = useState<VPNs>([]);
  const { devices, handleUpdateDevice, loading } = useDevices({ page: 0, limit: 0, brandId: 2, takeAll: true });

  useEffect(() => {
    if (devices) {
      setVPNs(devices.map(({
        dongleId,
        name,
        phoneNumber,
        publicVPNEndpoint,
        publicVPNLogin,
        publicVPNPassword,
        isVPNConnected,
        isOnline,
      }) => {
        return {
          dongleId,
          name,
          phoneNumber,
          publicVPNEndpoint,
          publicVPNLogin,
          publicVPNPassword,
          isVPNConnected,
          isOnline,
        }
      }))
    }
  }, [devices]);

  return {
    VPNs,
    VPNsLoading: loading,
    handleUpdateVPN: handleUpdateDevice,
  }
}