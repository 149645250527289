
import {Table, TableColType} from "../../../../../../shared/ui-kit-2/data-display/table/Table";
import {Card} from "../../../../../../shared/ui-kit-2/data-display/card/Card";
import React, {useEffect, useMemo, useState} from "react";
import {CallType, useCalls} from "../../../../../../sdk/hooks/use-calls/useCalls";
import {
  CommonTableMessages
} from "../../../../../../config/intl/common-messages/common-table-messages/CommonTableMessages";
import {useIntl} from "react-intl";
import {formatDate} from "../../../../../../shared/lib/utils/date-utils/formatDate";
import {DialogVersion} from "../../../../../../store/reducers/dialog/types";
import {useDialog} from "../../../../../../app/hooks/useDialog";
import { ReactComponent as TrashIcon } from '../../../../../../assets/icons/trash.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../../../assets/icons/arrow-right.svg'
// TODO: перенести на стили этого компонента
import styles from '../calls-auto-response-rules/styles.module.scss';
import {useCallStatuses} from "../../../../../../sdk/hooks/use-call-statuses/useCallStatuses";
import {formatPhoneNumber} from "../../../../../../sdk/formatters/format-phone-number";
import clsx from "clsx";
import _, {isNumber} from "lodash";
import {WavSurfer, WavSurferThemes} from "../../../../../../shared/ui-kit-2/components/wav-surfer/WavSurfer";
import {WEB_SOCKETS_EVENTS_VALUES} from "../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES";
import {useWebSockets} from "../../../../../../shared/lib/hooks/useWebSockets";
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
  IconContainer
} from "../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import {HeaderCol, useTableFilters} from "../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {
  tableFiltersToUrlFilters
} from "../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";
import ControlHeader from "../../../../../../shared/ui-kit-3/ControlHeader/ControlHeader";


export const CallsAutoResponse = () => {
  const intl = useIntl();

  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(50)
  const { handleOpenDialog, handleHideDialog } = useDialog()
  const { getCallStatusStickerComponent } = useCallStatuses()

  const callTypesTab = useMemo(() => ['3'], []);
  const {
    calls,
    totalCount,
    handleDeleteCall,
    loading,
    handleFetch: handleFetchCalls,
    initialFetchCompleted,
    setInitialFetchCompleted,
  } =
    useCalls({
      page,
      limit,
      callTypesTab,
    })

  const { webSocketsEventData } = useWebSockets({
    events: [WEB_SOCKETS_EVENTS_VALUES.call_created]
  });

  useEffect(() => {
    if (page === 1 && !loading) {
      handleFetchCalls({ hidden: true });
    }
  }, [webSocketsEventData]);

  const cols: TableColType[] = [
    { title: intl.formatMessage(CommonTableMessages.columnTime) },
    { title: intl.formatMessage(CommonTableMessages.columnStatus) },
    { title: intl.formatMessage(CommonTableMessages.columnSender) },
    { title: 'Recipient' },
    { title: 'Duration' },
    { title: 'Audio file' },
    { title: 'Answer' },
    {
      title: 'Action',
      position: 'right',
    },
  ];

  const handleDialogDeleteCall = async (dongleId: number, callId: number) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Delete rule?',
        subtitle: 'This action cannot be undone',
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog()
        },
        submit: {
          icon: 'trash',
          text: 'Delete',
          onClick: () => {
            handleDeleteCall(dongleId, callId);
            handleFetchCalls();
            handleHideDialog();
          }
        },
      }
    })
  }

  // TODO: Need import global formatTime method
  const formatTime = (seconds: number) => {
    if (!isNumber(seconds)) {
      return '';
    }

    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${secs}`;
  };

  const rowGroups = useMemo(() => {
    if (Array.isArray(calls)) {
      return [{
        groupHeader: <></>,
        rows: calls.map(call => {
          const isDeviceSender = [CallType.OUTGOING].includes(
            call.callTypeId,
          )

          const contactSenderName = isDeviceSender
            ? call.dongleName
            : call?.contact?.name || ''

          const contactReceiverName = isDeviceSender
            ? call?.contact?.name || ''
            : call.dongleName

          return [
            <div className={styles.Time}>
              {formatDate(call.createdAt, 0, 'time')}
            </div>,
            <div>{getCallStatusStickerComponent(call.callStatusId)}</div>,
            <div className={styles.Contact}>
              <div className={styles.Contact__container}>
                <div className={styles.Contact__name}>
                  {contactSenderName}
                </div>
                <div className={styles.Contact__phone}>
                  {formatPhoneNumber(call.numberCaller)}
                </div>
              </div>
              <ArrowRightIcon />
            </div>,
            <div className={styles.Contact}>
              <div className={styles.Contact__container}>
                <div
                  className={clsx(
                    styles.Contact__name,
                    styles.Contact__name_device,
                  )}
                >
                  {contactReceiverName}
                </div>
                <div className={styles.Contact__phone}>
                  {formatPhoneNumber(call.numberReceiver)}
                </div>
              </div>
            </div>,
            <div>
              {formatTime(call.duration)}
            </div>,
            <div>
              {call?.file?.cdnUrl && (
                <WavSurfer
                  audioSource={call.callScenarioFile.cdnUrl}
                  audioWavesWidth={61}
                  showDuration={false}
                  theme={WavSurferThemes.black}
                />
              )}
            </div>,
            <div>
              {call?.callScenarioFile?.cdnUrl && (
                <WavSurfer
                  audioSource={call.file.cdnUrl}
                  audioWavesWidth={61}
                  showDuration={false}
                  theme={WavSurferThemes.black}
                />
              )}
            </div>,
            <div className={styles.Actions}>
              <button
                className={styles.Action}
                onClick={() => handleDialogDeleteCall(call.dongleId, call.callId)}
              >
                <TrashIcon/>
              </button>
            </div>,
          ]
        })
      }]
    }

    return [];
  }, [calls]);

  const handlePageChange = (page: number, limit: number) => {
    setPage(page)
    setLimit(limit)
  }

  const headerCols = [
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.callStatus],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.sender],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.recipient],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.duration],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.audioFile],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.audioAnswer],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const { currentFilters, handleChangeFilters } = useTableFilters(headerCols);

  useEffect(() => {
    const debouncedFetch = _.debounce(() => {
      handleFetchCalls({
        params: tableFiltersToUrlFilters([
          { name: 'from', value: currentFilters?.time?.from },
          { name: 'to', value: currentFilters?.time?.to },
          { name: 'call_type_ids[]', value: currentFilters?.type },
          { name: 'call_status_ids[]', value: currentFilters?.callStatus },
          { name: 'caller', value: currentFilters?.sender },
          { name: 'receiver', value: currentFilters?.recipient },
          { name: 'duration_presets[]', value: currentFilters?.duration },
          {
            name: 'is_empty_call_scenario_file_id',
            value: currentFilters?.audioFile && !currentFilters?.audioFile.includes('All')
              ? currentFilters.audioFile[0]
              : undefined
          },
          {
            name: 'is_empty_file_id',
            value: currentFilters?.audioAnswer && !currentFilters?.audioAnswer.includes('All')
              ? currentFilters.audioAnswer[0]
              : undefined
          },
        ])
      })
    }, 300);

    if (initialFetchCompleted) {
      debouncedFetch();
    } else {
      setInitialFetchCompleted(true);
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [currentFilters]);

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.CallAutoResponseRules}>
        <ControlHeader
          title={'Autoreply'}
          counter={{
            min: calls.length,
            max: totalCount
          }}
          actions={[]}
          loading={loading}
        />

        <Table
          headerCols={headerCols}
          currentFilters={currentFilters}
          handleChangeFilters={handleChangeFilters}
          isNew

          name={'CallAutoResponse'}
          cols={cols}
          rowGroups={rowGroups}
          currentPage={page}
          totalCount={totalCount}
          onPageChange={handlePageChange}
          loading={loading}
          columnWidths={['125px', 2, 2, 2, '165px', 2, 2, 1]}
          itemsPerPage={limit}
        />
      </div>
    </Card>
  )
}