import React, { FC } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

export type StickerColorType =
  | 'lightGreen'
  | 'red'
  | 'yellow'
  | 'blue'
  | 'purple'
  | 'green'
  | 'greenOutline'
  | 'grey'

type StickerType = {
  text: string
  color?: StickerColorType
  testId?: string
}

export const Sticker: FC<StickerType> = ({ text, color, testId }) => {
  return (
    <div
      className={clsx(styles.Sticker, styles[`Sticker_${color}`])}
      data-test-id={testId}
    >
      {text}
    </div>
  )
}
