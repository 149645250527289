import { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { queryFromFilters } from '../../../layouts/filters/_helpers/queryFromFilters'
import { FilterType } from '../../../layouts/filters/hook/useFilters'
import { SNACKBAR_TYPES } from '../../../layouts/snackbar/SnackbarProvider'
import { getErrorMessage } from '../../../shared/lib/utils/get-error-message/getErrorMessage'
import {
  mailingDeleteDatagate,
  mailingListDatagate,
  mailingUpdateDatagate,
} from '../../datagates/api/mailing'
import { useBackendErrorCodes } from '../../datagates/helpers/_common/use-backend-error-codes'
import { QueryParameter } from '../../datagates/helpers/_common/wrap-api-request'
import { MailingListResponse } from '../../datagates/types/mailing/_crud/list'
import { MAILING_STATUSES } from '../use-mailing-statuses/constants/MailingStatuses'

interface UseMailingsProps {
  page: number
  limit: number
  mailingTab: string
  filters?: FilterType
}

export const MAILING_TYPE_IDS = {
  SIMPLE: 1,
  DELAYED: 2,
}

export const MailingTabs = {
  ALL: '',
  ENDED: 'ended',
  PLANNED: 'planned',
  DRAFT: 'draft',
}

const MailingTabValues = {
  [MailingTabs.ENDED]: [MAILING_STATUSES.ENDED.toString()],
  [MailingTabs.PLANNED]: [
    MAILING_STATUSES.CANCELLED.toString(),
    MAILING_STATUSES.PLANNED.toString(),
  ],
  [MailingTabs.DRAFT]: [MAILING_STATUSES.DRAFT.toString()],
}

const UseMailingsMessages = defineMessages({
  positiveDeleted: {
    id: 'UseMailingsMessages.positiveDeleted',
    defaultMessage: 'Mailing is successfully deleted',
  },
  negativeDeleted: {
    id: 'UseMailingsMessages.negativeDeleted',
    defaultMessage: 'An error occurred while deleting mailing',
  },
})

export const useMailings = (props: UseMailingsProps) => {
  const { page, limit, mailingTab, filters } = props

  const intl = useIntl()
  const { resolveBackendError } = useBackendErrorCodes()
  const { handleHideModal } = useModal()
  const { handleOpenSnackbar } = useSnackbar()

  const [mailings, setMailings] = useState<MailingListResponse['mailings']>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [initialFetchCompleted, setInitialFetchCompleted] = useState<boolean>(false)

  const handleDeleteMailing = async (mailingId: number) => {
    try {
      const urlParams = [{ name: 'mailing_id', value: mailingId.toString() }]

      await mailingDeleteDatagate(null, urlParams)
      await handleFetch()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseMailingsMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseMailingsMessages.negativeDeleted),
      })
    }
  }

  const handleCancelMailing = async (mailingId: number) => {
    try {
      const urlParams = [{ name: 'mailing_id', value: mailingId.toString() }]

      await mailingUpdateDatagate(
        { mailing_status_id: MAILING_STATUSES.CANCELLED },
        urlParams,
      )
      await handleFetch()
      handleHideModal()
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseMailingsMessages.positiveDeleted),
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.success,
        text: intl.formatMessage(UseMailingsMessages.negativeDeleted),
      })
    }
  }

  const handleFetch = async ({ params = [], hidden }: { params?: QueryParameter[], hidden?: boolean } = {}) => {
    try {
      setLoading(!hidden)

      const queryParams: QueryParameter[] = [
        { name: 'page', value: (page - 1).toString() },
        { name: 'limit', value: limit.toString() },
        { name: 'order_by', value: 'created_at' },
        { name: 'order_type', value: 'desc' },
        { name: 'is_deleted', value: '0' },
        ...params
      ]

      if (mailingTab && mailingTab !== MailingTabs.ALL)
        queryParams.push({
          name: 'mailing_status_ids',
          value: MailingTabValues[mailingTab],
        })

      if (filters) {
        const filterQueryParams = queryFromFilters(filters)
        queryParams.push(...filterQueryParams)
      }

      const { data } = await mailingListDatagate(null, null, queryParams)

      setTotalCount(data.totalCount)
      setMailings(data.mailings)
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [page, mailingTab, filters, limit])

  return {
    mailings,
    totalCount,
    loading,
    handleFetch,
    handleDeleteMailing,
    handleCancelMailing,
    initialFetchCompleted,
    setInitialFetchCompleted
  }
}
