import React, {Dispatch, FC, SetStateAction, useEffect} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { CommonTableMessages } from '../../../config/intl/common-messages/common-table-messages/CommonTableMessages'
import { MailingListResponse } from '../../../sdk/datagates/types/mailing/_crud/list'
import {
  Table,
  TableColType,
} from '../../../shared/ui-kit-2/data-display/table/Table'
import { MailingTableRows } from './components/sms-mailing-table-rows/SmsMailingTableRows'
import {TABLE_FILTERS_VARIANTS} from "../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
} from "../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';
import {HeaderCol, useTableFilters} from "../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {
  tableFiltersToUrlFilters
} from "../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";
import {QueryParameter} from "../../../sdk/datagates/helpers/_common/wrap-api-request";
import _ from "lodash";


const MailingTableMessage = defineMessages({
  name: {
    id: 'MailingTable.name',
    defaultMessage: 'Campaign Name',
  },
})

interface MailingTableProps {
  mailings: MailingListResponse['mailings']
  totalCount: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
  pageLimit: number
  loading: boolean
  handleFetch: (props: { params?: QueryParameter[] | undefined; hidden?: boolean | undefined }) => Promise<void>
  initialFetchCompleted: boolean
  setInitialFetchCompleted: Dispatch<SetStateAction<boolean>>
}

export const MailingTable: FC<MailingTableProps> = (props) => {
  const {
    mailings,
    page,
    setPage,
    setLimit,
    pageLimit,
    totalCount,
    loading,
    handleFetch,
    initialFetchCompleted,
    setInitialFetchCompleted,
  } = props

  const intl = useIntl()

  const handlePageChange = (page: number, limit: number) => {
    setPage(page)
    setLimit(limit)
  }

  const cols: TableColType[] = [
    { title: intl.formatMessage(MailingTableMessage.name) },
    { title: intl.formatMessage(CommonTableMessages.columnDevice) },
    { title: intl.formatMessage(CommonTableMessages.columnAmountOfNumbers) },
    { title: intl.formatMessage(CommonTableMessages.columnText) },
    { title: intl.formatMessage(CommonTableMessages.columnSendTime) },
    { title: intl.formatMessage(CommonTableMessages.columnStatus) },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ]

  const rowGroups = MailingTableRows(mailings, handleFetch)

  const COLS = {
    campaignName: 'campaignName',
    modemName: 'modemName',
    recipients: 'recipients',
    sendTime: 'sendTime',
  }

  const headerCols = [
    {
      id: COLS.campaignName,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Campaign name',
      defaultText: 'Enter the name',
    },
    {
      id: COLS.modemName,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Modem',
      defaultText: 'Enter the name',
      postfix: (
        <ArrowRightIcon />
      ),
    },
    {
      id: COLS.recipients,
      label: 'Recipients',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.textMessage],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.mailingStatus],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const { currentFilters, handleChangeFilters } = useTableFilters(headerCols);

  useEffect(() => {
    const debouncedFetch = _.debounce(() => {
      handleFetch({
        params: tableFiltersToUrlFilters([
          { name: 'name', value: currentFilters?.campaignName },
          { name: 'dongle_name', value: currentFilters?.modemName },
          { name: 'sms_type_ids[]', value: currentFilters?.type },
          { name: 'from', value: currentFilters?.time?.from },
          { name: 'to', value: currentFilters?.time?.to },
          { name: 'text', value: currentFilters?.textMessage },
          { name: 'sms_status_ids[]', value: currentFilters?.mailingStatus },
        ])
      })
    }, 300);

    if (initialFetchCompleted) {
      debouncedFetch();
    } else {
      setInitialFetchCompleted(true);
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [currentFilters]);

  return (
    <Table
      headerCols={headerCols}
      currentFilters={currentFilters}
      handleChangeFilters={handleChangeFilters}
      isNew

      name={'SmsMailing'}
      cols={cols}
      rowGroups={rowGroups}
      currentPage={page}
      totalCount={totalCount}
      onPageChange={handlePageChange}
      itemsPerPage={pageLimit}
      loading={loading}
      columnWidths={[1, 1, 1, 2, 1, 1, 0.5]}
    />
  )
}
