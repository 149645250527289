import React from 'react'
import { Card } from '../../../shared/ui-kit-2/data-display/card/Card'
import styles from './styles.module.scss'
import {useSiteVersion} from "../../../app/hooks/useSiteVersion";
import {RuPrivacyPolicy} from "./components/RuPrivacyPolicy/RuPrivacyPolicy";
import {EngPrivacyPolicy} from "./components/EngPrivacyPolicy/EngPrivacyPolicy";

export const PrivacyPolicyPage = () => {
  const { siteVersion } = useSiteVersion()
  
  const isRu = siteVersion === 'ru';

  return (
    <div className={styles.Page}>
      <Card additionalClassNames={[styles.Container]}>
        {isRu ? <RuPrivacyPolicy /> : <EngPrivacyPolicy />}
      </Card>
    </div>
  )
}
