import React, {FC, useEffect, useMemo, useState} from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../app/hooks/useModal'
import { useSnackbar } from '../../../app/hooks/useSnackbar'
import { CommonMultiselectMessages } from '../../../config/intl/common-messages/common-multiselect-messages/CommonMultiselectMessages'
import { CommonTabMessages } from '../../../config/intl/common-messages/common-tab-messages/CommonTabMessages'
import { CallTypeTabs } from '../../../sdk/hooks/use-calls/useCalls'
import { MailingTabs } from '../../../sdk/hooks/use-mailings/useMailings'
import { SmsTypeTabs } from '../../../sdk/hooks/use-smses/useSmses'
import { formatDate } from '../../../shared/lib/form/form-field-adapters/v2/datepicker-field/_helpers/formatDate'
import { DatepickerField } from '../../../shared/lib/form/form-field-adapters/v2/datepicker-field/DatepickerField'
import { InputField } from '../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { MultiselectField } from '../../../shared/lib/form/form-field-adapters/v2/multiselect-field/MultiselectField'
import { TextareaField } from '../../../shared/lib/form/form-field-adapters/v2/textarea-field/TextareaField'
import { clearPhoneMutator } from '../../../shared/lib/form/form-helpers/mutators'
import {
  composeValidators,
  useFormRules,
} from '../../../shared/lib/form/form-rules'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../shared/lib/hooks/useLessThanDeviceScreen'
import { parseStringAsUTCDate } from '../../../shared/lib/utils/date-utils/parseStringAsUTCDate'
import { parsePhoneInfo } from '../../../shared/lib/utils/libphonenumber/parse-phone-info'
import { Drawer } from '../../../shared/ui-kit-2/data-display/drawer/Drawer'
import { Button } from '../../../shared/ui-kit-2/inputs/button/Button'
import { MultiselectOption } from '../../../shared/ui-kit-2/inputs/multiselect/Multiselect'
import { RadioProps } from '../../../shared/ui-kit-2/inputs/radio/components/Radio'
import { RadioGroup } from '../../../shared/ui-kit-2/inputs/radio/RadioGroup'
import { SegmentedControl } from '../../../shared/ui-kit-2/inputs/segmented-control/SegmentedControl'
import { Switcher } from '../../../shared/ui-kit-2/inputs/switcher/Switcher'
import { LC } from '../../../tests/e2e/locators'
import { FilterType } from '../../filters/hook/useFilters'
import { SNACKBAR_TYPES } from '../../snackbar/SnackbarProvider'
import styles from './styles.module.scss'
import {
  PhoneInputFieldNumber
} from "../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew";
import {
  getClearedPhoneNumber,
} from "../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber";
import {invertObject} from "../../../shared/lib/utils/invert-object/invert-object";
import {PHONE_NUMBER_VARIANTS} from "../../../shared/lib/constants/PHONE_NUMBER_VARIANTS";

type Props = {
  handleClose?: () => void
}

const FiltersModalMessages = defineMessages({
  title: {
    id: 'FiltersModalMessages.title',
    defaultMessage: 'Filters',
  },
  devicesLabel: {
    id: 'FiltersModalMessages.devicesLabel',
    defaultMessage: 'Modems',
  },
  devicesPlaceholder: {
    id: 'FiltersModalMessages.devicesPlaceholder',
    defaultMessage: 'Specify modems',
  },
  dateRangeLabel: {
    id: 'FiltersModalMessages.dateRangeLabel',
    defaultMessage: 'Period',
  },
  dateFromPlaceholder: {
    id: 'FiltersModalMessages.dateFromPlaceholder',
    defaultMessage: 'From',
  },
  dateToPlaceholder: {
    id: 'FiltersModalMessages.dateToPlaceholder',
    defaultMessage: 'To',
  },
  phoneNumbersSwitcherLabel: {
    id: 'FiltersModalMessages.phoneNumbersSwitcherLabel',
    defaultMessage: 'Filter by number',
  },
  optionInternational: {
    id: 'FiltersModalMessages.optionInternational',
    defaultMessage: 'International',
  },
  optionShort: {
    id: 'FiltersModalMessages.optionShort',
    defaultMessage: 'Short',
  },
  optionAlphaNumber: {
    id: 'FiltersModalMessages.optionAlphaNumber',
    defaultMessage: 'Alpha name',
  },
  phoneLabel: {
    id: 'FiltersModalMessages.phoneLabel',
    defaultMessage: 'Phone number',
  },
  alphaNumberPlaceholder: {
    id: 'FiltersModalMessages.alphaNumberPlaceholder',
    defaultMessage: 'For example: google',
  },
  shortNumberPlaceholder: {
    id: 'FiltersModalMessages.shortNumberPlaceholder',
    defaultMessage: '12345',
  },
  smsTextLabel: {
    id: 'FiltersModalMessages.smsTextLabel',
    defaultMessage: 'Filter by SMS Content',
  },
  smsTextPlaceholder: {
    id: 'FiltersModalMessages.smsTextPlaceholder',
    defaultMessage: 'Enter text',
  },
  apply: {
    id: 'FiltersModalMessages.apply',
    defaultMessage: 'Apply',
  },
  clearAll: {
    id: 'FiltersModalMessages.clearAll',
    defaultMessage: 'Clear all',
  },
  error: {
    id: 'FiltersModalMessages.error',
    defaultMessage: 'An error occurred while applying filters',
  },
})

const getTypeTab = (pathname: string) => {
  if (pathname.includes('/sms/list')) {
    return 'sms_type_ids'
  } else if (pathname.includes('/sms/mailing')) {
    return 'mailing_status_ids'
  } else if (pathname.includes('/call')) {
    return 'call_type_ids'
  } else {
    return ''
  }
}

export const FiltersModal: FC<Props> = () => {
  const intl = useIntl()
  const { handleHideModal, props } = useModal()

  const {
    closeFilters,
    deviceOptions,
    filters,
    setFilters,
    filterFields,
    testIds,
    typeTab,
    setTypeTab,
    pathname,
  } = props

  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const { handleOpenSnackbar } = useSnackbar()
  const { ruleShortPhone, ruleAlphaNumber } = useFormRules()
  const [phoneNumbersOn, setPhoneNumbersOn] = useState<boolean>(filters["phone_number_type"])

  const { internationalPhone, shortNumber, alphaName } =
    parsePhoneInfo(filters.number)

  const [typeTabPageId, setIsTypeTabPageId] = useState<string | undefined>(
    typeTab,
  )
  const currentTypeTabPage = getTypeTab(pathname)

  const [phoneType, setPhoneType] = useState<string>(() => {
    if (filters["phone_number_type"]) {
      return invertObject(PHONE_NUMBER_VARIANTS)[filters["phone_number_type"]]
    }

    if (shortNumber) {
      return PHONE_NUMBER_VARIANTS.short
    } else if (alphaName) {
      return PHONE_NUMBER_VARIANTS.alpha
    } else {
      return PHONE_NUMBER_VARIANTS.international
    }
  })

  const defaultDateFrom = useMemo(
    () => (filters.from ? parseStringAsUTCDate(filters.from) : undefined),
    [filters],
  )
  const defaultDateTo = useMemo(
    () => (filters.to ? parseStringAsUTCDate(filters.to) : undefined),
    [filters],
  )

  const onSubmit = (values: FilterType) => {
    try {
      let formattedValues: FilterType = {}

      if (values.dongle_ids && values.dongle_ids.length > 0) {
        formattedValues.dongle_ids = values.dongle_ids
      }

      if (values.from) {
        formattedValues.from = formatDate(values.from)
      }

      if (values.to) {
        formattedValues.to = formatDate(values.to)
      }

      if (values.text) {
        formattedValues.text = values.text
      }

      if (!phoneNumbersOn) {
        delete formattedValues.number
        delete formattedValues.smsSourceTypeIds
      } else {
        if (phoneType) {
          formattedValues["phone_number_type"] = PHONE_NUMBER_VARIANTS[phoneType]
        }

        if (phoneType === PHONE_NUMBER_VARIANTS.alpha) {
          formattedValues.number = values.alphaName
        }

        if (phoneType === PHONE_NUMBER_VARIANTS.international) {
          // @ts-ignore
          formattedValues.number = getClearedPhoneNumber(values.internationalPhone)
        }

        if (phoneType === PHONE_NUMBER_VARIANTS.short) {
          formattedValues.number = values.shortNumber
        }
      }

      setFilters(formattedValues)
      setTypeTab(typeTabPageId)
      onClose()
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(FiltersModalMessages.error),
      })
    }
  }

  const showDates = useMemo(
    () => filterFields.some((f: string) => f === 'dates'),
    [filterFields],
  )
  const showDevices = useMemo(
    () => filterFields.some((f: string) => f === 'devices'),
    [filterFields],
  )
  const showPhoneNumbers = useMemo(
    () => filterFields.some((f: string) => f === 'phone-numbers'),
    [filterFields],
  )
  const showSmsText = useMemo(
    () => filterFields.some((f: string) => f === 'sms-text'),
    [filterFields],
  )

  const onClose = () => {
    closeFilters()
    handleHideModal()
  }

  const clearAll = () => {
    setFilters({})
    onClose()
  }

  const getSegmentedGroupByTypeTab = (typeTab: string) => {
    switch (typeTab) {
      case 'sms_type_ids':
        return smsTypeSegmentedGroup
      case 'mailing_status_ids':
        return mailingStatusSegmentedGroup
      case 'call_type_ids':
        return callTypeSegmentedGroup
      default:
        return []
    }
  }

  const callTypeSegmentedGroup = useMemo(
    () => [
      {
        value: CallTypeTabs.ALL,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterAll),
        onClick: () => setIsTypeTabPageId(CallTypeTabs.ALL),
        testId: LC.CALLS.TYPE.ALL,
      },
      {
        value: CallTypeTabs.INCOMING,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterIncoming),
        onClick: () => setIsTypeTabPageId(CallTypeTabs.INCOMING),
        testId: LC.CALLS.TYPE.INCOMING,
      },
      {
        value: CallTypeTabs.OUTGOING,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterOutgoing),
        onClick: () => setIsTypeTabPageId(CallTypeTabs.OUTGOING),
        testId: LC.CALLS.TYPE.OUTGOING,
      },
      // {
      //   value: CallTypeTabs.DELAYED,
      //   comparedValue: callTypeTab,
      //   label: intl.formatMessage(CommonTabMessages.filterDelayed),
      //   onClick: () => setCallTypeTab(CallTypeTabs.DELAYED),
      //   testId: LC.CALLS.TYPE.DELAYED
      // },
    ],
    [typeTabPageId],
  )

  const mailingStatusSegmentedGroup = useMemo(
    () => [
      {
        value: MailingTabs.ALL,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterAll),
        onClick: () => setIsTypeTabPageId(MailingTabs.ALL),
      },
      {
        value: MailingTabs.ENDED,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterEnded),
        onClick: () => setIsTypeTabPageId(MailingTabs.ENDED),
      },
      {
        value: MailingTabs.PLANNED,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterPlanned),
        onClick: () => setIsTypeTabPageId(MailingTabs.PLANNED),
      },
      {
        value: MailingTabs.DRAFT,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterDraft),
        onClick: () => setIsTypeTabPageId(MailingTabs.DRAFT),
      },
    ],
    [typeTabPageId],
  )

  const smsTypeSegmentedGroup = useMemo(
    () => [
      {
        value: SmsTypeTabs.ALL,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterAll),
        onClick: () => setIsTypeTabPageId(SmsTypeTabs.ALL),
        testId: LC.SMS.TYPE.ALL,
      },
      {
        value: SmsTypeTabs.INCOMING,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterIncoming),
        onClick: () => setIsTypeTabPageId(SmsTypeTabs.INCOMING),
        testId: LC.SMS.TYPE.INCOMING,
      },
      {
        value: SmsTypeTabs.OUTGOING,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterOutgoing),
        onClick: () => setIsTypeTabPageId(SmsTypeTabs.OUTGOING),
        testId: LC.SMS.TYPE.OUTGOING,
      },
      {
        value: SmsTypeTabs.DELAYED,
        comparedValue: typeTabPageId,
        label: intl.formatMessage(CommonTabMessages.filterDelayed),
        onClick: () => setIsTypeTabPageId(SmsTypeTabs.DELAYED),
        testId: LC.SMS.TYPE.DELAYED,
      },
    ],
    [typeTabPageId],
  )

  const currentSegmentedGroup = useMemo(
    () => getSegmentedGroupByTypeTab(currentTypeTabPage),
    [
      currentTypeTabPage,
      smsTypeSegmentedGroup,
      mailingStatusSegmentedGroup,
      callTypeSegmentedGroup,
    ],
  )

  useEffect(() => {
    if (filters.hasOwnProperty('numbers')) {
      setFilters(() => ({
        ...filters,
        number: undefined
      }))
    }
  }, [phoneType]);

  return (
    <Drawer
      title={intl.formatMessage(FiltersModalMessages.title)}
      isOpen={true}
      close={onClose}
      additionalClassNames={[styles.DrawerAdditional]}
    >
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...clearPhoneMutator,
        }}
        initialValues={{
          dongle_ids: filters.dongle_ids,
          from: defaultDateFrom ? [defaultDateFrom] : undefined,
          to: defaultDateTo ? [defaultDateTo] : undefined,
          internationalPhone,
          shortNumber,
          alphaName,
          text: filters.text,
        }}
        render={({ handleSubmit, values, form }) => {
          const clearDisabled = !Object.values(values).some((v) => {
            if (Array.isArray(v)) {
              return v.length > 0
            }
            return v !== undefined
          })

          const defaultDeviceOptions = (
            deviceOptions as MultiselectOption[]
          ).filter((d) => filters.dongle_ids?.includes(d.value))

          const mutator = form.mutators.clearPhoneMutator

          const phoneTypeRadioOptions: RadioProps[] = [
            {
              label: intl.formatMessage(
                FiltersModalMessages.optionInternational,
              ),
              value: PHONE_NUMBER_VARIANTS.international,
              comparedValue: phoneType,
              onClick: () => {
                setPhoneType(PHONE_NUMBER_VARIANTS.international)
                mutator?.()
              },
              variant: 'outlined',
              additionalClassNames: [styles.PhoneNumbers__radioAdditional],
              testId: LC.SMS.FILTER.RADIO_GROUPS.INTERNATIONAL,
            },
            {
              label: intl.formatMessage(FiltersModalMessages.optionAlphaNumber),
              value: PHONE_NUMBER_VARIANTS.alpha,
              comparedValue: phoneType,
              onClick: () => {
                setPhoneType(PHONE_NUMBER_VARIANTS.alpha)
                mutator?.()
              },
              variant: 'outlined',
              testId: LC.SMS.FILTER.RADIO_GROUPS.ALPHA,
            },
          ];

          if (currentTypeTabPage === "sms_type_ids") {
            phoneTypeRadioOptions.push({
              label: intl.formatMessage(FiltersModalMessages.optionShort),
              value: PHONE_NUMBER_VARIANTS.short,
              comparedValue: phoneType,
              onClick: () => {
                setPhoneType(PHONE_NUMBER_VARIANTS.short)
                mutator?.()
              },
              variant: 'outlined',
              testId: LC.SMS.FILTER.RADIO_GROUPS.SHORT,
            })
          }

          return (
            <form onSubmit={handleSubmit} className={styles.Container}>
              {isMobile && currentSegmentedGroup.length > 0 && (
                <div className={styles.SegmentedControlContent}>
                  <SegmentedControl
                    name={currentTypeTabPage}
                    group={currentSegmentedGroup}
                  />
                </div>
              )}
              {showDevices && (
                <MultiselectField
                  testId={testIds?.devicesMultiSelectTestId}
                  name={'dongle_ids'}
                  options={deviceOptions}
                  defaultValues={defaultDeviceOptions}
                  label={intl.formatMessage(FiltersModalMessages.devicesLabel)}
                  placeholder={intl.formatMessage(
                    FiltersModalMessages.devicesPlaceholder,
                  )}
                  allOptionsSwitcherLabel={intl.formatMessage(
                    CommonMultiselectMessages.all,
                  )}
                  searchPlaceholder={intl.formatMessage(
                    CommonMultiselectMessages.search,
                  )}
                  notFoundText={intl.formatMessage(
                    CommonMultiselectMessages.nothingFound,
                  )}
                />
              )}
              {showDates && (
                <div className={styles.Dates}>
                  <DatepickerField
                    name={'from'}
                    label={intl.formatMessage(
                      FiltersModalMessages.dateRangeLabel,
                    )}
                    placeholder={intl.formatMessage(
                      FiltersModalMessages.dateFromPlaceholder,
                    )}
                    defaultDate={defaultDateFrom}
                    testId={testIds?.dateFromTestId}
                  />
                  <DatepickerField
                    name={'to'}
                    label={' '}
                    placeholder={intl.formatMessage(
                      FiltersModalMessages.dateToPlaceholder,
                    )}
                    defaultDate={defaultDateTo}
                    testId={testIds?.dateToTestId}
                  />
                </div>
              )}

              {showPhoneNumbers && (
                <div className={styles.PhoneNumbers}>
                  <Switcher
                    name={'phone-numbers'}
                    checked={phoneNumbersOn}
                    onChange={() => setPhoneNumbersOn((prev) => !prev)}
                    label={intl.formatMessage(
                      FiltersModalMessages.phoneNumbersSwitcherLabel,
                    )}
                    additionalClassNames={[
                      styles.PhoneNumbers__switcherAdditional,
                    ]}
                    testId={LC.SMS.FILTER.SWITCHER_PHONE_NUMBER}
                  />

                  {phoneNumbersOn && (
                    <>
                      <RadioGroup
                        name={'phone-types'}
                        group={phoneTypeRadioOptions}
                        additionalClassNames={[
                          styles.PhoneNumbers__radioGroupAdditional,
                        ]}
                      />

                      {phoneType === PHONE_NUMBER_VARIANTS.international && (
                        <PhoneInputFieldNumber
                          name="internationalPhone"
                          initialValue={internationalPhone ? filters.number : ''}
                          label={intl.formatMessage(
                            FiltersModalMessages.phoneLabel,
                          )}
                        />
                      )}

                      {phoneType === PHONE_NUMBER_VARIANTS.short && (
                        <InputField
                          name={'shortNumber'}
                          type={'tel'}
                          validate={composeValidators(
                            ruleShortPhone(),
                          )}
                          label={intl.formatMessage(
                            FiltersModalMessages.phoneLabel,
                          )}
                          placeholder={intl.formatMessage(
                            FiltersModalMessages.shortNumberPlaceholder,
                          )}
                        />
                      )}

                      {phoneType === PHONE_NUMBER_VARIANTS.alpha && (
                        <InputField
                          name={'alphaName'}
                          type={'tel'}
                          validate={composeValidators(
                            ruleAlphaNumber(),
                          )}
                          label={intl.formatMessage(
                            FiltersModalMessages.phoneLabel,
                          )}
                          placeholder={intl.formatMessage(
                            FiltersModalMessages.alphaNumberPlaceholder,
                          )}
                        />
                      )}
                    </>
                  )}
                </div>
              )}

              {showSmsText && (
                <TextareaField
                  name={'text'}
                  label={intl.formatMessage(FiltersModalMessages.smsTextLabel)}
                  placeholder={intl.formatMessage(
                    FiltersModalMessages.smsTextPlaceholder,
                  )}
                  testId={LC.SMS.FILTER.TEXT_AREA}
                />
              )}

              <div className={styles.Actions}>
                <Button
                  text={intl.formatMessage(FiltersModalMessages.clearAll)}
                  variant={'danger'}
                  icon={'trash'}
                  disabled={clearDisabled}
                  testId={testIds?.clearAllButtonTestId}
                  onClick={clearAll}
                />

                <Button
                  text={intl.formatMessage(FiltersModalMessages.apply)}
                  type={'submit'}
                  testId={testIds?.submitButtonTestId}
                />
              </div>
            </form>
          )
        }}
      ></Form>
    </Drawer>
  )
}
