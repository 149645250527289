
import {Button} from "../../../../../../shared/ui-kit-3/Button";
import { ReactComponent as DeviceIcon } from '../../../../../../assets/icons/v2/ic-device.svg';
import activateModemPng from '../../../../../../assets/images/v2/activate-modem.png';
import styles from './styles.module.scss';
import {useNavigate} from "react-router";
import {RoutePath} from "../../../../../../config/routes/constants/routePath";
import {Route} from "../../../../../../config/routes/enums/route";


const ActivateModem = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.ActivateModem}>
      <div className={styles.Background}>
        <img src={activateModemPng} alt={"Activate modem"} />
      </div>
      <div className={styles.Content}>
        <div className={styles.Title}>
          Activate your modem
        </div>
        <div className={styles.Steps}>
          <div className={styles.Steps__Count}>
            3
          </div>
          <div className={styles.Steps__Text}>
            Steps for {'\n'} comfortable use
          </div>
        </div>
        <Button
          variant={'white'}
          text={'Activate modem'}
          size={'sm'}
          prefix={<DeviceIcon />}
          onClick={() => navigate(RoutePath[Route.ActivateDevice])}
        />
      </div>
    </div>
  )
}

export default ActivateModem;