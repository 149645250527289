import React, { FC, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../app/hooks/useModal'
import { CommonTabMessages } from '../../../../../../config/intl/common-messages/common-tab-messages/CommonTabMessages'
import {
  useFilters,
  UseFiltersTestIdsType,
} from '../../../../../../layouts/filters/hook/useFilters'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { SmsTable } from '../../../../../../layouts/tables/sms-table/SmsTable'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import {
  SmsTypeTabs,
  useSmses,
} from '../../../../../../sdk/hooks/use-smses/useSmses'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import { LC } from '../../../../../../tests/e2e/locators'
import styles from './styles.module.scss'
import {getUrlParams} from "../../../../../../shared/lib/utils/get-url-params/get-url-params";
import {useWebSockets} from "../../../../../../shared/lib/hooks/useWebSockets";
import {WEB_SOCKETS_EVENTS_VALUES} from "../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES";
import ControlHeader from "../../../../../../shared/ui-kit-3/ControlHeader/ControlHeader";
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg';


interface SmsListPageProps {
  activeDevices: DongleListResponse['dongles']
  activeDevicesLoading: boolean
}

const SmsListPageMessages = defineMessages({
  title: {
    id: 'SmsListPageMessages.title',
    defaultMessage: 'SMS',
  },
  newSms: {
    id: 'SmsListPageMessages.newSms',
    defaultMessage: 'SMS',
  },
})

const useFiltersTestIds: UseFiltersTestIdsType = {
  dateFromTestId: LC.SMS.FILTER.DATEPICKER_FROM,
  dateToTestId: LC.SMS.FILTER.DATEPICKER_TO,
  devicesMultiSelectTestId: LC.SMS.FILTER.DEVICES_MULTISELECT,
  clearAllButtonTestId: LC.SMS.FILTER.CLEAR_ALL_BTN,
  submitButtonTestId: LC.SMS.FILTER.SUBMIT_BTN,
}

export const SmsListPage: FC<SmsListPageProps> = ({
  activeDevices,
  activeDevicesLoading,
}) => {
  const intl = useIntl()
  const urlParams = getUrlParams()

  const [smsTypeTab, setSmsTypeTab] = useState<string>(SmsTypeTabs.ALL)
  const [settings, setSettings] = useState<Record<string, string | any>>({
    page: +urlParams?.page || 1,
    sortBy: urlParams?.sortBy || 'dongle_id',
    orderBy: urlParams?.orderBy || 'desc',
    limit: +urlParams?.limit || 50,
  })

  const handleChangeSettings = (fields: Object) => setSettings(prev => ({
    ...prev,
    ...fields
  }))

  const { filters } = useFilters({
    filterFields: ['dates', 'devices', 'phone-numbers', 'sms-text'],
    devices: activeDevices,
    testIds: useFiltersTestIds,
    typeTab: smsTypeTab,
    setTypeTab: setSmsTypeTab,
    handleChangeSettings
  })

  const {
    smses,
    totalCount: smsesTotalCount,
    handleFetch: handleFetchSmses,
    handleDeleteSms,
    loading,
    initialFetchCompleted,
    setInitialFetchCompleted,
  } = useSmses(
    {
      page: settings.page,
      limit: settings.limit,
      sortBy: settings.sortBy,
      orderBy: settings.orderBy,
      smsTypeTab,
      filters,
    },
  )

  const { webSocketsEventData } = useWebSockets({
    events: [
      WEB_SOCKETS_EVENTS_VALUES.sms_status_changed,
      WEB_SOCKETS_EVENTS_VALUES.sms_incoming_created,
      WEB_SOCKETS_EVENTS_VALUES.sms_outgoing_delayed_created,
    ]
  });

  const { handleOpenModal } = useModal()

   /*
  useEffect(() => {
    changeUrlParams(Object.entries(settings).reduce((prev, [key, value]) => value
      ? ({ ...prev, [key]: value })
      : prev
    , {}))
  }, [settings]);
  */

  const openSendSmsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.SEND_SMS,
      props: { handleFetch: handleFetchSmses },
    })
  }

  const sliceSmses = useMemo(() => smses.slice(0, 3000), [smses]);

  const smsTypeSegmentedGroup = useMemo(
    () => [
      {
        value: SmsTypeTabs.ALL,
        comparedValue: smsTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterAll),
        onClick: () => setSmsTypeTab(SmsTypeTabs.ALL),
        testId: LC.SMS.TYPE.ALL,
      },
      {
        value: SmsTypeTabs.INCOMING,
        comparedValue: smsTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterIncoming),
        onClick: () => setSmsTypeTab(SmsTypeTabs.INCOMING),
        testId: LC.SMS.TYPE.INCOMING,
      },
      {
        value: SmsTypeTabs.OUTGOING,
        comparedValue: smsTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterOutgoing),
        onClick: () => setSmsTypeTab(SmsTypeTabs.OUTGOING),
        testId: LC.SMS.TYPE.OUTGOING,
      },
      {
        value: SmsTypeTabs.DELAYED,
        comparedValue: smsTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterDelayed),
        onClick: () => setSmsTypeTab(SmsTypeTabs.DELAYED),
        testId: LC.SMS.TYPE.DELAYED,
      },
    ],
    [smsTypeTab],
  )

  useEffect(() => {
    handleChangeSettings({ page: 1 })
  }, [smsTypeTab, filters])

  useEffect(() => {
    if (settings.page === 1 && !loading) {
      handleFetchSmses({ hidden: true });
    }
  }, [webSocketsEventData]);

  return (
    <Card additionalClassNames={[styles.Card]}>
      <ControlHeader
        title={'SMS'}
        counter={{
          min: smses.length,
          max: smsesTotalCount
        }}
        actions={[{
          variant: 'greenFilled',
          size: 'md',
          text: intl.formatMessage(SmsListPageMessages.newSms),
          prefix: <PlusIcon />,
          onClick: openSendSmsModal,
        }]}
        loading={loading}
      />

      <SmsTable
        smses={sliceSmses}
        totalCount={smsesTotalCount}
        loading={activeDevicesLoading || loading}
        handleFetch={handleFetchSmses}
        handleDeleteSms={handleDeleteSms}
        activeDevicesTotalCount={activeDevices.length}
        settings={settings}
        handleChangeSettings={handleChangeSettings}
        initialFetchCompleted={initialFetchCompleted}
        setInitialFetchCompleted={setInitialFetchCompleted}
      />
    </Card>
  )
}
