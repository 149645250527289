
import React, {ReactNode} from "react";
import {useNavigate} from "react-router";
import {SeeAllButton} from "../SeeAllButton";
import {Sticker} from "../../../../../../shared/ui-kit-3/Sticker";
import {Card} from "../Card";
import styles from "../../styles.module.scss";


type SmallCardProps = {
  title: string;
  countOnline: number;
  countOffline: number;
  countDevices: number;
  seeAllButtonRoute: string;
  bgIcon: ReactNode;
}


const SmallCard = ({ title, countOnline, countOffline, countDevices, seeAllButtonRoute, bgIcon }: SmallCardProps) => {
  const navigate = useNavigate()

  return (
    <Card>
      <>
        <div className={styles.Card__Top}>
          <div className={styles.Card__Title}>
            {title}
          </div>
          <SeeAllButton onClick={() => navigate(seeAllButtonRoute)}/>
        </div>
        <div className={styles.Card__Stickers}>
          {countDevices === 0 ? (
            <Sticker
              variant={'grey'}
              text={`No one modem`}
            />
          ) : (
            <>
              <Sticker
                variant={'green'}
                text={`${countOnline} online`}
              />
              <Sticker
                variant={'red'}
                text={`${countOffline} offline`}
              />
            </>
          )}
        </div>
        <div className={styles.Card__Background}>
          {bgIcon}
        </div>
      </>
    </Card>
  )
};

export default SmallCard;