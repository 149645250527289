export enum WEB_SOCKETS_EVENTS_VALUES {
  'dongle_subscription_status_changed' = 'dongle_subscription_status_changed',
  'dongle_network_status_changed' = 'dongle_network_status_changed',
  'sms_status_changed' = 'sms_status_changed',
  'sms_incoming_created' = 'sms_incoming_created',
  'sms_outgoing_delayed_created' = 'sms_outgoing_delayed_created',
  'call_created' = 'call_created',
  'mailing_status_changed' = 'mailing_status_changed',
  'mailing_created' = 'mailing_created',
}
