import React from "react";
import { Range, getTrackBackground } from "react-range";
import styles from "./styles.module.scss";

type RangeSelectorProps = {
  min: number;
  max: number;
  step?: number;
  onChange: (range: { min: number; max: number }) => void;
};

export const RangeSelector: React.FC<RangeSelectorProps> = ({
  min,
  max,
  step = 1,
  onChange,
}) => {
  const [values, setValues] = React.useState([min, max]);
  const [tempValues, setTempValues] = React.useState([String(min), String(max)]);

  const handleInputBlur = (type: "min" | "max") => {
    const value = Number(tempValues[type === "min" ? 0 : 1]);

    if (type === "min") {
      const newMin = Math.min(Math.max(value || min, min), values[1] - step);
      setValues([newMin, values[1]]);
      setTempValues([String(newMin), tempValues[1]]);
      onChange({ min: newMin, max: values[1] });
    } else {
      const newMax = Math.max(Math.min(value || max, max), values[0] + step);
      setValues([values[0], newMax]);
      setTempValues([tempValues[0], String(newMax)]);
      onChange({ min: values[0], max: newMax });
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "min" | "max"
  ) => {
    const value = e.target.value;
    if (type === "min") {
      setTempValues([value, tempValues[1]]);
    } else {
      setTempValues([tempValues[0], value]);
    }
  };

  const handleSliderChange = (newValues: number[]) => {
    setValues(newValues);
    setTempValues([String(newValues[0]), String(newValues[1])]);
    onChange({ min: newValues[0], max: newValues[1] });
  };

  return (
    <div className={styles.RangeSelector}>
      <input
        type="number"
        value={tempValues[0]}
        placeholder={String(min)}
        className={styles.RangeSelector__Input}
        onChange={(e) => handleInputChange(e, "min")}
        onBlur={() => handleInputBlur("min")}
      />
      <div className={styles.RangeSelector__SliderWrapper}>
        <Range
          values={values}
          step={step}
          min={min}
          max={max}
          onChange={handleSliderChange}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className={styles.RangeSelector__Track}
              style={{
                ...props.style,
                background: getTrackBackground({
                  values,
                  colors: ["#e0e0e0", "#4caf50", "#e0e0e0"],
                  min,
                  max,
                }),
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className={styles.RangeSelector__Thumb}
              style={{
                ...props.style,
              }}
            />
          )}
        />
      </div>
      <input
        type="number"
        value={tempValues[1]}
        placeholder={String(max)}
        className={styles.RangeSelector__Input}
        onChange={(e) => handleInputChange(e, "max")}
        onBlur={() => handleInputBlur("max")}
      />
    </div>
  );
};
