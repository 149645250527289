import React, {Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Form, useField, useForm} from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { LoadingContent } from '../../../../../../layouts/loading-content/LoadingContent'
import { useCountrySelectOptions } from '../../../../../../sdk/hooks/use-countries/useCountrySelectOptions'
import { InputField } from '../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { SelectSearchField } from '../../../../../../shared/lib/form/form-field-adapters/v2/select-search-field/SelectSearchField'
import {
  composeValidators,
  useFormRules,
} from '../../../../../../shared/lib/form/form-rules'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { TextButton } from '../../../../../../shared/ui-kit-2/inputs/text-button/TextButton'
import {PURCHASE_STEPS, PurchaseProcessPageContext} from '../../context/PurchaseProcessPageContextProvider'
import {Checkbox} from "../../../../../../shared/ui-kit-2/inputs/checkbox/Checkbox";
import {ProfileFormMessages} from "../../../profile/components/profilePageEn/components/profile-page-form/ProfileForm";
import {compareAddresses} from "../../../../../../shared/lib/utils/compare-addresses";
import {USER_TYPE_IDS} from "../../../../../../sdk/datagates/types/user/_crud/get";
import styles from './styles.module.scss'
import {DeliveryForm} from "./components/delivery-form/DeliveryForm";
import {set} from "lodash";
import {CALCULATE_DELIVERY_ERRORS} from "../../constants/calculateDeliveryErrors";
import {ScreenDevices, useLessThanDeviceScreen} from "../../../../../../shared/lib/hooks/useLessThanDeviceScreen";
import {PurchaseStepsFormType} from "../purchase-steps/PurchaseSteps";

export type PurchaseStepShippingFormType = {
  countryId: string
  postalCode?: string
  addressLine1: string
  addressLine2?: string
  city?: string
  region?: string
}

export const PurchaseStepShippingMessages = defineMessages({
  countryLabel: {
    id: 'PurchaseStepShippingMessages.countryLabel',
    defaultMessage: 'Country',
  },
  countryPlaceholder: {
    id: 'PurchaseStepShippingMessages.countryPlaceholder',
    defaultMessage: 'Select Country',
  },
  postalCodeLabel: {
    id: 'PurchaseStepShippingMessages.postalCodeLabel',
    defaultMessage: 'Postal Code',
  },
  postalCodePlaceholder: {
    id: 'PurchaseStepShippingMessages.postalCodePlaceholder',
    defaultMessage: '4 or 6 digits',
  },
  streetAddress1Label: {
    id: 'PurchaseStepShippingMessages.streetAddress1Label',
    defaultMessage: 'Address Line 1',
  },
  streetAddress1Placeholder: {
    id: 'PurchaseStepShippingMessages.streetAddress1Placeholder',
    defaultMessage: 'Street, building, etc.',
  },
  streetAddress2Label: {
    id: 'PurchaseStepShippingMessages.streetAddress2Label',
    defaultMessage: 'Address Line 2',
  },
  streetAddress2Placeholder: {
    id: 'PurchaseStepShippingMessages.streetAddress2Placeholder',
    defaultMessage: 'Street, building, etc.',
  },
  cityLabel: {
    id: 'PurchaseStepShippingMessages.cityLabel',
    defaultMessage: 'City',
  },
  cityPlaceholder: {
    id: 'PurchaseStepShippingMessages.cityPlaceholder',
    defaultMessage: 'Your city',
  },
  regionLabel: {
    id: 'PurchaseStepShippingMessages.regionLabel',
    defaultMessage: 'Region',
  },
  regionPlaceholder: {
    id: 'PurchaseStepShippingMessages.regionPlaceholder',
    defaultMessage: 'Enter region',
  },
  back: {
    id: 'PurchaseStepShippingMessages.back',
    defaultMessage: 'Back',
  },
  saveToAccount: {
    id: 'PurchaseStepShippingMessages.saveToAccount',
    defaultMessage: 'Save data in your personal account',
  },
  submit: {
    id: 'PurchaseStepShippingMessages.submit',
    defaultMessage: 'Next Step',
  },
  fetchUserError: {
    id: 'PurchaseStepShippingMessages.fetchUserError',
    defaultMessage: 'Failed to fetch user profile',
  },
  calculateDelivery: {
    id: 'PurchaseStepShippingMessages.calculateDelivery',
    defaultMessage: 'Calculate delivery'
  },
  calculateDeliveryCountryError: {
    id: 'PurchaseStepShippingMessages.calculateDeliveryCountryError',
    defaultMessage: 'Check the correctness of selected country'
  },
  calculateDeliveryPostalCodeError: {
    id: 'PurchaseStepShippingMessages.calculateDeliveryPostalCodeError',
    defaultMessage: 'Check the correctness of entered postal code'
  },
  legalAddressTitle: {
    id: 'PurchaseStepShippingMessages.legalAddressTitle',
    defaultMessage: 'Billing address'
  },
  deliveryAddressTitle: {
    id: 'PurchaseStepShippingMessages.deliveryAddressTitle',
    defaultMessage: 'Shipping address'
  },
  validateDelivery400: {
    id: 'PurchaseStepShippingMessages.validateDelivery400',
    defaultMessage: 'Server error. Try again in a few minutes.'
  }
})

export const PurchaseStepShipping = () => {
  const {
    formData,
    setFormData,
    setCurrentStep,
    deliveryPriceWasFetched,
    setDeliveryPriceWasFetched,
    calculateDeliveryLoading,
    calculateDeliveryErrors,
    calculateDelivery
  } = useContext(PurchaseProcessPageContext)

  const isMobileXL = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK_XL)
  const intl = useIntl()
  const { ruleRequired } = useFormRules()
  const { countryOptions, loading: fetchCountriesLoading } =
    useCountrySelectOptions()

  const COUNTRIES_ISO_WHITE_LIST = [
    'ES', 'FR', 'DE', 'GB'
  ];
  const filteredCountryOptions = countryOptions.filter(country => COUNTRIES_ISO_WHITE_LIST.includes(country.iso));

  const isOrganization =
    formData?.accountType === USER_TYPE_IDS.ORGANIZATION.toString()

  const legalAddress = {
    countryId: formData?.legalCountryId ? formData.legalCountryId.toString() : undefined,
    postalCode: formData?.legalPostalCode,
    streetAddress1: formData?.legalStreetAddress1,
    streetAddress2: formData?.legalStreetAddress2,
    city: formData?.legalCity,
    region: formData?.legalRegion,
  };

  const deliveryAddress = {
    countryId: formData?.countryId?.toString(),
    postalCode: formData?.postalCode,
    streetAddress1: formData?.streetAddress1,
    streetAddress2: formData?.streetAddress2,
    city: formData?.city,
    region: formData?.region,
  };

  const [isLegalAddressSame, setIsLegalAddressSame] = useState<boolean>(compareAddresses(legalAddress, deliveryAddress));

  const calculateDeliveryHasErrors = !!(calculateDeliveryErrors && calculateDeliveryErrors.length > 0);

  useEffect(() => {
    if (isLegalAddressSame) {
      // @ts-ignore
      setFormData(prev => ({
        ...prev,
        ...legalAddress
      }))
    }
  }, [isLegalAddressSame]);

  const onBack = () => {
    setCurrentStep(PURCHASE_STEPS.CUSTOMER)
  }

  const onSubmit = (values: PurchaseStepShippingFormType) => {
    const editedFormData = {
      ...formData,
      ...values
    } as PurchaseStepsFormType;

    setFormData(editedFormData)

    calculateDelivery(editedFormData);
  }

  const onFieldChange = () => {
    if (deliveryPriceWasFetched) {
      setDeliveryPriceWasFetched(false);
    }
  }

  const ErrorDeliveryValidate400 = () => calculateDeliveryErrors?.includes(CALCULATE_DELIVERY_ERRORS.VALIDATOR_400) ? (
      <div className={styles.Error}>
        {intl.formatMessage(PurchaseStepShippingMessages.validateDelivery400)}
      </div>
  ) : <></>;

  const LegalAddress = () => {
    const countryDefaultInputValue = filteredCountryOptions.find(
      (c) => c.value === formData?.legalCountryId,
    )?.label

    return (
      <div className={styles.LegalAddress}>
        <div className={styles.CategoryTitle}>
          {intl.formatMessage(PurchaseStepShippingMessages.legalAddressTitle)}
        </div>
        <div className={styles.InputGrid}>
          <InputField
            name="legalStreetAddress1"
            validate={ruleRequired()}
            label={intl.formatMessage(
              PurchaseStepShippingMessages.streetAddress1Label,
            )}
            placeholder={intl.formatMessage(
              PurchaseStepShippingMessages.streetAddress1Placeholder,
            )}
            markAsRequired={true}
          />

          <InputField
            name="legalStreetAddress2"
            validate={ruleRequired()}
            label={intl.formatMessage(
              PurchaseStepShippingMessages.streetAddress2Label,
            )}
            placeholder={intl.formatMessage(
              PurchaseStepShippingMessages.streetAddress2Placeholder,
            )}
            markAsRequired={true}
          />

          <SelectSearchField
            name="legalCountryId"
            options={filteredCountryOptions}
            validate={ruleRequired()}
            label={intl.formatMessage(
              PurchaseStepShippingMessages.countryLabel,
            )}
            placeholder={intl.formatMessage(
              PurchaseStepShippingMessages.countryPlaceholder,
            )}
            markAsRequired={true}
            defaultInputValue={countryDefaultInputValue}
          />

          <InputField
            name="legalPostalCode"
            validate={composeValidators(
              ruleRequired(),
            )}
            label={intl.formatMessage(
              PurchaseStepShippingMessages.postalCodeLabel,
            )}
            placeholder={intl.formatMessage(
              PurchaseStepShippingMessages.postalCodePlaceholder,
            )}
            markAsRequired={true}
          />

          <InputField
            name="legalCity"
            validate={ruleRequired()}
            label={intl.formatMessage(
              PurchaseStepShippingMessages.cityLabel,
            )}
            placeholder={intl.formatMessage(
              PurchaseStepShippingMessages.cityPlaceholder,
            )}
            markAsRequired={true}
          />

          <InputField
            name="legalRegion"
            validate={ruleRequired()}
            label={intl.formatMessage(
              PurchaseStepShippingMessages.regionLabel,
            )}
            placeholder={intl.formatMessage(
              PurchaseStepShippingMessages.regionPlaceholder,
            )}
            markAsRequired={true}
          />
        </div>
      </div>
    );
  }

  const content = (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...(formData || {}),
      }}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className={styles.Container}>
            {isOrganization && <LegalAddress />}

            <DeliveryForm
              onFieldChange={onFieldChange}
              countryOptions={filteredCountryOptions}
              isLegalAddressSame={isLegalAddressSame}
              setIsLegalAddressSame={setIsLegalAddressSame}
            />

            {isMobileXL && <ErrorDeliveryValidate400 />}

            <div className={styles.Footer}>
              <div className={styles.BackAndSave}>
                <TextButton
                  type={'button'}
                  text={intl.formatMessage(PurchaseStepShippingMessages.back)}
                  icon={'caret-left'}
                  onClick={onBack}
                />
              </div>

              <div className={styles.Actions}>
                {!isMobileXL && <ErrorDeliveryValidate400 />}
                {!deliveryPriceWasFetched || calculateDeliveryHasErrors ? (
                  <Button
                    type={'submit'}
                    text={intl.formatMessage(PurchaseStepShippingMessages.calculateDelivery)}
                    loading={calculateDeliveryLoading}
                    disabled={calculateDeliveryLoading}
                  />
                ) : (
                  <Button
                    type={'button'}
                    text={intl.formatMessage(PurchaseStepShippingMessages.submit)}
                    onClick={() => setCurrentStep(PURCHASE_STEPS.PAYMENT)}
                    disabled={calculateDeliveryLoading}
                  />
                )}
              </div>
            </div>
          </form>
        )
      }}
    />
  )

  return (
    <LoadingContent
      loading={fetchCountriesLoading}
      content={content}
    />
  )
}
