import { QueryParameter } from "../../../../sdk/datagates/helpers/_common/wrap-api-request";

export const tableFiltersToUrlFilters = (tableFilters: Array<Record<string, any>>): QueryParameter[] => {
  if (!Array.isArray(tableFilters)) {
    return [];
  }

  return tableFilters.reduce((reducer: QueryParameter[], filter) => {
    if (filter.value === null || filter.value === undefined) {
      return reducer;
    }

    if (Array.isArray(filter.value)) {
      return [
        ...reducer,
        ...filter.value
          .filter((filterValue) => filterValue !== "All" && filterValue !== null)
          .map((filterValue) => ({
            name: filter.name,
            value: filterValue
          }))
      ];
    }

    if (filter.value === "All") {
      return reducer;
    }

    return [
      ...reducer,
      {
        name: filter.name,
        value: filter.value
      }
    ];
  }, []);
};
