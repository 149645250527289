import { COUNTRY_PHONES } from '../../../constants/COUNTRY_PHONES'
import { phonePostProcessing } from '../phone-post-processing'

export const getPhoneString = (
  countryCode?: string,
  nationalNumber?: string,
  shortNumber?: string,
) => {
  if (shortNumber) {
    return shortNumber
  } else if (
    countryCode &&
    nationalNumber &&
    COUNTRY_PHONES.hasOwnProperty(countryCode)
  ) {
    const countryCallingCode = COUNTRY_PHONES[countryCode].code
    return phonePostProcessing(countryCallingCode + nationalNumber)
  } else {
    throw new Error(
      'No phone was given (both short phone and international phone are undefined)',
    )
  }
}
