import React from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../app/hooks/useModal'
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right.svg'
import { ReactComponent as ReceivedIcon } from '../../../../../assets/icons/received-mini.svg'
import { ReactComponent as SentIcon } from '../../../../../assets/icons/sent-mini.svg'
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg'
import { ReactComponent as CallIcon } from '../../../../../assets/icons/call.svg'
import { ReactComponent as RepeatIcon } from '../../../../../assets/icons/repeat.svg'
import { CallListResponse } from '../../../../../sdk/datagates/types/call/_crud/list'
import { formatPhoneNumber } from '../../../../../sdk/formatters/format-phone-number'
import { useCallStatuses } from '../../../../../sdk/hooks/use-call-statuses/useCallStatuses'
import { CallType } from '../../../../../sdk/hooks/use-calls/useCalls'
import { formatDate } from '../../../../../shared/lib/utils/date-utils/formatDate'
import { RowGroupType } from '../../../../../shared/ui-kit-2/data-display/table/Table'
import { CallTypeBadge } from '../../../../custom-badges/call-type-badge/CallTypeBadge'
import { MODAL_TYPES } from '../../../../modals/ModalsProvider'
import styles from './styles.module.scss'
import {useDialog} from "../../../../../app/hooks/useDialog";
import {DialogVersion} from "../../../../../store/reducers/dialog/types";
import {isNumber} from "lodash";
import {DongleListResponse} from "../../../../../sdk/datagates/types/dongle/_crud/list";

type CallGroupsType = {
  [key: string]: CallListResponse['calls']
}

const CallTableRowsMessages = defineMessages({
  groupHeaderSent: {
    id: 'CallTableRows.groupHeaderSent',
    defaultMessage: 'Sent: {count}',
  },
  groupHeaderReceived: {
    id: 'CallTableRows.groupHeaderReceived',
    defaultMessage: 'Received: {count}',
  },
  deletingItemName: {
    id: 'CallTableRows.deletingItemName',
    defaultMessage: 'the call',
  },
})

export const CallTableRows = (
  calls: CallListResponse['calls'],
  handleDeleteCall: (dongleId: number, callId: number) => Promise<void>,
): RowGroupType[] => {
  const intl = useIntl()
  const { getCallStatusStickerComponent } = useCallStatuses()
  const { handleOpenDialog, handleHideDialog } = useDialog()

  const callGroups = calls.reduce<CallGroupsType>((acc, call) => {
    const date = formatDate(call.createdAt, 0, 'date')

    if (acc.hasOwnProperty(date))
      acc[date as keyof typeof acc].push(call)
    else
      acc[date as keyof typeof acc] = [call]

    return acc
  }, {});

  // TODO: add types
  const handleRepeatCall = (call: any) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Repeat call?',
        subtitle: 'Are you sure you want to repeat call?',
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog()
        },
        submit: {
          variant: 'green',
          text: 'Yes',
          onClick: () => {
            handleHideDialog();
          }
        },
      }
    })
  }

  // TODO: add types
  const handleCallBack = (call: any) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Call back',
        subtitle: 'Are you sure you want to call back?',
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog()
        },
        submit: {
          variant: 'green',
          text: 'Yes',
          onClick: () => {
            handleHideDialog();
          }
        },
      }
    })
  }

  // TODO: Need import global formatTime method
  const formatTime = (seconds: number) => {
    if (!isNumber(seconds)) {
      return '';
    }

    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${secs}`;
  };

  const handleDelete = (call: CallListResponse['calls'][0]) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Delete call?',
        subtitle: 'This action cannot be undone',
        subtitleIsAlert: true,
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog()
        },
        submit: {
          icon: 'trash',
          text: 'Delete',
          onClick: () => {
            handleHideDialog();
            handleDeleteCall(call.dongleId, call.callId);
          }
        },
      }
    })
  }

  return Object.entries(callGroups).map<RowGroupType>(([date, calls]) => {
    return {
      groupHeader: (
        <div className={styles.Header}>
          <div className={styles.Header__date}>{date}</div>
          <div className={styles.Header__sentAndReceived}>
            <div className={styles.Header__sentAndReceived__item}>
              <SentIcon />
              <div>
                {intl.formatMessage(CallTableRowsMessages.groupHeaderSent, {
                  count: calls.filter((c) => c.callTypeId === CallType.OUTGOING).length,
                })}
              </div>
            </div>

            <div className={styles.Header__sentAndReceived__item}>
              <ReceivedIcon />
              <div>
                {intl.formatMessage(CallTableRowsMessages.groupHeaderReceived, {
                  count: calls.filter((c) => c.callTypeId === CallType.INCOMING).length,
                })}
              </div>
            </div>
          </div>
        </div>
      ),
      rows: calls.map((call) => {
        const isDeviceSender = [CallType.OUTGOING].includes(
          call.callTypeId,
        )

        const contactSenderName = isDeviceSender
          ? call.dongleName
          : call?.contact?.name || ''

        const contactReceiverName = isDeviceSender
          ? call?.contact?.name || ''
          : call.dongleName

        return [
          <CallTypeBadge type={call.callTypeId} />,
          <div className={styles.Time}>
            {formatDate(call.createdAt, 0, 'time')}
          </div>,
          <div>{getCallStatusStickerComponent(call.callStatusId)}</div>,
          <div className={styles.Contact}>
            <div className={styles.Contact__container}>
              <div className={styles.Contact__name}>
                {contactSenderName}
              </div>
              <div className={styles.Contact__phone}>
                {formatPhoneNumber(call.numberCaller)}
              </div>
            </div>
            <ArrowRightIcon />
          </div>,
          <div className={styles.Contact}>
            <div className={styles.Contact__container}>
              <div
                className={clsx(
                  styles.Contact__name,
                  styles.Contact__name_device,
                )}
              >
                {contactReceiverName}
              </div>
              <div className={styles.Contact__phone}>
                {formatPhoneNumber(call.numberReceiver)}
              </div>
            </div>
          </div>,
          <div>
            {formatTime(call.duration)}
          </div>,
          <div className={styles.Actions}>
            <div className={styles.Action} tabIndex={0} onClick={() => handleDelete(call)}>
              <TrashIcon/>
            </div>
          </div>,
        ]
      }),
    }
  })
}
