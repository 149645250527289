import React from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../app/hooks/useModal'
import { ReactComponent as ArrowRightIcon } from '../../../../../assets/icons/arrow-right.svg'
import { ReactComponent as AutoReplyIndicatorIcon } from '../../../../../assets/icons/auto-reply-indicator.svg'
import { ReactComponent as CaretRightIcon } from '../../../../../assets/icons/caret-right.svg'
import { ReactComponent as ReceivedIcon } from '../../../../../assets/icons/received-mini.svg'
import { ReactComponent as RedirectionIndicatorIcon } from '../../../../../assets/icons/redirection-indicator.svg'
import { ReactComponent as ReplyIndicatorIcon } from '../../../../../assets/icons/reply-indicator.svg'
import { ReactComponent as SentIcon } from '../../../../../assets/icons/sent-mini.svg'
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg'
import { SmsStatus } from '../../../../../pages/lk/subpages/sms/components/SmsStatus/SmsStatus'
import { SmsListResponse } from '../../../../../sdk/datagates/types/sms/_crud/list'
import { formatPhoneNumber } from '../../../../../sdk/formatters/format-phone-number'
import { CallType } from '../../../../../sdk/hooks/use-calls/useCalls'
import {
  canSmsBeReplied,
  isDeviceSender,
  isSmsAutoReplied,
  isSmsManuallyReplied,
  isSmsRedirected,
  SmsType,
} from '../../../../../sdk/hooks/use-smses/useSmses'
import { formatDate } from '../../../../../shared/lib/utils/date-utils/formatDate'
import { getSmsCenter } from '../../../../../shared/lib/utils/get-sms-center/getSmsCenter'
import { RowGroupType } from '../../../../../shared/ui-kit-2/data-display/table/Table'
import { LC } from '../../../../../tests/e2e/locators'
import { SmsTypeBadge } from '../../../../custom-badges/sms-type-badge/CallTypeBadge'
import { MODAL_TYPES } from '../../../../modals/ModalsProvider'
import styles from './styles.module.scss'
import {SMS_STATUSES} from "../../../../../pages/lk/subpages/sms/components/SmsStatus/constants/SmsStatuses";
import {parseStringToDate} from "../../../../../shared/lib/utils/parseStringToDate/parseStringToDate";
import {ObjectEntries} from "../../../../../shared/lib/types/objectEntries";
import {SORTED_COLS} from "../../SmsTable";
import {QueryParameter} from "../../../../../sdk/datagates/helpers/_common/wrap-api-request";

type SmsGroupsType = {
  [key: string]: SmsListResponse['smses']
}

const SmsTableRowsMessages = defineMessages({
  groupHeaderSent: {
    id: 'SmsTableRowsMessages.groupHeaderSent',
    defaultMessage: 'Sent: {count}',
  },
  groupHeaderReceived: {
    id: 'SmsTableRowsMessages.groupHeaderReceived',
    defaultMessage: 'Received: {count}',
  },
  deletingItemName: {
    id: 'SmsTableRowsMessages.deletingItemName',
    defaultMessage: 'the sms',
  },
  reply: {
    id: 'SmsTableRowsMessages.reply',
    defaultMessage: 'Reply',
  },
})

const hasStatusIcons = (smsTypeId: number) =>
  smsTypeId === SmsType.INCOMING || smsTypeId === SmsType.SILENT

export const SmsTableRows = (
  smses: SmsListResponse['smses'],
  handleFetch: (props: { params?: QueryParameter[] | undefined; hidden?: boolean | undefined }) => Promise<void>,
  handleDeleteSms: (dongleId: number, smsId: number) => Promise<void>,
  settings: Record<string, any>
): RowGroupType[] => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const smsGroups = smses.reduce<SmsGroupsType>((acc, sms) => {
    const date = formatDate(sms.createdAt, 0, 'date')
    if (acc.hasOwnProperty(date)) acc[date as keyof typeof acc].push(sms)
    else acc[date as keyof typeof acc] = [sms]

    return acc
  }, {})

  const handleOpenSmsInfoModal = (
    sms: SmsListResponse['smses'][0],
    forceAction: 'reply' | 'delete' | null = null,
  ) => {
    handleOpenModal({
      type: MODAL_TYPES.SMS_VIEW,
      props: {
        sms,
        forceAction,
        onDeleteSms: () => handleDeleteSms(sms.dongleId, sms.smsId),
        handleFetch,
      },
    })
  }

  const sortSms = (smsArray: SmsListResponse['smses']) => {
    const sortedArray = [...smsArray];

    if (settings.sortBy === SORTED_COLS.status) {
      return sortedArray.sort((a, b) => {
        const dateA = a.createdAt.getTime();
        const dateB = b.createdAt.getTime();

        if (a.smsStatusId === SMS_STATUSES.DELIVERED && b.smsStatusId === SMS_STATUSES.DELIVERED) {
          return dateB - dateA;
        }

        if (a.smsStatusId === SMS_STATUSES.DELIVERED) {
          return settings.orderBy === 'asc' ? 1 : -1;
        }

        if (b.smsStatusId === SMS_STATUSES.DELIVERED) {
          return settings.orderBy === 'asc' ? -1 : 1;
        }

        return dateB - dateA;
      });
    }

    if (settings.sortBy === SORTED_COLS.time) {
      return sortedArray.sort((a, b) => {
        const dateA = a.createdAt.getTime();
        const dateB = b.createdAt.getTime();
        return settings.orderBy === 'desc'
          ? dateB - dateA
          : dateA - dateB;
      });
    }

    return sortedArray;
  }

  const sortEntriesByDate = (entries: ObjectEntries<SmsGroupsType>[]) => {
    const sortedArray = [...entries];

    sortedArray.sort((a, b) => {
      const dateA = parseStringToDate(a[0]).getTime();
      const dateB = parseStringToDate(b[0]).getTime();
      return dateB - dateA;
    });

    return sortedArray;
  }

  return sortEntriesByDate(Object.entries(smsGroups)).map<RowGroupType>(([date, smses], index) => {
    return {
      groupHeader: (
        <div className={styles.Header}>
          <div
            className={styles.Header__date}
            data-test-id={LC.SMS.TABLE.ROW.DATE(index)}
          >
            {date}
          </div>
          <div className={styles.Header__sentAndReceived}>
            <div className={styles.Header__sentAndReceived__item}>
              <SentIcon />
              <div>
                {intl.formatMessage(SmsTableRowsMessages.groupHeaderSent, {
                  count: smses.filter((s) =>
                    [
                      SmsType.OUTGOING,
                      SmsType.REPLY,
                      SmsType.AUTO_REPLY,
                      SmsType.MAILING,
                    ].includes(s.smsTypeId),
                  ).length,
                })}
              </div>
            </div>

            <div className={styles.Header__sentAndReceived__item}>
              <ReceivedIcon />
              <div>
                {intl.formatMessage(SmsTableRowsMessages.groupHeaderReceived, {
                  count: smses.filter(
                    (s) =>
                      s.smsTypeId === CallType.INCOMING ||
                      s.smsTypeId === SmsType.SILENT,
                  ).length,
                })}
              </div>
            </div>
          </div>
        </div>
      ),
      rows: sortSms(smses).map((sms) => {
        const sentFromDevice = isDeviceSender(sms.smsTypeId)

        const contactSenderName = sentFromDevice
          ? sms.dongleName
          : sms.contact
            ? sms.contact.name
            : ''
        const contactReceiverName = sentFromDevice
          ? sms.contact
            ? sms.contact.name
            : ''
          : sms.dongleName

        return [
          <SmsTypeBadge type={sms.smsTypeId} />,
          <div className={styles.Time}>
            {formatDate(sms.createdAt, 0, 'time')}
          </div>,
          <div>
            {SmsStatus({
              smsStatusId: sms.smsStatusId,
              testId: LC.SMS.TABLE.ROW.STATUS(index),
            })}
          </div>,
          <div className={styles.Contact}>
            <div className={styles.Contact__container}>
              <div
                className={clsx(
                  styles.Contact__name,
                  sentFromDevice && styles.Contact__name_device,
                )}
                data-test-id={LC.SMS.TABLE.ROW.DEVICE_SENDER(index)}
              >
                {contactSenderName}
              </div>
              <div
                className={styles.Contact__phone}
                data-test-id={LC.SMS.TABLE.ROW.SENDER(index)}
              >
                {formatPhoneNumber(sms.numberCaller)}
              </div>
            </div>
            <ArrowRightIcon />
          </div>,
          <div className={styles.Contact}>
            <div className={styles.Contact__container}>
              <div
                className={clsx(
                  styles.Contact__name,
                  !sentFromDevice && styles.Contact__name_device,
                )}
                data-test-id={LC.SMS.TABLE.ROW.DEVICE_RECEIVER(index)}
              >
                {contactReceiverName}
              </div>
              <div
                className={styles.Contact__phone}
                data-test-id={LC.SMS.TABLE.ROW.RECEIVER(index)}
              >
                {formatPhoneNumber(sms.numberReceiver)}
              </div>
            </div>
          </div>,
          <div className={styles.RightBordered}>
            {getSmsCenter(sms?.smsCenter)}
          </div>,
          <div
            className={styles.MessageText}
            data-test-id={LC.SMS.TABLE.ROW.TEXT(index)}
          >
            {sms.messageDecoded}
          </div>,
          <div className={styles.Actions}>
            {canSmsBeReplied(sms) && (
              <button
                type={'button'}
                className={styles.ReplyButton}
                onClick={() => handleOpenSmsInfoModal(sms, 'reply')}
              >
                {intl.formatMessage(SmsTableRowsMessages.reply)}
              </button>
            )}

            {hasStatusIcons(sms.smsTypeId) && (
              <div className={styles.StatusIcons}>
                <ReplyIndicatorIcon
                  className={clsx(
                    styles.StatusIcon,
                    isSmsManuallyReplied(sms) && styles.StatusIcon_active,
                  )}
                />
                <AutoReplyIndicatorIcon
                  className={clsx(
                    styles.StatusIcon,
                    isSmsAutoReplied(sms) && styles.StatusIcon_active,
                  )}
                />
                <RedirectionIndicatorIcon
                  className={clsx(
                    styles.StatusIcon,
                    isSmsRedirected(sms) && styles.StatusIcon_active,
                  )}
                />
              </div>
            )}

            <TrashIcon onClick={() => handleOpenSmsInfoModal(sms, 'delete')} />
            <CaretRightIcon onClick={() => handleOpenSmsInfoModal(sms)} />
          </div>,
        ]
      }),
    }
  })
}
