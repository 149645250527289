import React, {ComponentProps, FC, useEffect} from 'react'
import {Field, FieldRenderProps, useField} from 'react-final-form'
import {
  SelectSearch,
  SelectSearchProps,
} from '../../../../../ui-kit-2/inputs/select-search/SelectSearch'
import { getFieldError } from '../../../form-helpers/get-field-error/getFieldError'

type SelectSearchFieldProps = SelectSearchProps &
  ComponentProps<typeof Field<string>>

export const SelectSearchField: FC<SelectSearchFieldProps> = (props) => {
  const {
    options,
    label,
    defaultInputValue,
    handleAfterSelect,
    helperText,
    width,
    customError,
    validationNeeded = true,
    ...otherProps
  } = props

  return (
    <Field {...otherProps}>
      {(fieldRenderProps) => {
        const { input, meta, ...otherFieldRenderProps } =
          fieldRenderProps as FieldRenderProps<string>
        return (
          <SelectSearch
            options={options}
            label={label}
            error={getFieldError(meta) || customError}
            defaultInputValue={defaultInputValue}
            handleAfterSelect={handleAfterSelect}
            helperText={helperText}
            width={width}
            {...input}
            {...otherFieldRenderProps}
            onChange={(e) => {
              input.onChange(e)
              otherProps.onChange?.(e)
            }}
            noValidate={!validationNeeded}
          />
        )
      }}
    </Field>
  )
}
