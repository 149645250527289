import {PageWrapper} from "../../../../layouts/page-wrapper/PageWrapper";
import {Navigate, Routes} from "react-router-dom";
import {AccessRoute} from "../../../../routing/AccessRoute";
import {RoutePath} from "../../../../config/routes/constants/routePath";
import {Route} from "../../../../config/routes/enums/route";
import React, {useEffect, useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import { Route as ReactRoute, useLocation, useNavigate} from "react-router";
import {ScreenDevices, useLessThanDeviceScreen} from "../../../../shared/lib/hooks/useLessThanDeviceScreen";
import {CallsRootRoutePath, CallsRoutePath} from "../../../../config/routes/constants/callsRoutePath";
import {CallsRoute} from "../../../../config/routes/enums/callsRoute";
import {callsRoutes} from "../../../../config/routes/callsRoutes";
import {CloudTabs, CloudTabType} from "../../../../shared/ui-kit-2/navigation/cloud-tabs/CloudTabs";
import styles from "./styles.module.scss";

export const CALLS_PAGE_TABS_IDS = {
  CALLS_LIST: CallsRoutePath[CallsRoute.CallsList],
  CALLS_REDIRECTS: CallsRoutePath[CallsRoute.CallsRedirects],
  CALLS_SETTINGS: CallsRoutePath[CallsRoute.CallsSettings],
  CALLS_AUTO_RESPONSE: CallsRoutePath[CallsRoute.CallsAutoResponse],
  CALLS_AUTO_RESPONSE_RULES: CallsRoutePath[CallsRoute.CallsAutoResponseRules],
  CALLS_AUTO_DIALER: '/3',
}

const CallsPageMessages = defineMessages({
  callsList: {
    id: 'CallsPageMessages.callsList',
    defaultMessage: 'Calls',
  },
  callsNotifications: {
    id: 'CallsPageMessages.callsNotifications',
    defaultMessage: 'Calls notifications',
  },
  callsAutoResponse: {
    id: 'CallsPageMessages.callsAutoResponse',
    defaultMessage: 'Autoreply'
  },
  callsAutoResponseRules: {
    id: 'CallsPageMessages.callsAutoResponseRules',
    defaultMessage: 'Autoreply rules'
  },
  callsAutoDialer: {
    id: 'CallsPageMessages.callsAutoDialer',
    defaultMessage: 'Autodialer'
  },
  callsSettings: {
    id: 'CallsPageMessages.callsSettings',
    defaultMessage: 'Settings',
  },
})


export const CallsPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const defaultTabId = useMemo(() => pathname, [])

  const cloudTabOptions: CloudTabType[] = [
    {
      id: CALLS_PAGE_TABS_IDS.CALLS_LIST,
      label: intl.formatMessage(CallsPageMessages.callsList),
      onClick: () => navigate(CallsRoutePath[CallsRoute.CallsList]),
    },
    {
      id: CALLS_PAGE_TABS_IDS.CALLS_REDIRECTS,
      label: intl.formatMessage(CallsPageMessages.callsNotifications),
      onClick: () => navigate(CallsRoutePath[CallsRoute.CallsRedirects]),
    },
    {
      id: CALLS_PAGE_TABS_IDS.CALLS_AUTO_RESPONSE,
      label: intl.formatMessage(CallsPageMessages.callsAutoResponse),
      onClick: () => navigate(CallsRoutePath[CallsRoute.CallsAutoResponse]),
    },
    {
      id: CALLS_PAGE_TABS_IDS.CALLS_AUTO_RESPONSE_RULES,
      label: intl.formatMessage(CallsPageMessages.callsAutoResponseRules),
      onClick: () => navigate(CallsRoutePath[CallsRoute.CallsAutoResponseRules]),
    },
    {
      id: CALLS_PAGE_TABS_IDS.CALLS_AUTO_DIALER,
      label: intl.formatMessage(CallsPageMessages.callsAutoDialer),
      onClick: () => null,
      disabled: true,
    },
    {
      id: CALLS_PAGE_TABS_IDS.CALLS_SETTINGS,
      label: intl.formatMessage(CallsPageMessages.callsSettings),
      onClick: () => navigate(CallsRoutePath[CallsRoute.CallsSettings]),
    },
  ];

  useEffect(() => {
    if (pathname === RoutePath[Route.Calls]) {
      navigate(CallsRoutePath[CallsRoute.CallsList])
    }
  }, [pathname])

  const handleTabChange = (path: string) => {
    navigate(path)
  }

  return (
    <PageWrapper>
      <CloudTabs
        items={cloudTabOptions}
        mainRoot={CallsRoutePath[CallsRoute.CallsList]}
        defaultTabId={defaultTabId}
        handleChange={handleTabChange}
        classes={{
          nav: styles.CloudTabsAdditional
        }}
      />

      <Routes>
        {Object.values(callsRoutes).map((r) => (
          <ReactRoute
            key={r.route}
            path={r.path.split('/').pop()}
            element={
              <AccessRoute
                key={r.route}
                route={r}
              />
            }
          />
        ))}

        <ReactRoute
          path={'*'}
          element={<Navigate to={RoutePath[Route.NotFound]} replace={true} />}
        />
      </Routes>
    </PageWrapper>
  )
}