import { AuthRoutePath } from '../../../../../constants/authRoutePath'
import { RoutePath } from '../../../../../constants/routePath'
import { AuthRoute } from '../../../../../enums/authRoute'
import { Route } from '../../../../../enums/route'
import { RouteDescriptor } from '../../../../../types/routeDescriptor'
import {DashboardPage} from "../../../../../../../pages/lk/subpages/dashboard/DashboardPage";

export const dashboard: RouteDescriptor<Route.Dashboard> = {
  route: Route.Dashboard,
  type: 'subpage',
  title: 'Teleleo',
  path: RoutePath[Route.Dashboard],
  render: DashboardPage,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
