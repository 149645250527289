import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../app/hooks/useModal'
import { ReactComponent as ReceivedIcon } from '../../../../../assets/icons/received-mini.svg'
import { ReactComponent as SentIcon } from '../../../../../assets/icons/sent-mini.svg'
import {DongleFlags, DongleListResponse} from '../../../../../sdk/datagates/types/dongle/_crud/list'
import { formatPhoneNumber } from '../../../../../sdk/formatters/format-phone-number'
import {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_VIEWS,
  IconButton,
} from '../../../../../shared/ui-kit/inputs/icon-button/IconButton'
import { LC } from '../../../../../tests/e2e/locators'
import { MODAL_TYPES } from '../../../../modals/ModalsProvider'
import styles from './styles.module.scss'
import {StatusNetwork} from "../../../../../shared/ui-kit-2/data-display/status-network/StatusNetwork";
import {StatusSubscription} from "../../../../../shared/ui-kit-2/data-display/status-subscription/StatusSubscription";
import {ReactComponent as TriangleAlertIcon} from "../../../../../assets/icons/triangle-alert.svg";
import { Loader } from '../../../../../shared/ui-kit-2/components/loader/Loader'
import clsx from "clsx";
import {useSiteVersion} from "../../../../../app/hooks/useSiteVersion";
import {QueryParameter} from "../../../../../sdk/datagates/helpers/_common/wrap-api-request";

;

const DeviceTableRowsMessages = defineMessages({
  received: {
    id: 'DeviceTableRows.received',
    defaultMessage: 'Received: {count}',
  },
  sent: {
    id: 'DeviceTableRows.sent',
    defaultMessage: 'Sent: {count}',
  },
  online: {
    id: 'DeviceTableRows.online',
    defaultMessage: 'Online',
  },
  offline: {
    id: 'DeviceTableRows.offline',
    defaultMessage: 'Offline',
  },
  active: {
    id: 'DeviceTableRows.active',
    defaultMessage: 'active',
  },
  inactive: {
    id: 'DeviceTableRows.inactive',
    defaultMessage: 'inactive',
  },
})

export const DeviceTableRows = (
  devices: DongleListResponse['dongles'],
  handleFetchDevices: (props: { params?: QueryParameter[] | undefined; hidden?: boolean | undefined }) => Promise<void>,
  handleDeleteDevice: (dongleId: number) => Promise<void>,
  handleSwitchHotspot: (dongleId: number, enabled: boolean) => Promise<void>,
) => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()
  const { siteVersion } = useSiteVersion()
  const isRuSiteVersion = siteVersion === 'ru';

  if (devices.length === 0) return []

  return [
    {
      groupHeader: <></>,
      rows: devices.map((device, idx) => {
        const handleOpenDeviceInfo = () => {
          handleOpenModal({
            type: MODAL_TYPES.DEVICE_DETAILS,
            props: {
              deviceId: device.dongleId,
              handleFetchDevices,
              handleDeleteDevice,
              handleSwitchHotspot,
            },
          })
        }

        const handleChangePhoneNumber = () => {
          handleOpenModal({
            type: MODAL_TYPES.DEVICE_CHANGE_PHONE_NUMBER,
            props: {
              deviceId: device.dongleId,
              handleFetchDevices,
            },
          })
        }

        const dongleFlags = device?.dongleFlags?.map(({ dongleFlagId }) => dongleFlagId) || [];
        const needNewPhoneNumber = !isRuSiteVersion && dongleFlags.includes(DongleFlags.PHONE_DETERMINATION_ERROR);

        return [
          <div
            className={styles.Content_name}
            data-test-id={LC.DEVICES.TABLE.ROW.ID(idx)}
          >
            {device.dongleId}
          </div>,
          <div
            className={styles.Content_name}
            data-test-id={LC.DEVICES.TABLE.ROW.DEVICE(idx)}
          >
            {device.name}
          </div>,
          <div
            className={clsx(styles.Content, needNewPhoneNumber && styles.Content_needNewPhoneNumber)}
            data-test-id={LC.DEVICES.TABLE.ROW.PHONE_NUMBER(idx)}
            onClick={needNewPhoneNumber
              ? handleChangePhoneNumber
              : () => null}
          >
            {needNewPhoneNumber
              ? (
                <div className={styles.Error}>
                  <TriangleAlertIcon />
                </div>
              )
              : (!isRuSiteVersion && dongleFlags.includes(DongleFlags.MANUAL_PHONE_DETERMINING))
                ? (
                  <div className={styles.Error}>
                    <Loader />
                  </div>
                )
                : <></>
            }
            {formatPhoneNumber(device.phoneNumber)}
          </div>,
          <div
            className={styles.Sms}
            data-test-id={LC.DEVICES.TABLE.ROW.SMS(idx)}
          >
            <div className={styles.Row}>
              <div className={styles.Icon}>
                <SentIcon />
              </div>
              {intl.formatMessage(DeviceTableRowsMessages.sent, {
                count: device.smsOutgoing,
              })}
            </div>

            <div className={styles.Row}>
              <div className={styles.Icon}>
                <ReceivedIcon />
              </div>
              {intl.formatMessage(DeviceTableRowsMessages.received, {
                count: device.smsIncoming,
              })}
            </div>
          </div>,
          <div
            className={styles.Calls}
            data-test-id={LC.DEVICES.TABLE.ROW.CALLS(idx)}
          >
            <div className={styles.Row}>
              <div className={styles.Icon}>
                <SentIcon />
              </div>
              {intl.formatMessage(DeviceTableRowsMessages.sent, {
                count: device.callOutgoing,
              })}
            </div>

            <div className={styles.Row}>
              <div className={styles.Icon}>
                <ReceivedIcon />
              </div>
              {intl.formatMessage(DeviceTableRowsMessages.received, {
                count: device.callIncoming,
              })}
            </div>
          </div>,
          <div
            className={styles.Switcher}
            data-test-id={LC.DEVICES.TABLE.ROW.IS_ONLINE(idx)}
          >
            <StatusSubscription isTariffPackageActive={device.isTariffPackageActive}/>
          </div>,
          <div>
            <StatusNetwork
              isOnline={device.isOnline}
              dongleSignalQualityId={device.dongleSignalQualityId}
            />
          </div>,
          <div
            className={styles.Actions}
            data-test-id={LC.DEVICES.TABLE.ROW.ACTIONS._(idx)}
          >
            <IconButton
              icon={ICON_BUTTON_ICONS.caretRight}
              theme={ICON_BUTTON_VIEWS.text}
              onClick={handleOpenDeviceInfo}
              data-test-id={LC.DEVICES.TABLE.ROW.ACTIONS.VIEW_DEVICE_BTN(idx)}
            />
          </div>,
        ]
      }),
    },
  ]
}
