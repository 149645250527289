import React, {Dispatch, FC, SetStateAction, useContext, useEffect} from 'react'
import { useIntl } from 'react-intl'
import { useTableFooterInfo } from '../_helpers/hooks/use-table-footer-info/useTableFooterInfo'
import { CommonTableMessages } from '../../../config/intl/common-messages/common-table-messages/CommonTableMessages'
import { ContactsPageContext } from '../../../pages/lk/subpages/contacts/contexts/ContactsPageContext'
import { ContactListResponse } from '../../../sdk/datagates/types/contact/_crud/list'
import {
  Table,
  TableColType,
} from '../../../shared/ui-kit-2/data-display/table/Table'
import { LC } from '../../../tests/e2e/locators'
import { ContactTableRows } from './components/contact-table-rows/ContactTableRows'
import {DEFAULT_COLS, DEFAULT_COLS_VARIANTS} from "../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import {TABLE_FILTERS_VARIANTS} from "../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";
import {HeaderCol, useTableFilters} from "../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {
  tableFiltersToUrlFilters
} from "../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";
import {QueryParameter} from "../../../sdk/datagates/helpers/_common/wrap-api-request";
import _ from "lodash";

interface ContactTableProps {
  contacts: ContactListResponse['contacts']
  totalCount: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageLimit: number
  loading: boolean
  handleFetchContacts: (props?: { params?: QueryParameter[] | undefined; hidden?: boolean | undefined }) => Promise<void>
  handleFetchContactGroups: () => Promise<void>
  handleDeleteContact: (contactId: number) => Promise<void>
}

export const ContactTable: FC<ContactTableProps> = (props) => {
  const {
    contacts,
    page,
    setPage,
    pageLimit,
    totalCount,
    loading,
    handleFetchContacts,
    handleFetchContactGroups,
    handleDeleteContact,
  } = props

  const {
    handleSortedOrderTypeAsc,
    isSortedOrderTypeAsc,
    initialFetchCompleted,
    setInitialFetchCompleted
  } = useContext(ContactsPageContext)

  const intl = useIntl()
  const { footerInfo } = useTableFooterInfo(page, pageLimit, totalCount)

  const cols: TableColType[] = [
    {
      title: intl.formatMessage(CommonTableMessages.columnName),
      handleSorted: handleSortedOrderTypeAsc,
      isSorted: isSortedOrderTypeAsc,
      isActive: true,
      isAZZA: true
    },
    { title: intl.formatMessage(CommonTableMessages.columnPhone) },
    { title: intl.formatMessage(CommonTableMessages.columnGroups) },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ]

  const rowGroups = ContactTableRows(
    contacts,
    handleFetchContacts,
    handleFetchContactGroups,
    handleDeleteContact,
  )

  const COLS = {
    name: 'name',
    groups: 'groups'
  }

  const headerCols = [
    {
      id: COLS.name,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Name',
      defaultText: 'Enter the name',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.phone],
    {
      id: COLS.groups,
      label: 'Groups',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const { currentFilters, handleChangeFilters } = useTableFilters(headerCols);

  useEffect(() => {
    const debouncedFetch = _.debounce(() => {
      handleFetchContacts({
        params: tableFiltersToUrlFilters([
          { name: 'name', value: currentFilters?.name },
          { name: 'phone', value: currentFilters?.phone && encodeURIComponent(currentFilters?.phone) },
        ])
      })
    }, 300);

    if (initialFetchCompleted) {
      debouncedFetch();
    } else {
      setInitialFetchCompleted(true);
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [currentFilters]);

  return (
    <Table
      headerCols={headerCols}
      currentFilters={currentFilters}
      handleChangeFilters={handleChangeFilters}
      isNew

      name={'ContactTable'}
      cols={cols}
      rowGroups={rowGroups}
      currentPage={page}
      totalCount={totalCount}
      onPageChange={setPage}
      itemsPerPage={pageLimit}
      loading={loading}
      footerInfo={footerInfo}
      columnWidths={[1, 1, 1, 1]}
      testId={LC.CALLS.TABLE._}
    />
  )
}
