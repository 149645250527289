import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModalType } from './types'

const modalInitialState: ModalType = {
  type: null,
  props: {},
}

const modalSlice = createSlice({
  name: 'modal',
  initialState: modalInitialState,
  reducers: {
    setModal(state, { payload }: PayloadAction<ModalType>) {
      state.type = payload.type
      state.props = payload?.props || {}
    },
    updateModal(state, { payload }: PayloadAction<ModalType>) {
      state.props = payload?.props || {}
    },
    clearModal(state, { payload }: PayloadAction<{}>) {
      state.type = null
      state.props = {}
    },
  },
})

export const { setModal, clearModal, updateModal } = modalSlice.actions
export default modalSlice.reducer
