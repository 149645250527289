import {PageWrapper} from "../../../../layouts/page-wrapper/PageWrapper";
import React, {useEffect, useState} from "react";
import {LoadingContent} from "../../../../layouts/loading-content/LoadingContent";
import {useVPN} from "../../../../sdk/hooks/use-vpn/useVPN";
import {ActionCard} from "../../../../shared/ui-kit-2/data-display/action-card/ActionCard";
import {MainMessages} from "../../../../shared/intl-messages/MainMessages";
import {
  DeviceDetailsModalMessages
} from "../../../../layouts/modals/DeviceDetailsModal/messages/DeviceDetailsModalMessages";
import {useIntl} from "react-intl";
import styles from './styles.module.scss';
import {dongleUpdateDatagate} from "../../../../sdk/datagates/api/dongle";
import {SNACKBAR_TYPES} from "../../../../layouts/snackbar/SnackbarProvider";
import {useSnackbar} from "../../../../app/hooks/useSnackbar";
import {Counter} from "../../../../shared/ui-kit-2/data-display/counter/Counter";
import {SearchInputMobile} from "../../../../shared/ui-kit-2/inputs/searchInputMobile/SearchInputMobile";
import {Input} from "../../../../shared/ui-kit-2/inputs/input/Input";
import {ScreenDevices, useLessThanDeviceScreen} from "../../../../shared/lib/hooks/useLessThanDeviceScreen";
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import {DongleListResponse} from "../../../../sdk/datagates/types/dongle/_crud/list";


export const VPNPage = () => {
  const intl = useIntl();
  const { handleOpenSnackbar } = useSnackbar();
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK);

  const { VPNs, VPNsLoading, handleUpdateVPN } = useVPN();
  const [loadings, setLoadings] = useState<Record<string, boolean>>({});
  const [inputValue, setInputValue] = useState<string>('');

  const [filteredVPNs, setFilteredVPNs] = useState(VPNs);

  // TODO: вынести отдельно и убрать копии
  const handleChangeLoadings = ({ dongleId, status }: { dongleId: number, status: boolean }) => {
    setLoadings(prev => {
      const updatedLoadings = { ...prev };
      if (status) {
        updatedLoadings[dongleId] = true;
      } else {
        delete updatedLoadings[dongleId];
      }
      return updatedLoadings;
    });
  };

  const handleSwitchVPN = async (dongleId: number, isVPNConnected: boolean) => {
    try {
      handleChangeLoadings({ dongleId, status: true })
      await handleUpdateVPN(dongleId, {
        is_public_vpn_enabled: isVPNConnected
      })
    } catch {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: 'An error occurred when changing status of vpn.',
      })
    } finally {
      setTimeout(() => handleChangeLoadings({ dongleId, status: false }), 100);
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  useEffect(() => {
    const filterValue = inputValue ?? '';

    const result = VPNs.filter((vpn) => {
      const nameMatch = vpn.name
        ?.toLowerCase()
        .includes(filterValue.toLowerCase())
      const phoneMatch = vpn.phoneNumber?.includes(filterValue)
      return nameMatch || phoneMatch
    })

    setFilteredVPNs(result);
  }, [inputValue, VPNs])

  const content = (
    <div>
      <div className={styles.PageHeader}>
        <div className={styles.TitleAndCounter}>
          <div className={styles.Title}>
            VPN
          </div>
          <Counter
            customText={`${VPNs.reduce((sum, {isVPNConnected}) => isVPNConnected ? sum + 1 : sum, 0)} ${intl.formatMessage(MainMessages.of)} ${VPNs.length}`}
            variant='filled'
          />
        </div>
        <div className={styles.SearchInputContainer}>
          {isMobile ? (
            <SearchInputMobile
              value={inputValue}
              defaultValue={inputValue}
              placeholder={'Search'}
              onChange={handleInputChange}
              theme="outlined"
            />
          ) : (
            <Input
              validationNeeded={false}
              placeholder={'Search'}
              onChange={handleInputChange}
              postfix={<SearchIcon/>}
            />
          )}
        </div>
      </div>
      <div className={styles.Cards}>
        {filteredVPNs
          .sort((a, b) => +b.isOnline - +a.isOnline)
          .map(({
            dongleId,
            name,
            phoneNumber,
            publicVPNEndpoint,
            publicVPNLogin,
            publicVPNPassword,
            isVPNConnected,
            isOnline
          }) => (
            <ActionCard
              id={dongleId}
              name={name}
              number={phoneNumber}
              loading={loadings[dongleId]}
              switcher={{
                currentValue: isVPNConnected,
                leftButton: {
                  text: intl.formatMessage(MainMessages.On),
                  onClick: id => handleSwitchVPN(id, true),
                },
                rightButton: {
                  text: intl.formatMessage(MainMessages.Off),
                  onClick: id => handleSwitchVPN(id, false),
                },
              }}
              copyBlock={{
                show: true,
                items: [
                  {
                    name: 'User name',
                    value: publicVPNLogin,
                    possiblyToCopy: true
                  },
                  {
                    name: intl.formatMessage(MainMessages.Password),
                    value: publicVPNPassword,
                    possiblyToCopy: true
                  },
                  {
                    name: 'IP-address',
                    value: 'gw.teleleo.com',
                    // value: publicVPNEndpoint,
                    possiblyToCopy: true
                  },
                ]
              }}
              alert={{
                show: !isOnline,
                text: 'Please connect the modem to the network'
              }}
            />
          ))}
      </div>
    </div>
  );

  return (
    <PageWrapper loading={VPNsLoading}>
      <LoadingContent loading={VPNsLoading} content={content} errorText={''}/>
    </PageWrapper>
  )
}