import React, { useCallback, useContext, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../app/hooks/useModal'
import { MODAL_TYPES } from '../../../../../../../../layouts/modals/ModalsProvider'
import { ContactTable } from '../../../../../../../../layouts/tables/contact-table/ContactTable'
import { useDebounce } from '../../../../../../../../sdk/datagates/helpers/_common/debounce'
import { Card } from '../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { ContactsPageContext } from '../../../../contexts/ContactsPageContext'
import styles from './styles.module.scss'
import ControlHeader from "../../../../../../../../shared/ui-kit-3/ControlHeader/ControlHeader";
import { ReactComponent as UserPlusIcon } from '../../../../../../../../assets/icons/user-plus.svg';


const ContactListMessages = defineMessages({
  title: {
    id: 'ContactListMessages.title',
    defaultMessage: 'Contacts',
  },
  searchPlaceholder: {
    id: 'ContactListMessages.searchPlaceholder',
    defaultMessage: 'Search',
  },
  addContact: {
    id: 'ContactListMessages.addContact',
    defaultMessage: 'Add contact',
  },
})

export const ContactList = () => {
  const intl = useIntl()

  const {
    setContactsSearchQuery,
    contactsPage,
    setContactsPage,
    contacts,
    contactsTotalCount,
    contactsLoading,
    handleFetchContacts,
    handleDeleteContact,
    handleFetchContactGroups,
    limitContactsPage,
  } = useContext(ContactsPageContext)

  const { handleOpenModal } = useModal()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchLoading, setSearchLoading] = useState<boolean>(false)
  const sliceContacts = contacts.slice(0, 3000)

  const debouncedSearch = useCallback(
    useDebounce((value: string) => {
      setContactsSearchQuery(value)
      setSearchLoading(false)
    }, 500),
    [],
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchLoading(true)
    setSearchQuery(event.target.value)
    debouncedSearch(event.target.value)
  }

  const openAddContactModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.CONTACT_SETTINGS_MODAL,
      props: {
        handleFetchContacts,
        handleFetchContactGroups,
      },
    })
  }

  const loading = contactsLoading || searchLoading;

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <ControlHeader
        title={'Contacts'}
        counter={{
          min: contacts.length,
          max: contactsTotalCount
        }}
        actions={[
          {
            variant: 'greenFilled',
            size: 'md',
            text: intl.formatMessage(ContactListMessages.addContact),
            prefix: <UserPlusIcon />,
            onClick: openAddContactModal,
          },
        ]}
        loading={loading}
      />

      <ContactTable
        contacts={sliceContacts}
        totalCount={contactsTotalCount}
        page={contactsPage}
        setPage={setContactsPage}
        pageLimit={limitContactsPage}
        loading={loading}
        handleFetchContacts={handleFetchContacts}
        handleFetchContactGroups={handleFetchContactGroups}
        handleDeleteContact={handleDeleteContact}
      />
    </Card>
  )
}
