
import React, {useState} from "react";
import styles from './styles.module.scss';
import {MODAL_TYPES} from "../../../../../../layouts/modals/ModalsProvider";
import {REDIRECTION_TYPES} from "../../../../../../sdk/hooks/use-redirection-types/constants/RedirectionTypes";
import {useModal} from "../../../../../../app/hooks/useModal";
import {useRedirections} from "../../../../../../sdk/hooks/use-redirections/useRedirections";
import {useDevices} from "../../../../../../sdk/hooks/use-devices/useDevices";
import {Card} from "../../../../../../shared/ui-kit-2/data-display/card/Card";
import {CallNotificationsTable} from "./components/CallNotificationsTable/CallNotificationsTable";
import {RedirectionListResponse} from "../../../../../../sdk/datagates/types/redirection/_crud/list";
import {useDialog} from "../../../../../../app/hooks/useDialog";
import {DialogVersion} from "../../../../../../store/reducers/dialog/types";
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg';
import ControlHeader from "../../../../../../shared/ui-kit-3/ControlHeader/ControlHeader";


// TODO: Add intl
export const CallNotificationsPage = () => {
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(50)

  const { handleOpenModal } = useModal();
  const { handleOpenDialog, handleHideDialog } = useDialog();

  const { devices } = useDevices({
    page: 0,
    limit: 0,
    takeAll: true,
    isActive: true,
  })

  const {
    redirections,
    totalCount,
    handleFetch,
    handleDeleteRedirection: handleDelete,
    loading,
    initialFetchCompleted,
    setInitialFetchCompleted,
  } = useRedirections({
    page: 0,
    limit: 0,
    redirectionTypes: [REDIRECTION_TYPES.CALL],
    // deviceIds: devices.map(({ dongleId }) => String(dongleId)),
    takeAll: true,
  })

  const handleOpenRedirectionSettingsModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirectionTypeId: REDIRECTION_TYPES.CALL,
        handleFetch,
        phoneSourceVariant: 'international-only',
      },
    })
  }

  const handleDeleteRedirection = (redirectionId: number) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Delete call notification?',
        subtitle: 'This action cannot be undone',
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog()
        },
        submit: {
          icon: 'trash',
          text: 'Delete',
          onClick: () => {
            handleHideDialog();
            handleDelete(redirectionId);
          }
        },
      }
    })
  }

  const handleOpenEditRedirectionModal = (redirection: RedirectionListResponse['redirections'][0]) => {
    handleOpenModal({
      type: MODAL_TYPES.REDIRECTION_SETTINGS,
      props: {
        redirection: redirection,
        redirectionTypeId: redirection.redirectionTypeId,
        handleFetch,
        handleDeleteRedirection: handleDelete,
        phoneSourceVariant: 'international-only',
      },
    })
  }

  return (
    <div className={styles.Container}>
      <Card additionalClassNames={[styles.CardAdditional]}>
        <ControlHeader
          title={'Calls notifications'}
          counter={{
            min: redirections.length,
            max: totalCount
          }}
          actions={[{
            variant: 'greenFilled',
            size: 'md',
            text: 'Create',
            prefix: <PlusIcon />,
            onClick: handleOpenRedirectionSettingsModal,
          }]}
          loading={loading}
        />

        <CallNotificationsTable
          devices={devices}
          redirections={redirections}
          totalCount={totalCount}
          page={page}
          setPage={setPage}
          setLimit={setLimit}
          pageLimit={limit}
          loading={loading}
          handleDelete={handleDeleteRedirection}
          handleEdit={handleOpenEditRedirectionModal}
          handleFetchCalls={handleFetch}
          initialFetchCompleted={initialFetchCompleted}
          setInitialFetchCompleted={setInitialFetchCompleted}
        />
      </Card>
    </div>
  )
}