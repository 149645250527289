import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import { LoadingContent } from '../../../../layouts/loading-content/LoadingContent'
import { PageWrapper } from '../../../../layouts/page-wrapper/PageWrapper'
import { NoPurchasedDevicesWifi } from '../../../../layouts/status-layouts/no-purchased-devices/wifi/NoPurchasedDevicesWifi'
import { useWifi } from '../../../../sdk/hooks/use-wifi/useWifi'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Input } from '../../../../shared/ui-kit-2/inputs/input/Input'
import { SearchInputMobile } from '../../../../shared/ui-kit-2/inputs/searchInputMobile/SearchInputMobile'
import { ReactComponent as SearchIcon } from './components/assets/icons/search.svg'
import { WifiCardList } from './components/wifi-card-list/WifiCardList'
import styles from './styles.module.scss'
import {Counter} from "../../../../shared/ui-kit-2/data-display/counter/Counter";
import {MainMessages} from "../../../../shared/intl-messages/MainMessages";
import {useWebSockets} from "../../../../shared/lib/hooks/useWebSockets";
import {WEB_SOCKETS_EVENTS_VALUES} from "../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES";

const WifiListPageMessages = defineMessages({
  title: {
    id: 'WifiListPageMessages.title',
    defaultMessage: 'WI-FI',
  },
  searchPlaceholder: {
    id: 'WifiListPageMessages.searchPlaceholder',
    defaultMessage: 'Search by name or phone',
  },
  devicesCount: {
    id: 'WifiListPageMessages.devices',
    defaultMessage: '{count} devices',
  },
})

export const WifiListPage = () => {
  const intl = useIntl()
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const [inputValue, setInputValue] = useState<string>('')

  const {
    filteredDevices,
    handleFetch: handleFetchWifi,
    handleUpdateWifi,
    handleSwitchHotspot,
    totalCount,
    loading,
  } = useWifi({ inputValue: inputValue })

  const { webSocketsEventData } = useWebSockets({
    events: [
      WEB_SOCKETS_EVENTS_VALUES.dongle_network_status_changed,
      WEB_SOCKETS_EVENTS_VALUES.dongle_subscription_status_changed
    ]
  });

  useEffect(() => {
    if (!loading) {
      handleFetchWifi({ hidden: true });
    }
  }, [webSocketsEventData]);

  const emptyDeviceList = totalCount === 0;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const content = (
    <>
      <div className={styles.PageHeader}>
        <div className={styles.TitleAndCounter}>
          <div className={styles.Title}>
            {intl.formatMessage(WifiListPageMessages.title)}
          </div>
          <Counter
            customText={`${filteredDevices.reduce((sum, {isOnline}) => isOnline ? sum + 1 : sum, 0)} ${intl.formatMessage(MainMessages.of)} ${filteredDevices.length}`}
            variant='filled'
          />
        </div>
        {!emptyDeviceList && (
          <div className={styles.SearchInputContainer}>
            {isMobile ? (
              <SearchInputMobile
                value={inputValue}
                defaultValue={inputValue}
                placeholder={intl.formatMessage(
                  WifiListPageMessages.searchPlaceholder,
                )}
                onChange={handleInputChange}
                theme="outlined"
              />
            ) : (
              <Input
                validationNeeded={false}
                placeholder={intl.formatMessage(
                  WifiListPageMessages.searchPlaceholder,
                )}
                onChange={handleInputChange}
                postfix={<SearchIcon/>}
              />
            )}
          </div>
        )}
      </div>
      {emptyDeviceList ? (
        <NoPurchasedDevicesWifi/>
      ) : (
        <WifiCardList
          devices={filteredDevices}
          onUpdateWifi={handleUpdateWifi}
          onSwitchHotspot={handleSwitchHotspot}
        />
      )}
    </>
  );

  return (
    <PageWrapper loading={loading}>
      <LoadingContent loading={loading} content={content} />
    </PageWrapper>
  )
}
