import React, { FC, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../app/hooks/useModal'
import { CommonTabMessages } from '../../../../../../config/intl/common-messages/common-tab-messages/CommonTabMessages'
import { useFilters } from '../../../../../../layouts/filters/hook/useFilters'
import { MODAL_TYPES } from '../../../../../../layouts/modals/ModalsProvider'
import { MailingTable } from '../../../../../../layouts/tables/sms-mailing-table/SmsMailingTable'
import { DongleListResponse } from '../../../../../../sdk/datagates/types/dongle/_crud/list'
import {
  MailingTabs,
  useMailings,
} from '../../../../../../sdk/hooks/use-mailings/useMailings'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import styles from './styles.module.scss'
import {WEB_SOCKETS_EVENTS_VALUES} from "../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES";
import {useWebSockets} from "../../../../../../shared/lib/hooks/useWebSockets";
import ControlHeader from "../../../../../../shared/ui-kit-3/ControlHeader/ControlHeader";
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg';


interface SmsMailingPageProps {
  activeDevices: DongleListResponse['dongles']
  activeDevicesLoading: boolean
}

const SmsMailingPageMessages = defineMessages({
  title: {
    id: 'SmsMailingPageMessages.title',
    defaultMessage: 'Bulk SMS',
  },
  create: {
    id: 'SmsMailingPageMessages.create',
    defaultMessage: 'Create New',
  },
})

export const SmsMailingPage: FC<SmsMailingPageProps> = ({ activeDevices, activeDevicesLoading }) => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()

  const [mailingTab, setMailingTab] = useState<string>(MailingTabs.ALL)
  const [limit, setLimit] = useState<number>(50)
  const [page, setPage] = useState<number>(1)
  const isMobile = useLessThanDeviceScreen(ScreenDevices.MOBILE_LK)

  const { filters, openFilters } = useFilters({
    filterFields: ['devices', 'sms-text'],
    devices: activeDevices,
    typeTab: mailingTab,
    setTypeTab: setMailingTab,
  })

  const {
    mailings,
    totalCount: mailingsTotalCount,
    handleFetch: handleFetchMailings,
    loading,
    initialFetchCompleted,
    setInitialFetchCompleted,
  } = useMailings({
    page,
    limit,
    mailingTab,
    filters,
  })

  const { webSocketsEventData } = useWebSockets({
    events: [
      WEB_SOCKETS_EVENTS_VALUES.mailing_status_changed,
      WEB_SOCKETS_EVENTS_VALUES.mailing_created,
    ]
  });

  const mailingStatusSegmentedGroup = useMemo(
    () => [
      {
        value: MailingTabs.ALL,
        comparedValue: mailingTab,
        label: intl.formatMessage(CommonTabMessages.filterAll),
        onClick: () => setMailingTab(MailingTabs.ALL),
      },
      {
        value: MailingTabs.ENDED,
        comparedValue: mailingTab,
        label: intl.formatMessage(CommonTabMessages.filterEnded),
        onClick: () => setMailingTab(MailingTabs.ENDED),
      },
      {
        value: MailingTabs.PLANNED,
        comparedValue: mailingTab,
        label: intl.formatMessage(CommonTabMessages.filterPlanned),
        onClick: () => setMailingTab(MailingTabs.PLANNED),
      },
      {
        value: MailingTabs.DRAFT,
        comparedValue: mailingTab,
        label: intl.formatMessage(CommonTabMessages.filterDraft),
        onClick: () => setMailingTab(MailingTabs.DRAFT),
      },
    ],
    [mailingTab],
  )

  const sliceMailings = useMemo(() => mailings.slice(0, 3000), [mailings]);

  const handleOpenCreateMailingModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.SMS_MAILING_CREATE,
      props: {
        handleFetch: handleFetchMailings,
      },
    })
  }

  useEffect(() => {
    setPage(1)
  }, [mailingTab, filters])

  useEffect(() => {
    if (page === 1 && !loading) {
      handleFetchMailings({ hidden: true });
    }
  }, [webSocketsEventData]);

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <ControlHeader
        title={'Bulk SMS'}
        counter={{
          min: mailings.length,
          max: mailingsTotalCount
        }}
        actions={[{
          variant: 'greenFilled',
          size: 'md',
          text: 'Create',
          prefix: <PlusIcon />,
          onClick: handleOpenCreateMailingModal,
        }]}
        loading={loading}
      />

      <MailingTable
        mailings={sliceMailings}
        totalCount={mailingsTotalCount}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        pageLimit={limit}
        loading={loading}
        handleFetch={handleFetchMailings}
        initialFetchCompleted={initialFetchCompleted}
        setInitialFetchCompleted={setInitialFetchCompleted}
      />
    </Card>
  )
}
