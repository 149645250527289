import React, {Dispatch, FC, SetStateAction, useEffect} from 'react'
import clsx from 'clsx'
import { defineMessages, useIntl } from 'react-intl'
import {
  PhoneSourceVariant,
  Source,
  useAddSource,
} from '../../_hooks/use-add-source/useAddSource'
import { ReactComponent as CloseItemIcon } from '../../../../assets/icons/close-mini.svg'
import { ReactComponent as ContactMiniIcon } from '../../../../assets/icons/mini-badge-contacts.svg'
import { Counter } from '../../../../shared/ui-kit-2/data-display/counter/Counter'
import { Switcher } from '../../../../shared/ui-kit-2/inputs/switcher/Switcher'
import { DashedAddButton } from '../../../custom-buttons/dashed-add-button/DashedAddButton'
import styles from './styles.module.scss'

interface PhoneMultipleSettingsProps {
  sources: Source[]
  setSources: Dispatch<SetStateAction<Source[]>>
  title?: string
  phoneSourceVariant?: PhoneSourceVariant
  allPhonesFeatureOn?: boolean
  allPhones?: boolean
  setAllPhones?: Dispatch<SetStateAction<boolean>>
  error?: boolean
  onChange?: () => void
  disabled?: boolean
}

const PhoneMultipleSettingsMessages = defineMessages({
  title: {
    id: 'PhoneMultipleSettingsMessages.title',
    defaultMessage: 'Incoming numbers',
  },
  all: {
    id: 'PhoneMultipleSettingsMessages.all',
    defaultMessage: 'All',
  },
})

export const PhoneMultipleSettings: FC<PhoneMultipleSettingsProps> = (
  props,
) => {
  const {
    sources,
    setSources,
    title,
    phoneSourceVariant = 'all',
    allPhonesFeatureOn = true,
    allPhones = false,
    setAllPhones,
    error,
    disabled
  } = props

  const { setIsOpen, component } = useAddSource({
    processOnSave: (source) => setSources((prev) => [...prev, source]),
    sourceList: sources,
    phoneSourceVariant: phoneSourceVariant,
  })

  const intl = useIntl()

  const removeRedirectionSource = (rs: Source) => {
    setSources((prev) =>
      prev.filter((r) => {
        return (
          r.sourceType !== rs.sourceType || r.sourceValue !== rs.sourceValue
        )
      }),
    )
  }

  useEffect(() => {
    if (allPhones) {
      setSources([]);
    }
  }, [allPhones]);

  const content = !allPhones && (
    <div className={styles.Content}>
      {sources.map((rs) => (
        <div className={styles.Item} key={rs.sourceType + rs.sourceValue}>
          {rs.sourceType !== 'phone' && <ContactMiniIcon />}
          <div>{rs.sourceName}</div>
          <CloseItemIcon
            onClick={() => removeRedirectionSource(rs)}
            className={styles.Item__close}
          />
        </div>
      ))}
      {sources.length < 100 && (
        <DashedAddButton onClick={() => setIsOpen(true)} disabled={disabled} />
      )}
    </div>
  )

  useEffect(() => {
    props?.onChange?.();
  }, [sources]);

  return (
    <>
      <div className={clsx(styles.Container, error && styles.Container_error)}>
        <div className={clsx(styles.Header, allPhones && styles.Header_all)}>
          <div className={styles.Title}>
            <div className={styles.Text}>
              {title || intl.formatMessage(PhoneMultipleSettingsMessages.title)}
            </div>
            {!allPhones && <Counter count={sources.length} size={'small'} />}
          </div>

          {allPhonesFeatureOn && (
            <Switcher
              checked={allPhones}
              onChange={() => setAllPhones && setAllPhones((prev) => !prev)}
              label={intl.formatMessage(PhoneMultipleSettingsMessages.all)}
              variant={'white'}
              disabled={disabled}
            />
          )}
        </div>

        {content}
      </div>
      {component}
    </>
  )
}
