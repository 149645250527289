import styles from './styled.module.scss';
import clsx from "clsx";
import { useState, useEffect, useRef } from "react";
import {TableHeaderFilterContent} from "../TableHeaderFilterContent/TableHeaderFilterContent";
import {isNumber} from "lodash";
import {HeaderCol} from "../../hooks/useTableFilters";
import {formatDate as formatDateToUserFormat} from "../../../../../lib/utils/date-utils/formatDate";

export enum TABLE_FILTERS_VARIANTS {
  TEXT_FIELD,
  CHECKBOXES,
  RANGE_SELECTOR,
  SEARCH,
  CALENDAR,
}

export type TableHeaderProps = {
  cols: HeaderCol[]
  currentFilters: Record<string, any>
  handleChangeFilters: (colId: number, value: unknown) => void
  gridColWidthsStyles: Record<string, string>;
};

const COLS_WITH_CUSTOM_FILTER_WIDTH = {
  time: '440px'
} as Record<string, string>;


export const TableHeader = ({ cols, currentFilters, handleChangeFilters, gridColWidthsStyles }: TableHeaderProps) => {
  const [openedColFilter, setOpenedColFilter] = useState<Record<string, any> | null>(null);
  const colRefs = useRef<Array<HTMLDivElement | null>>([]);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const blockCloseFilterRef = useRef<boolean>(false);

  const handleFilterClick = (col: Record<string, any>, index: number) => {
    if (openedColFilter === col) {
      closeFilter();
      return;
    }

    setOpenedColFilter(col);
  };

  const closeFilter = () => {
    setOpenedColFilter(null);
  };

  const handleGlobalClick = (event: MouseEvent) => {
    if (!blockCloseFilterRef.current) {
      const clickTarget = event.target as Node;

      if (
        !filterRef?.current?.contains(clickTarget) &&
        !colRefs.current.some((ref) => ref && ref.contains(clickTarget))
      ) {
        closeFilter();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleGlobalClick);

    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
    };
  }, []);

  useEffect(() => {
    if (openedColFilter) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [openedColFilter]);

  // TODO: NEEDS A HUGE REFACTORING

  const formatDate = (calendarSelectedValue: { from: string, to: string }) => {
    const { from, to } = calendarSelectedValue;

    if (from && to) {
      return `From ${formatDateToUserFormat(from)} to ${formatDateToUserFormat(to)}`;
    }

    if (from) {
      return `From ${formatDateToUserFormat(from)}`
    }

    if (to) {
      return `To ${formatDateToUserFormat(to)}`
    }

    return '';
  }

  return (
    <div className={styles.TableHeader} ref={headerRef}>
      <div
        className={styles.Cols}
        style={gridColWidthsStyles}
      >
        {cols.map((col, index) => {
          const hasFilterType = isNumber(col.filterType);

          return (
            <div
              // @ts-ignore
              key={col.label}
              className={clsx(
                styles.Col,
              )}
            >
              {col?.postfix && (
                <div className={styles.Postfix}>{col.postfix}</div>
              )}
              <div
                className={clsx(
                  styles.Col__Name,
                  col?.sort && styles.Col__Name_sort,
                  !hasFilterType && styles.Col__Name_withoutFilter
               )}
              >
                <span>{col.label}</span>
              </div>

              {hasFilterType && (
                <div
                  className={clsx(
                    styles.Col__FilterRow,
                    col.filterType === TABLE_FILTERS_VARIANTS.TEXT_FIELD && styles.Col__FilterRow_textField
                  )}
                  onClick={() => handleFilterClick(col, index)}
                >
                  {col?.defaultText && typeof col.id === 'string' && (
                    <div
                      className={styles.Col__SelectedValue}
                      ref={(el) => (colRefs.current[index] = el)}
                    >
                      {/*@ts-ignore*/}
                      {typeof currentFilters[col.id] === 'string'
                        ? currentFilters[col.id]
                        // @ts-ignore
                        : Array.isArray(currentFilters[col.id]) && currentFilters[col.id].length > 0
                          // @ts-ignore
                          ? currentFilters[col.id].includes('All')
                            ? 'All'
                            // @ts-ignore
                            : `${col.defaultText} (${currentFilters[col.id].length})`
                          : typeof currentFilters[col.id] === 'object' && currentFilters[col.id]
                            ? formatDate({
                              from: currentFilters[col.id]?.from,
                              to: currentFilters[col.id]?.to
                            })
                            : col.defaultText
                      }
                    </div>
                  )}
                  {col.defaultTextIcon &&
                    /* @ts-ignore */
                    !(col.hideDefaultTextIconAfterSelectedValue && currentFilters[col.id]) && (
                      <div>
                        {col.defaultTextIcon}
                      </div>
                    )}
                </div>
              )}

              {openedColFilter?.id === col.id && (
                <div
                  ref={filterRef}
                  className={styles.Filter}
                  style={{
                    width: COLS_WITH_CUSTOM_FILTER_WIDTH[openedColFilter.id]
                  }}
                >
                  <TableHeaderFilterContent
                    col={openedColFilter}
                    filterValue={currentFilters[openedColFilter.id]}
                    handleChangeFilters={handleChangeFilters}
                    blockCloseFilterRef={blockCloseFilterRef}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  );
};
