import { formatPhoneNumber } from '../../../../../../formatters/format-phone-number'
import {
  DongleGetBackendResponse,
  DongleGetResponse,
} from '../../../../../types/dongle/_crud/get'
import {DongleFlags} from "../../../../../types/dongle/_crud/list";

export const mapDongleGetBackendToDongleGet = (
  item: DongleGetBackendResponse,
): DongleGetResponse => {
  const {
    dongle_id,
    bootstrap_dongle_id,
    user_id,
    name,
    hotspot_name,
    hotspot_password,
    fake_imei,
    number,
    dongle_call_type_id,
    is_online,
    is_deleted,
    api_version,
    is_hotspot_enable,
    created_at,
    updated_at,
    tariff_package_id,
    tariff_package_end,
    activated_at,
    is_active,
    bootstrap_dongle,
    is_tariff_package_enabled,
    is_tariff_package_active,
    dongle_signal_quality_id,
    dongle_flags,
    sms_incoming,
    sms_outgoing,
    call_outgoing,
    call_incoming,
    is_public_vpn_enabled,
    public_vpn_endpoint,
    public_vpn_login,
    public_vpn_password,
    is_mute_incoming_call
  } = item.data

  return {
    dongleId: dongle_id,
    bootstrapDongle: {
      imeiManufacture: bootstrap_dongle.imei_manufacture,
      imeiFake: bootstrap_dongle.imei_fake
    },
    bootstrapDongleId: bootstrap_dongle_id,
    userId: user_id,
    name: name,
    hotspotName: hotspot_name,
    hotspotPassword: hotspot_password,
    fakeImei: {
      fakeImeiId: fake_imei?.fake_imei_id,
      imei: fake_imei?.imei,
      createdAt: fake_imei?.created_at,
    },
    phoneNumber: number,
    dongleCallTypeId: dongle_call_type_id,
    isOnline: is_online,
    isDeleted: is_deleted,
    apiVersion: api_version,
    isHotspotEnable: is_hotspot_enable,
    createdAt: created_at,
    updatedAt: updated_at,
    tariffPackageId: tariff_package_id,
    tariffPackageEnd: tariff_package_end,
    activatedAt: activated_at,
    isActive: is_active,
    imeiManufacture: bootstrap_dongle.imei_manufacture,
    isTariffPackageEnable: is_tariff_package_enabled,
    isTariffPackageActive: is_tariff_package_active,
    dongleSignalQualityId: dongle_signal_quality_id,
    dongleFlags: dongle_flags.map(({ dongle_flag_id, name }) => ({
      dongleFlagId: dongle_flag_id,
      name
    })),
    smsOutgoing: sms_outgoing,
    smsIncoming: sms_incoming,
    callIncoming: call_incoming,
    callOutgoing: call_outgoing,
    isVPNConnected: is_public_vpn_enabled,
    publicVPNEndpoint: public_vpn_endpoint,
    publicVPNLogin: public_vpn_login,
    publicVPNPassword: public_vpn_password,
    isMuteIncomingCall: is_mute_incoming_call,
  }
}
