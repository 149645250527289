export enum Route {
  Home,
  LegalNotice,
  PrivacyPolicy,
  TermsOfUse,
  NotFound,
  FAQ,
  Auth,
  PurchaseProcess,
  ActivateDevice,
  PaymentSuccess,
  PaymentFail,
  AddCardSuccess,
  Orders,
  DeviceList,
  Sms,
  Calls,
  WifiList,
  VPN,
  Profile,
  Api,
  Contacts,
  Dashboard
}
