import { AuthRoutePath } from '../../../../../../../constants/authRoutePath'
import { AuthRoute } from '../../../../../../../enums/authRoute'
import { RouteDescriptor } from '../../../../../../../types/routeDescriptor'
import {CallsRoutePath} from "../../../../../../../constants/callsRoutePath";
import {CallsRoute} from "../../../../../../../enums/callsRoute";
import {
  CallsAutoResponseRules
} from "../../../../../../../../../pages/lk/subpages/calls/subpages/calls-auto-response-rules/CallsAutoResponseRules";

export const callsAutoResponseRules: RouteDescriptor<CallsRoute.CallsAutoResponseRules> = {
  route: CallsRoute.CallsAutoResponseRules,
  type: 'subpage',
  title: 'Teleleo',
  path: CallsRoutePath[CallsRoute.CallsAutoResponseRules],
  render: CallsAutoResponseRules,
  accessLevel: 'user',
  noAccessRedirectPath: AuthRoutePath[AuthRoute.SignIn],
}
