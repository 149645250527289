import {StickerProps} from "./Sticker.types";
import styles from './styles.module.scss';
import clsx from "clsx";

const Sticker = ({
  variant,
  text
}: StickerProps) => {
  return (
    <div className={clsx(
      styles.Sticker,
      variant === 'red' && styles.Sticker_red,
      variant === 'green' && styles.Sticker_green,
      variant === 'grey' && styles.Sticker_grey,
    )}>
      {text}
    </div>
  )
}

export default Sticker;