import React, { FC, useState } from 'react'
import { Form } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import { useSnackbar } from '../../../../../../app/hooks/useSnackbar'
import { CommonButtonMessages } from '../../../../../../config/intl/common-messages/common-button-messages/CommonButtonMessages'
import { formatPhoneNumber } from '../../../../../../sdk/formatters/format-phone-number'
import { InputField } from '../../../../../../shared/lib/form/form-field-adapters/v2/input-field/InputField'
import { clearPhoneMutator } from '../../../../../../shared/lib/form/form-helpers/mutators'
import {
  composeValidators,
  useFormRules,
} from '../../../../../../shared/lib/form/form-rules'
import { Button } from '../../../../../../shared/ui-kit-2/inputs/button/Button'
import { RadioProps } from '../../../../../../shared/ui-kit-2/inputs/radio/components/Radio'
import { RadioGroup } from '../../../../../../shared/ui-kit-2/inputs/radio/RadioGroup'
import { SNACKBAR_TYPES } from '../../../../../snackbar/SnackbarProvider'
import { PhoneSourceVariant, Source } from '../../useAddSource'
import styles from './styles.module.scss'
import {
  PhoneInputFieldNumber
} from "../../../../../../shared/lib/form/form-field-templates/v2/phone-input-field-new/PhoneInputFieldNew";
import {getClearedPhoneNumber} from "../../../../../../shared/lib/utils/get-cleared-phone-number/getClearedPhoneNumber";
import { PHONE_NUMBER_VARIANTS } from 'shared/lib/constants/PHONE_NUMBER_VARIANTS'

interface SourcePhonesProps {
  onSave: (source: Source) => void
  sourceList: Source[]
  phoneSourceVariant: PhoneSourceVariant
}

type SourcePhonesFormType = {
  phone?: string
  shortNumber?: string
  alphaNumber?: string
}

const SourcePhonesMessages = defineMessages({
  optionInternational: {
    id: 'SourcePhonesMessages.optionInternational',
    defaultMessage: 'International',
  },
  optionShort: {
    id: 'SourcePhonesMessages.optionShort',
    defaultMessage: 'Short',
  },
  optionAlphaNumber: {
    id: 'SourcePhonesMessages.optionAlphaNumber',
    defaultMessage: 'Alpha name',
  },
  phoneLabel: {
    id: 'SourcePhonesMessages.phoneLabel',
    defaultMessage: 'Phone number',
  },
  alphaNumberLabel: {
    id: 'SourcePhonesMessages.alphaNumberLabel',
    defaultMessage: 'Alpha number',
  },
  shortNumberLabel: {
    id: 'SourcePhonesMessages.shortNumberLabel',
    defaultMessage: 'Short number',
  },
  alphaNumberPlaceholder: {
    id: 'SourcePhonesMessages.alphaNumberPlaceholder',
    defaultMessage: 'For example: google',
  },
  shortNumberPlaceholder: {
    id: 'SourcePhonesMessages.shortNumberPlaceholder',
    defaultMessage: '12345',
  },
  alreadyAdded: {
    id: 'SourcePhonesMessages.alreadyAdded',
    defaultMessage: 'Phone already added',
  },
  error: {
    id: 'SourcePhonesMessages.error',
    defaultMessage: 'Please enter a valid phone number',
  },
})

export const SourcePhones: FC<SourcePhonesProps> = (props) => {
  const { onSave, sourceList, phoneSourceVariant } = props

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { ruleRequired, ruleShortPhone, ruleAlphaNumber } = useFormRules()
  const [phoneType, setPhoneType] = useState<string>(PHONE_NUMBER_VARIANTS.international)

  const onSubmit = (values: SourcePhonesFormType) => {
    let phone = ''

    if (values.phone) {
      phone = getClearedPhoneNumber(values.phone) as string
    } else if (values.shortNumber) {
      phone = values.shortNumber
    } else if (values.alphaNumber) {
      phone = values.alphaNumber
    }

    if (sourceList.find((s) => s.sourceValue === phone)) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: intl.formatMessage(SourcePhonesMessages.alreadyAdded),
      })
      return
    }

    onSave({
      sourceType: 'phone',
      sourceName: formatPhoneNumber(phone),
      sourceValue: phone,
    })
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        mutators={{
          clearPhoneMutator,
        }}
        render={({ handleSubmit, submitting, values, form }) => {
          const mutator = form.mutators.clearPhoneMutator

          const phoneTypeRadioOptions: RadioProps[] = [
            {
              label: intl.formatMessage(
                SourcePhonesMessages.optionInternational,
              ),
              value: PHONE_NUMBER_VARIANTS.international,
              comparedValue: phoneType,
              onClick: () => {
                setPhoneType(PHONE_NUMBER_VARIANTS.international)
                mutator?.()
              },
              variant: 'outlined',
              additionalClassNames: [styles.RadioAdditional],
            },
            {
              label: intl.formatMessage(SourcePhonesMessages.optionShort),
              value: PHONE_NUMBER_VARIANTS.short,
              comparedValue: phoneType,
              onClick: () => {
                setPhoneType(PHONE_NUMBER_VARIANTS.short)
                mutator?.()
              },
              variant: 'outlined',
            },
            {
              label: intl.formatMessage(SourcePhonesMessages.optionAlphaNumber),
              value: PHONE_NUMBER_VARIANTS.alpha,
              comparedValue: phoneType,
              onClick: () => {
                setPhoneType(PHONE_NUMBER_VARIANTS.alpha)
                mutator?.()
              },
              variant: 'outlined',
            },
          ]

          return (
            <>
              {phoneSourceVariant === 'all' && (
                <RadioGroup
                  name={'phone-types'}
                  group={phoneTypeRadioOptions}
                  additionalClassNames={[styles.RadioGroupAdditional]}
                />
              )}

              <form onSubmit={handleSubmit} className={styles.Container}>
                {phoneType === PHONE_NUMBER_VARIANTS.international && (
                  <PhoneInputFieldNumber
                    name="phone"
                    values={values}
                    label={intl.formatMessage(SourcePhonesMessages.phoneLabel)}
                    noHelperText={true}
                    markAsRequired={true}
                  />
                )}

                {phoneType === PHONE_NUMBER_VARIANTS.short && (
                  <InputField
                    name={'shortNumber'}
                    type={'tel'}
                    validate={composeValidators(
                      ruleRequired(),
                      ruleShortPhone(),
                    )}
                    label={intl.formatMessage(SourcePhonesMessages.phoneLabel)}
                    placeholder={intl.formatMessage(
                      SourcePhonesMessages.shortNumberPlaceholder,
                    )}
                    markAsRequired={true}
                  />
                )}

                {phoneType === PHONE_NUMBER_VARIANTS.alpha && (
                  <InputField
                    name={'alphaNumber'}
                    type={'tel'}
                    validate={composeValidators(
                      ruleRequired(),
                      ruleAlphaNumber(),
                    )}
                    label={intl.formatMessage(SourcePhonesMessages.phoneLabel)}
                    placeholder={intl.formatMessage(
                      SourcePhonesMessages.alphaNumberPlaceholder,
                    )}
                    markAsRequired={true}
                  />
                )}

                <Button
                  type={'submit'}
                  text={intl.formatMessage(CommonButtonMessages.add)}
                  onClick={handleSubmit}
                  disabled={submitting}
                  loading={submitting}
                />
              </form>
            </>
          )
        }}
      />
    </>
  )
}
