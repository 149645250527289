import React, {Dispatch, FC, SetStateAction, useEffect} from 'react'
import { useIntl } from 'react-intl'
import { CommonTableMessages } from '../../../config/intl/common-messages/common-table-messages/CommonTableMessages'
import { SmsListResponse } from '../../../sdk/datagates/types/sms/_crud/list'
import {
  Table,
  TableColType,
} from '../../../shared/ui-kit-2/data-display/table/Table'
import { ReactComponent as SortAlphabetIconUnActive } from "../../../assets/icons/sort-by-alphabet-unactive.svg";
import { ReactComponent as SortAlphabetIconTrue } from "../../../assets/icons/sort-by-alphabet-true.svg";
import { ReactComponent as SortAlphabetIconFalse } from "../../../assets/icons/sort-by-alphabet-false.svg";
import { ReactComponent as CaretDownIcon } from '../../../assets/icons/v2/ic-caret-down.svg';
import { LC } from '../../../tests/e2e/locators'
import { NoDevicesForSmses } from '../../status-layouts/no-devices-for-smses/NoDevicesForSmses'
import { SmsTableRows } from './components/sms-table-rows/SmsTableRows'
import {TABLE_FILTERS_VARIANTS} from "../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS,
  IconContainer
} from "../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import {HeaderCol, useTableFilters} from "../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {
  tableFiltersToUrlFilters
} from "../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";
import {QueryParameter} from "../../../sdk/datagates/helpers/_common/wrap-api-request";
import {SMS_STATUSES} from "../../../pages/lk/subpages/sms/components/SmsStatus/constants/SmsStatuses";
import _ from "lodash";

interface SmsTableProps {
  smses: SmsListResponse['smses']
  totalCount: number
  loading: boolean
  handleFetch: (props: { params?: QueryParameter[] | undefined; hidden?: boolean | undefined }) => Promise<void>
  handleDeleteSms: (dongleId: number, smsId: number) => Promise<void>
  activeDevicesTotalCount: number
  settings: Record<string, any>
  handleChangeSettings: (arg: Object) => void
  initialFetchCompleted: boolean
  setInitialFetchCompleted: Dispatch<SetStateAction<boolean>>
}

export const SORTED_COLS = {
  status: 'sms_status_id',
  time: 'created_at'
}

export const SmsTable: FC<SmsTableProps> = (props) => {
  const {
    smses,
    loading,
    totalCount,
    handleFetch,
    handleDeleteSms,
    activeDevicesTotalCount,
    settings,
    handleChangeSettings,
    initialFetchCompleted,
    setInitialFetchCompleted,
  } = props

  const intl = useIntl()

  const handlePageChange = (page: number, limit: number) => handleChangeSettings({ page, limit })

  const handleSorted = (field: string) => {
    if (!loading) {
      const orderBy = field !== settings.sortBy
        ? 'desc'
        : settings.orderBy === 'asc'
          ? 'desc'
          : 'asc'
      handleChangeSettings({ orderBy, sortBy: field })
    }
  }

  const getIsActive = (field: string) => settings.sortBy === field;
  const getIsSorted = (field: string) => getIsActive(field) && settings.orderBy === 'desc';

  const getSortIcon = (field: string) => {
    if (settings.sortBy === field) {
      return settings.orderBy === 'desc' ? <SortAlphabetIconTrue /> : <SortAlphabetIconFalse />
    }

    return <SortAlphabetIconUnActive />
  }

  const cols: TableColType[] = [
    { title: intl.formatMessage(CommonTableMessages.columnType) },
    {
      title: intl.formatMessage(CommonTableMessages.columnTime),
      handleSorted: () => handleSorted(SORTED_COLS.time),
      isSorted: getIsSorted(SORTED_COLS.time),
      isActive: getIsActive(SORTED_COLS.time),
    },
    {
      title: intl.formatMessage(CommonTableMessages.columnStatus),
      handleSorted: () => handleSorted(SORTED_COLS.status),
      isSorted: getIsSorted(SORTED_COLS.status),
      isActive: getIsActive(SORTED_COLS.status),
      sortIcon: getSortIcon(SORTED_COLS.status)
    },
    { title: intl.formatMessage(CommonTableMessages.columnSender) },
    { title: intl.formatMessage(CommonTableMessages.columnReceiver) },
    {
      title: intl.formatMessage(CommonTableMessages.columnSmsc),
    },
    { title: intl.formatMessage(CommonTableMessages.columnText) },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ]

  const rowGroups = SmsTableRows(smses, handleFetch, handleDeleteSms, settings)

  const COLS = {
    type: 'type',
    smsc: 'smsc',
  }

  const headerCols = [
    {
      id: COLS.type,
      filterType: TABLE_FILTERS_VARIANTS.CHECKBOXES,
      label: 'Type',
      defaultText: 'Select',
      defaultTextIcon: (
        <IconContainer>
          <CaretDownIcon />
        </IconContainer>
      ),
      checkboxes: [
        { text: 'All', value: 'All' },
        { text: 'Incoming', value: '2' },
        { text: 'Outgoing', value: '1' },
        { text: 'Planned', value: '5' },
      ]
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.smsStatus],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.sender],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.recipient],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.smsc],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.textMessage],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const { currentFilters, handleChangeFilters } = useTableFilters(headerCols);

  useEffect(() => {
    let smsStatuses = currentFilters?.smsStatus ? [...currentFilters?.smsStatus] : undefined;

    if (smsStatuses) {
      if (smsStatuses.includes(SMS_STATUSES.CREATED)) {
        smsStatuses.push(SMS_STATUSES.SENT)
      } else {
        smsStatuses = smsStatuses.filter(status => status !== SMS_STATUSES.SENT)
      }
    }

    const debouncedFetch = _.debounce(() => {
      handleFetch({
        params: tableFiltersToUrlFilters([
          { name: 'sms_type_ids[]', value: currentFilters?.type },
          { name: 'from', value: currentFilters?.time?.from },
          { name: 'to', value: currentFilters?.time?.to },
          { name: 'sms_status_ids[]', value: smsStatuses },
          { name: 'caller', value: currentFilters?.sender },
          { name: 'receiver', value: currentFilters?.recipient },
          { name: 'sms_center', value: currentFilters?.smsc },
          { name: 'text', value: currentFilters?.textMessage },
        ])
      })
    }, 300);

    if (initialFetchCompleted) {
      debouncedFetch();
    } else {
      setInitialFetchCompleted(true);
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [currentFilters]);

  return (
    <Table
      headerCols={headerCols}
      currentFilters={currentFilters}
      handleChangeFilters={handleChangeFilters}
      isNew

      name={'SMS'}
      cols={cols}
      rowGroups={rowGroups}
      currentPage={settings.page}
      totalCount={totalCount}
      onPageChange={handlePageChange}
      itemsPerPage={settings.limit}
      loading={loading}
      columnWidths={['100px', '125px', '120px', 2, 2, 1.5, 2, 2.5]}
      testId={LC.SMS.TABLE._}
      noDataComponent={activeDevicesTotalCount ? null : <NoDevicesForSmses />}
    />
  )
}
