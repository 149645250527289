import { useEffect } from 'react';
import { useSelector } from "react-redux";

export function useClickOutside<T>(
  ref: React.RefObject<HTMLElement>,
  onClickOutside: () => void,
  shouldBlockClicks: () => boolean = () => false
) {
  // @ts-ignore
  const { isOpen } = useSelector((store) => store.dialog);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !shouldBlockClicks() &&
        !isOpen
      ) {
        onClickOutside();
      }
    }

    document.addEventListener('mousedown', handleClickOutside, true); // Добавляем capture фазу
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [ref, isOpen, onClickOutside, shouldBlockClicks]);
}
