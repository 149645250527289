import React, { FC, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useSiteVersion } from '../../../../../../app/hooks/useSiteVersion'
import { useSnackbar } from '../../../../../../app/hooks/useSnackbar'
import { RoutePath } from '../../../../../../config/routes/constants/routePath'
import { Route } from '../../../../../../config/routes/enums/route'
import { SNACKBAR_TYPES } from '../../../../../../layouts/snackbar/SnackbarProvider'
import { useVerificationCodeInput } from '../../../../../../layouts/verification-code-input/useVerificationCodeInput'
import {
  generateEmailVerificationTokenDatagate,
  signUpApproveDatagate,
} from '../../../../../../sdk/datagates/api/auth'
import { userGetDatagate } from '../../../../../../sdk/datagates/api/user'
import { useBackendErrorCodes } from '../../../../../../sdk/datagates/helpers/_common/use-backend-error-codes'
import { getErrorMessage } from '../../../../../../shared/lib/utils/get-error-message/getErrorMessage'
import { useAppDispatch } from '../../../../../../store'
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from '../../../../../../store/reducers/user'
import styles from './styles.module.scss'

interface SignUpApproveProps {
  email: string
}

const SignUpApproveMessages = defineMessages({
  title: {
    id: 'SignUpApprove.title',
    defaultMessage: 'Enter Verification Code',
  },
  description: {
    id: 'SignUpApprove.description',
    defaultMessage: 'The code was sent to {email}',
  },
})

export const SignUpApprove: FC<SignUpApproveProps> = (props) => {
  const { email } = props

  const intl = useIntl()
  const { handleOpenSnackbar } = useSnackbar()
  const { resolveBackendError } = useBackendErrorCodes()
  const { siteDomainId } = useSiteVersion()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const getUser = async () => {
    try {
      const { data } = await userGetDatagate()
      dispatch(setUser(data))
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
    }
  }

  const handleApprove = async (code: string) => {
    try {
      setLoading(true)
      const { data } = await signUpApproveDatagate({
        verification_token: code,
        email: email,
        site_domain_id: siteDomainId,
      })
      setAccessToken(data.accessToken)
      setRefreshToken(data.refreshToken)
      await getUser()
      navigate(RoutePath[Route.Dashboard])
    } catch (e) {
      handleOpenSnackbar({
        type: SNACKBAR_TYPES.error,
        text: resolveBackendError(getErrorMessage(e)),
      })
      setHasErrors(true)
    } finally {
      setLoading(false)
    }
  }

  const { component } = useVerificationCodeInput(
    handleApprove,
    loading,
    hasErrors,
    () =>
      generateEmailVerificationTokenDatagate({
        email: email,
        site_domain_id: siteDomainId,
      }),
  )

  return (
    <div className={styles.VerifySignUpContainer}>
      <div className={styles.VerifySignUpContent}>
        <div className={styles.Title}>
          {intl.formatMessage(SignUpApproveMessages.title)}
        </div>

        <div className={styles.Description}>
          {intl.formatMessage(SignUpApproveMessages.description, {
            email: email,
          })}
        </div>

        {component}
      </div>
    </div>
  )
}
