
import { Card } from '../../../../../../shared/ui-kit-2/data-display/card/Card'
import styles from './styles.module.scss';
import {WavSurfer, WavSurferThemes} from "../../../../../../shared/ui-kit-2/components/wav-surfer/WavSurfer";
import {Table, TableColType} from "../../../../../../shared/ui-kit-2/data-display/table/Table";
import {useAutoResponseRules} from "../../../../../../sdk/hooks/use-auto-response-rules/useAutoResponseRules";
import React, {useEffect, useMemo, useState} from "react";
import {
  CommonTableMessages
} from "../../../../../../config/intl/common-messages/common-table-messages/CommonTableMessages";
import {useIntl} from "react-intl";
import {formatDate} from "../../../../../../shared/lib/utils/date-utils/formatDate";
import {Sticker} from "../../../../../../shared/ui-kit-2/data-display/sticker/Sticker";
import { ReactComponent as TrashIcon } from "../../../../../../assets/icons/trash.svg";
import { ReactComponent as CaretRightIcon } from '../../../../../../assets/icons/caret-right.svg';
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/v2/ic-plus.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/v2/ic-close.svg';
import {MODAL_TYPES} from "../../../../../../layouts/modals/ModalsProvider";
import {useModal} from "../../../../../../app/hooks/useModal";
import {useDialog} from "../../../../../../app/hooks/useDialog";
import {DialogVersion} from "../../../../../../store/reducers/dialog/types";
import {
  DEFAULT_COLS,
  DEFAULT_COLS_VARIANTS
} from "../../../../../../shared/ui-kit-2/data-display/table/contants/defaultCols";
import {HeaderCol, useTableFilters} from "../../../../../../shared/ui-kit-2/data-display/table/hooks/useTableFilters";
import {
  TABLE_FILTERS_VARIANTS
} from "../../../../../../shared/ui-kit-2/data-display/table/components/TableHeader/TableHeader";
import {Button} from "../../../../../../shared/ui-kit-3/Button";
import {Counter} from "../../../../../../shared/ui-kit-2/data-display/counter/Counter";
import {
  tableFiltersToUrlFilters
} from "../../../../../../shared/lib/utils/table-filters-to-url-filters/tableFiltersToUrlFilters";
import ControlHeader from "../../../../../../shared/ui-kit-3/ControlHeader/ControlHeader";
import _ from "lodash";

export const CallsAutoResponseRules = () => {
  const intl = useIntl()
  const { handleOpenModal } = useModal()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)

  const [activeWavSurfer, setActiveWavSurfer] = useState<any>(null);

  const {
    rules,
    totalCount,
    loading,
    handleFetchRules,
    handleUpdateRules,
    handleAddRule,
    handleUpdateRule,
    handleDeleteRule,
    initialFetchCompleted,
    setInitialFetchCompleted,
  } = useAutoResponseRules({
    page: 1,
    limit
  });

  const { handleOpenDialog, handleHideDialog } = useDialog();

  const handlePageChange = (page: number, limit: number) => {
    setPage(page)
    setLimit(limit)
  }

  const cols: TableColType[] = [
    { title: intl.formatMessage(CommonTableMessages.columnTime) },
    { title: 'Name' },
    { title: 'Modem name' },
    { title: 'Phone' },
    { title: 'Audio file' },
    {
      title: intl.formatMessage(CommonTableMessages.columnActions),
      position: 'right',
    },
  ];

  const handleDialogDeleteRule = async (callScenarioId: number) => {
    handleOpenDialog({
      version: DialogVersion.v2,
      props: {
        title: 'Delete rule?',
        subtitle: 'This action cannot be undone',
        cancel: {
          text: 'Cancel',
          onClick: () => handleHideDialog()
        },
        submit: {
          icon: 'trash',
          text: 'Delete',
          onClick: async () => {
            await handleDeleteRule({ callScenarioId });
            await handleFetchRules();
            handleHideDialog();
          }
        },
      }
    })
  }

  const rowGroups = useMemo(() => {
    if (Array.isArray(rules)) {
      return [{
        groupHeader: <></>,
        rows: rules.map(rule => {
          const { call_scenario_id, updated_at, name, is_call_record, file, dongle } = rule;

          return [
            <div className={styles.Time}>
              {formatDate(updated_at, 0, 'time')}
            </div>,
            <div>{name}</div>,
            <div>
              {dongle?.name}
            </div>,
            <div>{dongle?.number}</div>,
            <div>
              {is_call_record && (
                <WavSurfer
                  audioSource={file.cdn_url}
                  audioWavesWidth={61}
                  showDuration={false}
                  theme={WavSurferThemes.black}
                />
              )}
            </div>,
            <div className={styles.Actions}>
              <button
                className={styles.Action}
                onClick={() => handleDialogDeleteRule(call_scenario_id)}
              >
                <TrashIcon/>
              </button>
              <div
                className={styles.Action}
                onClick={() => handleOpenModal({
                  type: MODAL_TYPES.CALLS_AUTO_RESPONSE_RULES,
                  props: {
                    rule,
                    handleUpdateRule,
                    handleUpdateRules,
                    handleFetchRules
                  }
                })}
              >
                <CaretRightIcon/>
              </div>
            </div>,
          ]
        })
      }]
    }

    return [];
  }, [rules]);

  const COLS = {
    name: 'name',
    modemName: 'modemName'
  }

  const headerCols = [
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.time],
    {
      id: COLS.name,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Name',
      defaultText: 'Enter the name',
    },
    {
      id: COLS.modemName,
      filterType: TABLE_FILTERS_VARIANTS.TEXT_FIELD,
      label: 'Modem name',
      defaultText: 'Enter the name',
    },
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.phone],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.audioFile],
    DEFAULT_COLS[DEFAULT_COLS_VARIANTS.actions],
  ] as HeaderCol[];

  const { currentFilters, handleChangeFilters } = useTableFilters(headerCols);

  useEffect(() => {
    const debouncedFetch = _.debounce(() => {
      handleFetchRules({
        params: tableFiltersToUrlFilters([
          { name: 'from', value: currentFilters?.time?.from },
          { name: 'to', value: currentFilters?.time?.to },
          { name: 'name', value: currentFilters?.name },
          { name: 'dongle_name', value: currentFilters?.modemName },
          { name: 'dongle_phone', value: currentFilters?.phone && encodeURIComponent(currentFilters?.phone) },
          {
            name: 'is_empty_file_id',
            value: currentFilters?.audioFile && !currentFilters?.audioFile.includes('All')
              ? currentFilters.audioFile[0]
              : undefined
          },
        ])
      })
    }, 300);

    if (initialFetchCompleted) {
      debouncedFetch();
    } else {
      setInitialFetchCompleted(true);
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [currentFilters]);

  return (
    <Card additionalClassNames={[styles.CardAdditional]}>
      <div className={styles.CallAutoResponseRules}>
        <ControlHeader
          title={'Autoreply rules'}
          counter={{
            min: rules.length,
            max: totalCount
          }}
          actions={[{
            variant: 'greenFilled',
            size: 'md',
            text: 'Create',
            prefix: <PlusIcon />,
            onClick: () => handleOpenModal({
              type: MODAL_TYPES.CALLS_AUTO_RESPONSE_RULES,
              props: {
                handleAddRule,
                handleFetchRules
              }
            }),
          }]}
          loading={loading}
        />
        {false && (
          <div className={styles.Wrapper}>
            <div className={styles.Audio}>
              <div className={styles.Audio__Wrapper}>
                <WavSurfer
                  audioWavesWidth={225}
                  theme={WavSurferThemes.white}
                />
              </div>
              <button
                className={styles.Audio__Close}
                onClick={() => setActiveWavSurfer(null)}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        )}
        <Table
          headerCols={headerCols}
          currentFilters={currentFilters}
          handleChangeFilters={handleChangeFilters}
          isNew

          name={'CallsAutoResponseRules'}
          cols={cols}
          rowGroups={rowGroups}
          currentPage={page}
          totalCount={totalCount}
          onPageChange={handlePageChange}
          loading={loading}
          columnWidths={['125px', 3, 3, 3, 2, 1]}
          itemsPerPage={limit}
        />
      </div>
    </Card>
  )
}