import React, { FC, ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { formatClasses } from '../../../lib/utils/format-classes/formatClasses';
import {useLocation} from "react-router";

export type CloudTabType = {
  id: string;
  label: string;
  render?: ReactNode;
  onClick?: () => void;
  testId?: string;
  disabled?: boolean;
  anchorId?: string;
};

type CloudTabsType = Array<CloudTabType>;

interface CloudTabsProps {
  items: CloudTabsType;
  mainRoot?: string;
  defaultTabId?: string;
  handleChange?: (id: string) => void;
  enableScrollTracking?: boolean;
  scrollTrackingOffset?: number;
  classes?: {
    container?: string[] | string;
    nav?: string[] | string;
  };
}

export const CloudTabs: FC<CloudTabsProps> = ({
  items,
  mainRoot,
  defaultTabId,
  handleChange = () => null,
  enableScrollTracking = false,
  scrollTrackingOffset = 0,
  classes,
  ...props
}) => {
  const classesContainer = formatClasses(classes?.container);
  const classesNav = formatClasses(classes?.nav);

  const getTabById = (foundedId: string) =>
    items.find(({ id }) => id === foundedId) || items[0];

  const [selectedTab, setSelectedTab] = useState<CloudTabType>(
    defaultTabId ? getTabById(defaultTabId) : items[0],
  );

  const handleChangeTab = (id: string) => {
    if (handleChange) {
      handleChange(id);
    }

    setSelectedTab(getTabById(id));
  };

  /*
  TODO: Ninja wtf code

  useEffect(() => {
    if (mainRoot === pathname) {
      handleChangeTab(items[0].id);
    }
  }, [mainRoot, pathname]);
  */

  useEffect(() => {
    if (!enableScrollTracking) return;

    const handleScroll = () => {
      let lastMatchingTab: string | null = null;

      items.forEach(({ anchorId }) => {
        if (!anchorId) return;

        const element = document.getElementById(anchorId);
        if (!element) return;

        const rect = element.getBoundingClientRect();
        if (rect.top - scrollTrackingOffset <= 0) {
          lastMatchingTab = anchorId;
        }
      });

      if (lastMatchingTab) {
        const activeTab = items.find((item) => item.anchorId === lastMatchingTab);
        if (activeTab && activeTab.id !== selectedTab.id) {
          setSelectedTab(activeTab);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [items, enableScrollTracking, scrollTrackingOffset, selectedTab]);

  return (
    <div {...props} className={clsx(styles.CloudTabsWrapper, ...classesContainer)}>
      <div className={clsx(styles.CloudTabs, ...classesNav)}>
        {items?.map(({ id, label, testId, disabled }) => (
          <button
            key={id}
            className={clsx(
              styles.CloudTab,
              selectedTab.id === id && styles.CloudTab_selected,
            )}
            onClick={() => handleChangeTab(id)}
            data-test-id={testId}
            disabled={disabled}
          >
            {label}
          </button>
        ))}
      </div>
      {selectedTab?.render}
    </div>
  );
};
