import React, {FC, useEffect, useMemo, useRef, useState} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useModal } from '../../../../../../../../../app/hooks/useModal'
import { CommonTabMessages } from '../../../../../../../../../config/intl/common-messages/common-tab-messages/CommonTabMessages'
import {
  useFilters,
  UseFiltersTestIdsType,
} from '../../../../../../../../../layouts/filters/hook/useFilters'
import { MODAL_TYPES } from '../../../../../../../../../layouts/modals/ModalsProvider'
import { CallTable } from '../../../../../../../../../layouts/tables/call-table/CallTable'
import { DongleListResponse } from '../../../../../../../../../sdk/datagates/types/dongle/_crud/list'
import {
  CallTypeTabs,
  useCalls,
} from '../../../../../../../../../sdk/hooks/use-calls/useCalls'
import {
  ScreenDevices,
  useLessThanDeviceScreen,
} from '../../../../../../../../../shared/lib/hooks/useLessThanDeviceScreen'
import { Card } from '../../../../../../../../../shared/ui-kit-2/data-display/card/Card'
import { LC } from '../../../../../../../../../tests/e2e/locators'
import styles from './styles.module.scss'
import {useSip} from "../../../../../../../../../shared/lib/hooks/useSip";
import {useSiteVersion} from "../../../../../../../../../app/hooks/useSiteVersion";
import {callType} from "../../../../../../../../../store/reducers/sip/types";
import {contactListDatagate} from "../../../../../../../../../sdk/datagates/api/contact";
import {useWebSockets} from "../../../../../../../../../shared/lib/hooks/useWebSockets";
import {WEB_SOCKETS_EVENTS_VALUES} from "../../../../../../../../../shared/lib/constants/WEB_SOCKETS_EVENTS_VALUES";
import ControlHeader from "../../../../../../../../../shared/ui-kit-3/ControlHeader/ControlHeader";
import { ReactComponent as CallIcon } from '../../../../../../../../../assets/icons/call.svg';


interface CallListProps {
  devices: DongleListResponse['dongles']
  devicesLoading: boolean
}

const useFiltersTestIds: UseFiltersTestIdsType = {
  dateFromTestId: LC.CALLS.FILTER.DATEPICKER_FROM,
  dateToTestId: LC.CALLS.FILTER.DATEPICKER_TO,
  devicesMultiSelectTestId: LC.CALLS.FILTER.DEVICES_MULTISELECT,
  clearAllButtonTestId: LC.CALLS.FILTER.SUBMIT_BTN,
  submitButtonTestId: LC.CALLS.FILTER.SUBMIT_BTN,
}

const CallsListMessages = defineMessages({
  title: {
    id: 'CallsListMessages.title',
    defaultMessage: 'Calls',
  },
  call: {
    id: 'CallsListMessages.call',
    defaultMessage: 'Call',
  },
})

export const CallsList: FC<CallListProps> = (props) => {
  const { devices, devicesLoading } = props
  const activeDevices = useMemo(() => devices.filter((device: any) => device.isActive), [devices])
  const intl = useIntl()
  const { siteVersion } = useSiteVersion();
  const { handleChangeModal } = useModal();

  const [callTypeTab, setCallTypeTab] = useState<string>(CallTypeTabs.ALL)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(50)

  const audioRef = useRef(null);

  const { webSocketsEventData } = useWebSockets({
    events: [WEB_SOCKETS_EVENTS_VALUES.call_created]
  });

  const {
    deviceFrom,
    phoneFrom,
    makeCall,
    answerIncomingCall,
    endCall,
    ua,
    hasOutgoingCall,
    hasIncomingCall,
    callHasAcceptedByOtherSide,
    loading: sipLoading,
    setAudioRef,
  } = useSip();
  const { filters } = useFilters({
    filterFields: ['dates', 'devices', 'phone-numbers'],
    devices: activeDevices,
    testIds: useFiltersTestIds,
    typeTab: callTypeTab,
    setTypeTab: setCallTypeTab,
  })

  const callTypesTabForUseCalls = useMemo(() => callTypeTab === CallTypeTabs.ALL ? ['1', '2'] : [callTypeTab], [callTypeTab])

  const {
    calls,
    totalCount,
    handleDeleteCall,
    handleFetch: handleFetchCalls,
    loading,
    initialFetchCompleted,
    setInitialFetchCompleted
  } =
    useCalls({
      page,
      limit,
      callTypesTab: callTypesTabForUseCalls,
      filters,
    })

  const { handleOpenModal } = useModal()
  const sliceCalls = calls.slice(0, 3000)

  useEffect(() => {
    setAudioRef(audioRef.current);
  }, [setAudioRef]);

  useEffect(() => {
    if (page === 1 && !loading) {
      handleFetchCalls({ hidden: true });
    }
  }, [webSocketsEventData]);

  useEffect(() => {
    const handleIncomingCall = async () => {
      if (hasIncomingCall) {
        const { data } = await contactListDatagate(null, null,[
          { name: 'phone', value: phoneFrom?.replace('+', '%2B') || '' },
          { name: 'page', value: '0' },
          { name: 'limit', value: '1' },
        ])

        handleOpenModal({
          type: MODAL_TYPES.CALL,
          props: {
            from: {
              name: data?.contacts?.[0]?.name,
              phoneNumber: phoneFrom,
            },
            to: {
              name: deviceFrom?.name,
              phoneNumber: deviceFrom?.phoneNumber,
            },
            type: callType.incoming,
            sip: {
              makeCall,
              answerIncomingCall,
              endCall,
              ua,
              hasOutgoingCall,
              hasIncomingCall,
              callHasAcceptedByOtherSide,
              loading: sipLoading
            }
          }
        })
      }
    }

    handleIncomingCall();
  }, [hasIncomingCall, deviceFrom]);

  useEffect(() => {
    if (hasIncomingCall || hasOutgoingCall) {
      handleChangeModal({
        type: hasIncomingCall
          ? callType.incoming
          : hasOutgoingCall
            ? callType.outgoing
            : null,
        sip: {
          makeCall,
          answerIncomingCall,
          endCall,
          ua,
          hasOutgoingCall,
          hasIncomingCall,
          callHasAcceptedByOtherSide,
          loading: sipLoading
        }
      })
    }
  }, [sipLoading, phoneFrom, deviceFrom, callHasAcceptedByOtherSide]);

  const openCreateCallModal = () => {
    handleOpenModal({
      type: MODAL_TYPES.CREATE_CALL,
      props: {
        makeCall,
        answerIncomingCall,
        endCall,
        ua,
        hasOutgoingCall,
        hasIncomingCall,
        callHasAcceptedByOtherSide,
      }
    })
  }

  const callTypeSegmentedGroup = useMemo(
    () => [
      {
        value: CallTypeTabs.ALL,
        comparedValue: callTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterAll),
        onClick: () => setCallTypeTab(CallTypeTabs.ALL),
        testId: LC.CALLS.TYPE.ALL,
      },
      {
        value: CallTypeTabs.INCOMING,
        comparedValue: callTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterIncoming),
        onClick: () => setCallTypeTab(CallTypeTabs.INCOMING),
        testId: LC.CALLS.TYPE.INCOMING,
      },
      {
        value: CallTypeTabs.OUTGOING,
        comparedValue: callTypeTab,
        label: intl.formatMessage(CommonTabMessages.filterOutgoing),
        onClick: () => setCallTypeTab(CallTypeTabs.OUTGOING),
        testId: LC.CALLS.TYPE.OUTGOING,
      },
      // {
      //   value: CallTypeTabs.DELAYED,
      //   comparedValue: callTypeTab,
      //   label: intl.formatMessage(CommonTabMessages.filterDelayed),
      //   onClick: () => setCallTypeTab(CallTypeTabs.DELAYED),
      //   testId: LC.CALLS.TYPE.DELAYED
      // },
    ],
    [callTypeTab],
  )

  useEffect(() => {
    setPage(1)
  }, [callTypeTab, filters])

  return (
    <Card additionalClassNames={[styles.Card]}>
      <ControlHeader
        title={'Calls'}
        counter={{
          min: calls.length,
          max: totalCount
        }}
        actions={siteVersion !== 'ru' ? [
          {
            variant: 'greenFilled',
            size: 'md',
            text: intl.formatMessage(CallsListMessages.call),
            prefix: <CallIcon />,
            onClick: openCreateCallModal,
            disabled: devices.length === 0
          },
        ] : []}
        loading={loading}
      />

      <CallTable
        calls={sliceCalls}
        devices={devices}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        setLimit={setLimit}
        pageLimit={limit}
        loading={devicesLoading || loading}
        handleDeleteCall={handleDeleteCall}
        handleFetchCalls={handleFetchCalls}
        initialFetchCompleted={initialFetchCompleted}
        setInitialFetchCompleted={setInitialFetchCompleted}
      />

      <audio ref={audioRef} id="remoteAudio" autoPlay playsInline></audio>
    </Card>
  )
}
